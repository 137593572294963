import { createSelector } from 'reselect';
import { crmTypeSelector, orgObjSelector } from 'modules/user/selectors';
import { INTEGRATIONS_TO_VALIDATE } from './constants';
import { isNil } from 'lodash';

export const integrationsStateSelector = (state) => state.integrations;

const relevantCRMStateSelector = createSelector(
  integrationsStateSelector,
  crmTypeSelector,
  (integrationsState, crmType = '') => {
    const normalizedCRMType = isNil(crmType) ? '' : crmType.toLowerCase();
    if (normalizedCRMType === 'salesforce') {
      return integrationsState[INTEGRATIONS_TO_VALIDATE.SALESFORCE];
    }
    if (normalizedCRMType === 'dynamics') {
      return integrationsState[INTEGRATIONS_TO_VALIDATE.DYNAMICS];
    }
    if (normalizedCRMType === 'hubspotcrm') {
      return integrationsState[INTEGRATIONS_TO_VALIDATE.HUBSPOTCRM];
    }

    // in case no crm type is set
    return {};
  }
);

export const areCRMCredsValidSelector = createSelector(
  relevantCRMStateSelector,
  ({ areCredsValid }) => areCredsValid
);

export const areCRMCredsValidatedSelector = createSelector(
  relevantCRMStateSelector,
  ({ loaded }) => loaded
);

export const areCRMCredsValidatingSelector = createSelector(
  relevantCRMStateSelector,
  ({ loading }) => loading
);

export const isCRMValidationErroredSelector = createSelector(
  relevantCRMStateSelector,
  ({ error }) => error
);

export const crmValidationErrorSelector = createSelector(
  relevantCRMStateSelector,
  ({ errorMessage }) => errorMessage
);

export const areG2CredsValidSelector = createSelector(
  integrationsStateSelector,
  (integrationsState) => integrationsState[INTEGRATIONS_TO_VALIDATE.G2].areCredsValid
);

export const isG2ConfiguredSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    const g2Obj = sync_sources.find(
      (sync_source) => sync_source.sync_source_type === 'G2'
    );
    return g2Obj ? !g2Obj.is_deleted : false;
  }
);

export const isTrustRadiusConfiguredSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    const trustRadiusObj = sync_sources.find(
      (sync_source) => sync_source.sync_source_type === 'trustradius'
    );
    return trustRadiusObj ? !trustRadiusObj.is_deleted : false;
  }
);

export const isSaleswhaleConfiguredSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    const saleswhaleObj = sync_sources.find(
      (sync_source) => sync_source.sync_source_type === 'saleswhale'
    );
    return saleswhaleObj ? !saleswhaleObj.is_deleted : false;
  }
);

export const isSnowflakeConfiguredSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    const snowflakeObj = sync_sources.find(
      (sync_source) => sync_source.sync_source_type === 'snowflake'
    );
    return snowflakeObj ? !snowflakeObj.is_deleted : false;
  }
);

export const isPeerspotConfiguredSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    const peerspotObj = sync_sources.find(
      (sync_source) => sync_source.sync_source_type === 'PeerSpot'
    );
    return peerspotObj ? !peerspotObj.is_deleted : false;
  }
);

// gartner is actually configured as `buyer_discovery` in `sync_source_type`
export const isGartnerConfiguredSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    const GartnerObj = sync_sources.find(
      (sync_source) => sync_source.sync_source_type === 'buyer_discovery'
    );
    return GartnerObj ? !GartnerObj.is_deleted : false;
  }
);

export const isTechTargetConfiguredSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    const techTargetObj = sync_sources.find(
      (sync_source) => sync_source.sync_source_type === 'TechTarget'
    );
    return techTargetObj ? !techTargetObj.is_deleted : false;
  }
);

export const isS3ConfiguredSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    const s3Obj = sync_sources.find(
      (sync_source) => sync_source.sync_source_type === 's3'
    );
    return s3Obj ? !s3Obj.is_deleted : false;
  }
);

export const isGongConfiguredSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    const gongObj = sync_sources.find(
      (sync_source) => sync_source.sync_source_type === 'Gong'
    );
    return gongObj ? !gongObj.is_deleted : false;
  }
);

export const isSourceForgeConfiguredSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    const sourceForgeObj = sync_sources.find(
      (sync_source) => sync_source.sync_source_type === 'SourceForge'
    );
    return sourceForgeObj ? !sourceForgeObj.is_deleted : false;
  }
);
