/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react';
import { Card, Flex, Text, Toggle, Select, Tooltip } from '@sixsense/core/components';
import ToggleContent from './ToggleContent';
import { useCrmAccountFields } from '../state';
import { css } from 'styles/emotion';
import { InfoCircle } from '@sixsense/core/icons';

const styles = {
  cardItem: css({
    padding: '20px 16px',
    borderBottom: '1px solid #F3F4F6',
    minHeight: '60px',
  }),
  select: css({
    width: '410px',
  }),
};

type CrmAccountFieldSelectProps = {
  data: Array<any>;
  config: any;
  metadata: any;
  handleToggleClick: (key: string) => {};
  handleSelectChange: (values: Array<[string]>, key: string) => {};
};

const CrmAccountFieldSelect = ({
  data = [], config = {},
  metadata = {},
  handleToggleClick,
  handleSelectChange }: CrmAccountFieldSelectProps) => {
  let selectOptions = data;

  if (config.crm_account_fields?.length > 4) {
    selectOptions = data.map((option) =>
      ({ ...option, ...{ isDisabled: !config.crm_account_fields.includes(option.value) } }));
  }

  const getSelectValues = () =>
    config.crm_account_fields?.
  map((field) => selectOptions.find((option) => option.value === field));

  return (<Flex direction="column">
    <ToggleContent
      className={styles.cardItem}
      text={metadata.label3}
      tooltip={metadata?.tooltips?.label5}
      configKey={'contact_account_sync_enabled'}
      toggleValue={config?.contact_account_sync_enabled}
      handleToggleClick={handleToggleClick}
    />
    {
      config.contact_account_sync_enabled &&
      <ToggleContent
        className={styles.cardItem}
        text={metadata.label4}
        configKey={'contact_push_on_account_failure_enabled'}
        toggleValue={config.contact_push_on_account_failure_enabled}
        handleToggleClick={handleToggleClick}
      />
    }

    <Flex justifyContent="space-between" alignItems="center" className={styles.cardItem}>
      <Flex gap={4} alignItems="center">
        <Text color={'#6C737F'} size={14}>
          Allow manual account selection during export
        </Text>
        <Tooltip overlay={metadata?.tooltips?.label4} placement="top">
          <InfoCircle size={16} />
        </Tooltip>
      </Flex>
      <Flex gap={16} alignItems="center">
        <Select
          hideSelectedOptions={false}
          size="md"
          type="default"
          disabled={!config.crm_account_selection_enabled}
          multi
          options={selectOptions}
          value={getSelectValues()}
          className={styles.select}
          onChange={(v) => v.length < 6 ?
            handleSelectChange(v.map((k) => k.value), 'crm_account_fields') : null}
          placeholder="Select Account Fields"
        />
        <Toggle
          value={config.crm_account_selection_enabled}
          onChange={() => handleToggleClick('crm_account_selection_enabled')}
        />
      </Flex>
    </Flex>
  </Flex>
  );
};

const AccountSelectContent = (
  {
    config,
    metadata,
    handleToggleClick,
    handleSelectChange,
  }: any
) => {
  const crmAccountFields = useCrmAccountFields();
  return (
    <Card.Content.Async result={crmAccountFields} style={{ padding: 0 }}>
      {({ data }) => (
        <CrmAccountFieldSelect
          data={data}
          config={config} metadata={metadata}
          handleToggleClick={handleToggleClick}
          handleSelectChange={handleSelectChange}
        />
      )}
    </Card.Content.Async>);
};

export default AccountSelectContent;
