import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Flex, Select, Text, Tooltip } from '@sixsense/core/components';
import { ChevronRight } from '@sixsense/core/icons';
import { css } from 'styles/emotion';
import { pxToRemUtil } from '../../utils';
import { DesignSystemColor } from '@sixsense/core/style';
import {
  DATE_RANGES_OPTIONS,
  DATASOURCE_PUBLISH_THRESHOLDS,
} from '../../constants';
import {
  dateRangeSelector,
  hasPublishedMappingsSelector,
  isSingleProductSelector,
  reviewPercentageSelector,
} from '../../selectors';
import { actions } from '../../modules/commonModules';
import { DesignSystemThemeHoc } from '../DesignSystemThemeHOC';
import { omit } from 'lodash';

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: pxToRemUtil(35),
    // paddingRight: pxToRemUtil(10),
    marginTop: pxToRemUtil(10),
    paddingBottom: pxToRemUtil(16),
    borderBottom: `1px solid ${DesignSystemColor.Gray.LIGHT_3}`,
  }),
  selectBox: css({
    marginLeft: pxToRemUtil(10),
  }),
};

const HeaderComponent = ({
  dateRange,
  changeDateRange,
  onDateRangeChange,
  hasPublishedMapping = false,
  dataSource,
  publishMappings,
  reviewPercentages,
  isSingleProduct,
}) => {
  let thresholds = DATASOURCE_PUBLISH_THRESHOLDS[dataSource];
  if (isSingleProduct) {
    thresholds = omit(thresholds, 'product');
  }

  const thresholdsMet = Object.keys(thresholds).reduce(
    (acc, key) =>
      acc && reviewPercentages[`${key}Percentage`] >= thresholds[key],
    true
  );
  return (
    <DesignSystemThemeHoc>
      <div className={styles.container}>
        <Flex direction="column" style={{ flexBasis: '50%' }}>
          <Flex alignItems="center">
            <Text.Body color={DesignSystemColor.Gray.DARK_4}>
              Show Data
            </Text.Body>
            <Select
              options={DATE_RANGES_OPTIONS}
              value={DATE_RANGES_OPTIONS.find(
                (option) => option.value === dateRange
              )}
              onChange={(option) => {
                changeDateRange(option.value);
                onDateRangeChange();
              }}
              className={styles.selectBox}
            />
          </Flex>
        </Flex>

        {!hasPublishedMapping && (
          <Flex direction="column" style={{ flexBasis: '50%' }}>
            <Flex alignItems="center" justifyContent="flex-end">
              <Text.Body color={DesignSystemColor.Blue.DARK_2}>
                Mappings must be published after review
              </Text.Body>
              <ChevronRight />
              <Tooltip
                placement="top"
                overlay={
                  !thresholdsMet
                    ? 'Publish Mappings is disabled until all significant data review is complete.'
                    : null
                }
              >
                <Button
                  hierarchy="primary"
                  className="aam-l--10"
                  disabled={!thresholdsMet}
                  onClick={() => publishMappings(dataSource)}
                >
                  Publish Mappings
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
        )}
      </div>
    </DesignSystemThemeHoc>
  );
};

const mapStateToProps = (state, ownProps) => ({
  dateRange: dateRangeSelector(state),
  hasPublishedMapping: hasPublishedMappingsSelector(ownProps.dataSource)(state),
  reviewPercentages: reviewPercentageSelector(ownProps.dataSource)(state),
  isSingleProduct: isSingleProductSelector(state),
});

HeaderComponent.propTypes = {
  dateRange: PropTypes.string,
  changeDateRange: PropTypes.func,
  onDateRangeChange: PropTypes.func,
  hasPublishedMapping: PropTypes.bool,
  publishMappings: PropTypes.func,
  dataSource: PropTypes.string,
  reviewPercentages: PropTypes.object,
  isSingleProduct: PropTypes.bool,
};

const Header = connect(mapStateToProps, {
  changeDateRange: actions.changeDateRange,
  publishMappings: actions.publishMappings,
})(HeaderComponent);

export default Header;
