import React from "react";
import { Text } from "@sixsense/core/components";
import { COLUMN_CATEGORIES } from "../constants";
import { NA } from "./common/NA";
import { toFixedfp } from "utils/utils";
import { is6SenseCampaign, isDraftOrScheduledCampaign } from "../../../utils";
import { ROW_DATA_TYPES } from "aa-components/CampaignCSVExportModal/constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const VTR = {
  sortByKey: "vtr",
  category: COLUMN_CATEGORIES.general,
  key: "vtr",
  csvColumnKey: "vtr",
  title: <Label name={CAMPAIGN_LABELS.vtr} />,
  dataIndex: "campaign_data.vtr",
  width: pxToRem(110),
  render: (vtr, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (
      is6SenseCampaign(campaign) === false ||
      isDraftOrScheduledCampaign(campaignStatus)
    ) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {toFixedfp(2)(vtr)}%
      </Text.Body>
    );
  },
  csvOverrides: {
    dataIndex: "download_stats.vtr",
    render: (vtr, campaign, dataType) => {
      const campaignStatus = campaign.campaign_status;
      const campaignHasStats = !isDraftOrScheduledCampaign(campaignStatus);
      if (
        (is6SenseCampaign(campaign) && campaignHasStats) ||
        dataType === ROW_DATA_TYPES.aggregated
      ) {
        return `"${toFixedfp(2)(vtr || 0)}%"`;
      }

      return "-";
    },
  },
  align: "right",
};
