import React, { useEffect, useState } from 'react';
import { css } from 'styles/emotion';
import { Flex, Toggle, Text, Avatar } from '@sixsense/core/components';
import { Radio } from 'v2-components';
import { useRequest } from '@sixsense/core';
import insightsicon from '../images/ai_insights_icon.svg';
import { ComponentAndMetricsType } from '../types';
import { LEARN_MORE_LINK, INSIGHTS_CARD_HEADER, INSIGHTS_CARD_SUBHEADER,
  LEARN_MORE } from '../constant';
import { SalesPermission, usePermissionCheck } from '@sixsense/rbac';
import { isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';
import { useSelector } from '@sixsense/core/versioned/react-redux';


const styles = {
  card: css({
    width: '100%',
    border: '0.5px solid #E2E4E7',
    borderRadius: '6px',
    backgroundColor: '#fff',
    position: 'relative',
  }),
  text: css({
    marginLeft: '15px',
  }),
  title: css({
    fontSize: '18px',
    lineHeight: '24px',
    color: '#001F32',
  }),
  subBody: css({
    fontSize: '14px',
    lineHeight: '24px',
    color: '#696F7B',
  }),
  toggleSwitch: css({
    div: {
      position: 'absolute',
      right: '20px',
      top: '30px',
    },
  }),
  radio: css({
    border: 'none',
    paddingLeft: 0,
    marginRight: '30px',
  }),
  topSection: css({
    padding: '20px',
  }),
  bottomSection: css({
    borderTop: '1px solid #E2E4E7',
    padding: '20px',
  }),
  image: css({
    marginTop: '4px',
  }),
};

type Props = {
  metricsConfig: any,
  orgId: number,
  updateLoader: any,
};

export const AIInsightsCard = ({ metricsConfig, orgId, updateLoader }: Props) => {
  const [isEnabled, setIsEnabled] = useState<Boolean>(false);
  const [onlyAdminEnabled, setOnlyAdminEnabled] = useState<Boolean>(false);
  const [allUsersEnabled, setAllUsersEnabled] = useState<Boolean>(false);
  const request = useRequest();
  const isViewOnlyEnabled = useSelector(isViewOnlyEnabledFor6senseAccessSelector);
  const hasEditPermission = usePermissionCheck([SalesPermission.SETTINGS_GENERATIVE_AI_EDIT]);

  useEffect(() => {
    if (metricsConfig.genai_account_summary_enabled) {
      setIsEnabled(true);
      setOnlyAdminEnabled(metricsConfig.genai_account_summary_enabled_admin_only);
      setAllUsersEnabled(!metricsConfig.genai_account_summary_enabled_admin_only);
    } else {
      setIsEnabled(false);
      setOnlyAdminEnabled(false);
      setAllUsersEnabled(false);
    }
  }, [metricsConfig]);

  const onChange = async (type: string) => {
    let payload = {};
    if (type === 'admin' && !onlyAdminEnabled) {
      payload = {
        genai_account_summary_enabled_admin_only: true,
      };
      await postMetricsChange(payload);
    } else if (type === 'all_users' && onlyAdminEnabled) {
      payload = {
        genai_account_summary_enabled_admin_only: false,
      };
      await postMetricsChange(payload);
    }
  };

  const onToggle = async () => {
    const payload = {
      genai_account_summary_enabled: !isEnabled,
    };
    await postMetricsChange(payload);
  };

  const postMetricsChange = async (payload: Object) => {
    try {
      updateLoader(true);
      const res: ComponentAndMetricsType = await request(
        `settings/${orgId}/components-and-metrics/${metricsConfig.id}/`,
        {
          method: 'PATCH',
          body: JSON.stringify(payload),
        }
      );

      if (res) {
        setIsEnabled(res.genai_account_summary_enabled);
        setOnlyAdminEnabled(res.genai_account_summary_enabled &&
          res.genai_account_summary_enabled_admin_only);
        setAllUsersEnabled(res.genai_account_summary_enabled &&
          !res.genai_account_summary_enabled_admin_only);
      }
      updateLoader(false);
    } catch (ex) {
      updateLoader(false);
    }
  };

  return (
    <div className={styles.card}>
      <Flex className={styles.topSection} justifyContent="space-between" alignItems="center">
        <Flex>
          <Avatar src={insightsicon} className={styles.image} />
          <span className={styles.text}>
            <Text className={styles.title} weight="bold">
              {INSIGHTS_CARD_HEADER}
            </Text>
            <br />
            <Text className={styles.subBody}>
              {INSIGHTS_CARD_SUBHEADER}{' '}
              <a
                href={LEARN_MORE_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                {LEARN_MORE}
              </a>
            </Text>
          </span>
        </Flex>
        <Toggle
          disabled={!hasEditPermission || isViewOnlyEnabled}
          className={styles.toggleSwitch}
          value={isEnabled}
          onChange={onToggle}
        />
      </Flex>
      {isEnabled ?
        <Flex className={styles.bottomSection}>
          <Radio
            disable={!hasEditPermission || isViewOnlyEnabled}
            className={styles.radio}
            selected={onlyAdminEnabled}
            item={{
              label: 'Visible to Sales Administrator',
              value: 'Visible to Sales Administrator',
            }}
            action={() => onChange('admin')}
          />
          <Radio
            disable={!hasEditPermission || isViewOnlyEnabled}
            className={styles.radio}
            item={{
              label: 'Visible to All Users',
              value: 'Visible to All Users',
            }}
            selected={allUsersEnabled}
            action={() => onChange('all_users')}
          />
        </Flex> : ''
      }
    </div>
  );
};
