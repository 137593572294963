/* eslint-disable no-undef */
import {
  defineRequestState,
  defineSharedState,
  useRequestValue,
} from '@sixsense/core';
import { useOrg } from 'hooks';

export const PATH_PREFIX = 'settings.siIntegrations';
export const DEFAULT_CONFIG = {
  id: 0,
  org_id: '',
  contact_update_enabled: false,
  lead_update_enabled: false,
  account_update_enabled: false,
  integrated_app: '',
  app_type: '',
  contact_account_sync_enabled: false,
  contact_push_on_account_failure_enabled: false,
  contact_export_enabled: false,
  lead_export_enabled: false,
  account_export_enabled: false,
  export_options: { Lead: false, Contact: false, Account: false },
  export_only_crm_existing_records_to_sep: false,
  enrich_when_exporting_to_sep: '',
  crm_account_fields: [],
  crm_account_selection_enabled: false,
};
type SiIntegrationsListParams = {
  orgId: string | number;
};
export type SiIntegrationsConfig = {
  id: number;
  updated: string;
  created: string;
  org_id: string | number;
  contact_update_enabled: boolean;
  lead_update_enabled: boolean;
  account_update_enabled: boolean;
  integrated_app: string;
  app_type: string;
  contact_account_sync_enabled: boolean;
  contact_push_on_account_failure_enabled: boolean;
  contact_export_enabled: boolean;
  lead_export_enabled: boolean;
  account_export_enabled: boolean;
  created_by: number;
  updated_by: number;
  export_options?: any;
  mapping_profile?:any;
  export_only_crm_existing_records_to_sep: boolean;
  enrich_when_exporting_to_sep: string;
  crm_account_fields: Array<string>,
  crm_account_selection_enabled: boolean,
};
type SiEditIntegrationsConfig = Omit<
  SiIntegrationsConfig,
  "updated" | "created" | "created_by" | "updated_by"
>;
export type SiSaveIntegrationsConfig = Omit<
  SiEditIntegrationsConfig,
  "export_options" | "mapping_profile"
>;
type savedConfigs = {
  crm: SiEditIntegrationsConfig;
  sep: SiEditIntegrationsConfig;
};
export const savedConfigsState = defineSharedState<savedConfigs | undefined>({
  path: `${PATH_PREFIX}.savedConfigs`,
  defaultValue: {
    crm: DEFAULT_CONFIG,
    sep: DEFAULT_CONFIG,
  },
});

export const unSavedChangesState = defineSharedState<boolean>({
  path: `${PATH_PREFIX}.unSavedFlag`,
  defaultValue: false,
});

export const siIntegrationsRequestState = defineRequestState({
  path: `${PATH_PREFIX}.siIntegrationsList`,
  getConfig: ({ orgId }: SiIntegrationsListParams) => ({
    url: `settings/${orgId}/integrations-config/`,
  }),
  transformData: (
    data: any
  ): { crm: SiIntegrationsConfig; sep: SiIntegrationsConfig } =>
    data.reduce(
      (all, item) => {
        const d = all;
        d[item.app_type.toLowerCase()] = item;
        return d;
      },
      {
        crm: {},
        sep: {},
      }
    ),
});

export const useSiIntegrationRequest = () => {
  const org = useOrg();
  const siIntegrationsResult = useRequestValue(siIntegrationsRequestState, {
    orgId: org.id,
  });
  return siIntegrationsResult;
};

export const accountFieldsRequest = defineRequestState({
  path: `${PATH_PREFIX}.crmAccountFields`,
  getConfig: ({ orgId }: SiIntegrationsListParams) => ({
    url: `settings/${orgId}/crm-account-fields/`,
  }),
  transformData: (data: any) => data?.map((el) =>
    ({ ...el, ...{ value: el.name, label: el.label || el.name } })) || [],
});

export const useCrmAccountFields = () => {
  const org = useOrg();
  const crmAccountFields = useRequestValue(accountFieldsRequest, {
    orgId: org.id,
  });
  return crmAccountFields;
};
