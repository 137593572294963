import { get, some, has, filter, upperCase, keyBy, remove } from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';
import {
  orgHasUnifiedContacts,
  toOrganization,
  getCommandbarMetadata,
} from './utils';
import { DEFAULT_PRODUCT_LIST, VIEW_ONLY_6SI_STAFF_ROLES } from './constants';
import { PIPELINE_INTELLIGENCE_PERMISSIONS } from 'constants/featurePermissions';
import {
  DefaultRole,
  permissionsSelector,
  WorkflowsPermission,
} from '@sixsense/rbac';

/* eslint max-len: 0 */
const orgObjSelector = (state) => get(state, 'user.user.organization', {});
const multiAppsListSelector = (state) =>
  get(state, 'user.multiAppsList', []) || [];
const orgNameSelector = (state) => get(orgObjSelector(state), 'org_name', '');
const setUpSelector = (state) =>
  get(state, 'user.user.organization.is_setup', undefined);
const userSelector = (state) => get(state, 'user.user.id');
const userObjectSelector = (state) => get(state, 'user.user', {});
const userIsStaffSelector = (state) => state.user.user.is_staff;
const userIsSuperuserSelector = () => false;
const userIsDevSelector = () => false;
const userIsInternalSelector = () => false;
const userLoadingSelector = (state) => state.user.loading;
const userAppRolesSelector = (state) => get(state, 'user.user.app_roles', []);
const orgLoadingSelector = (state) => state.user.organization.loading;
const predictiveProductsSelector = (state) => {
  const productList = get(state, 'user.user.organization.predictiveProducts', []);
  const models_ready = get(state, 'user.user.organization.flags.models_ready');
  return models_ready && productList && productList.length ? productList : DEFAULT_PRODUCT_LIST;
};
// const userHasEpsilonIp2CSelector = (state) => state.user.organization.has_epsilon_ip2c; // key TBD

const orgFlagsSelector = createSelector(
  orgObjSelector,
  ({ flags, packages, is_in_demo_state } = {}) => ({
    ...flags,
    has_zeniq: !get(packages, 'zen.is_deleted', true),
    is_in_demo_state,
  }),
);

const isDataBetaCustomer = createSelector(
  orgObjSelector,
  ({ packagePlan }) => get(packagePlan, 'data_workflows.plan.name') === 'beta'
    && packagePlan?.data_workflows?.plan?.is_deleted === false,
);

const isAudienceBetaCustomer = createSelector(
  orgObjSelector,
  ({ packagePlan }) => get(packagePlan, 'audience_workflows.plan.name') === 'beta'
    && packagePlan?.audience_workflows?.plan?.is_deleted === false,
);

const isWorkflowsBetaCustomerSelector = createSelector(
  isDataBetaCustomer,
  isAudienceBetaCustomer,
  (hasDataBeta, hasAudienceBeta) => hasDataBeta || hasAudienceBeta,
);

const isPredictiveSelector = (state) => {
  const org = get(state, 'user.user.organization', false);
  const modelsReady = orgFlagsSelector(state).models_ready;
  return org ? (org.has_predictive && modelsReady) : false;
};

const hasCompanyMasterLookupSelector = (state) => {
  const org = get(state, 'user.user.organization', false);
  return org ? org.flags.has_cml : false;
};

const orgReadySelector = createSelector(
  orgObjSelector,
  setUpSelector,
  userIsStaffSelector,
  userIsInternalSelector,
  ({ packages } = {}, isSetUp, isStaff, isInternal) => {
    if ((!has(packages, 'aa') || get(packages, 'aa.is_deleted', true))
      && (!has(packages, 'SI') || get(packages, 'SI.is_deleted', true))) {
      return isSetUp && some([isStaff, isInternal]);
    }
    return isSetUp;
  }
);

const packageSelector = createSelector(
  orgObjSelector,
  ({ packages } = {}) => packages,
);

const userLoadedSelector = (state) => state.user.loaded;

const userSelectionLoadingSelector = createSelector(
  userLoadingSelector,
  orgLoadingSelector,
  (userLoading, orgLoading) => userLoading || orgLoading
);

const orgSelector = createSelector(
  orgObjSelector,
  (org) => get(org, 'id', '')
);

const is6SenseOrgSelector = createSelector(
  orgSelector,
  (org) => org === 4
);

const trackingSinceSelector = createSelector(
  orgObjSelector,
  (org) =>
    org.tracking_since ? moment.parseZone(org.tracking_since) : moment().subtract(1, 'year')
);

const lastUpdatedSelector = createSelector(
  orgObjSelector,
  (org) =>
    org.last_updated ? moment.parseZone(org.last_updated) : moment.utc().subtract(32, 'hour')
);

const lastUpdatedTimeSelector = createSelector(
  orgObjSelector,
  (org) =>
    org.last_updated_time ? moment.parseZone(org.last_updated_time) :
      moment.utc().subtract(32, 'hour')
);

const showRepeteSelector = createSelector(
  userIsDevSelector,
  userIsStaffSelector,
  userIsSuperuserSelector,
  (...userTypes) => some(userTypes)
);

const mapTypeSelector = createSelector(
  orgObjSelector, (org) => org.mapType,
);

const orgPropertiesSelector = createSelector(
  orgObjSelector,
  ({ properties } = {}) => properties,
);

const hasBomboraSelector = createSelector(
  orgPropertiesSelector,
  (p) => p ? p.bombora_topic_cap !== '0' : false,
);

const sepTypeSelector = createSelector(
  orgObjSelector, (org) => org.sepType,
);

const crmTypeSelector = createSelector(
  orgObjSelector, ({ crmType } = {}) => crmType,
);

const sandboxCRMTypeSelector = createSelector(
  orgObjSelector, ({ sandboxCRMType } = {}) => sandboxCRMType,
);

const emailServerTypeSelector = createSelector(
  orgObjSelector,
  ({ sync_sources = [] }) => {
    // this should be added from backend as email_server_type in details api
    const emailServerObjs = sync_sources.filter(
      (sync_source) => (sync_source.sync_source_type === 'microsoft' ||
        sync_source.sync_source_type === 'google') && !sync_source.is_deleted);

    // emailserverobjs should always be one for the code to function properly
    return (emailServerObjs.length === 1) ? emailServerObjs[0].sync_source_type : null;
  }
);

const emailServerTypeObjectSelector = createSelector(
  orgObjSelector,
  emailServerTypeSelector,
  ({ sync_sources = [] }, emailServerType) =>
    sync_sources.find((sync_source) => sync_source.sync_source_type === emailServerType) || {}
);

const pipelineIntelligenceEnabledSelector = createSelector(
  orgObjSelector, ({ piEnabled }) => piEnabled,
);

const hasDynamicsIntegrationSelector = createSelector(
  crmTypeSelector, (crmType) => crmType === 'Dynamics',
);

const hasSalesforceIntegrationSelector = createSelector(
  crmTypeSelector, (crmType) => crmType === 'Salesforce',
);

const packagesSelector = createSelector(
  orgObjSelector,
  ({ packages }) => {
    const pkgs = [
      get(packages, 'aa.is_deleted', true) === false ? 'platform' : '',
      get(packages, 'predictive.is_deleted', true) === false ? 'advanced' : '',
      get(packages, 'zen.is_deleted', true) === false
        ? 'essentials'
        : '',
    ];
    return filter(pkgs, 'length');
  },
);

const essentialsSelector = createSelector(
  packagesSelector,
  (pkgs) => pkgs.includes('essentials')
);

/**
 * This differs from orgObjSelector because it returns a standardized Organization type defined
 * in @sixsense/core/types.
 *
 * @returns {import('@sixsense/core/types').Organization}
 */
const organizationSelector = createSelector(
  orgObjSelector,
  orgFlagsSelector,
  isPredictiveSelector,
  essentialsSelector,
  predictiveProductsSelector,
  (orgObj, flags, isPredictive, hasEssentials, predictiveProducts) =>
    toOrganization(orgObj, flags, isPredictive, hasEssentials, predictiveProducts)
);


const orgActiveSelector = createSelector(
  orgFlagsSelector,
  ({ is_active, is_demo_org }) => is_active || is_demo_org
);

const isDevOrInternalUserSelector = createSelector(
  userIsDevSelector,
  userIsInternalSelector,
  (isDev, isInternalUser) => some([isDev, isInternalUser])
);

const hasUnifiedContactsSelector = createSelector(
  orgFlagsSelector,
  isPredictiveSelector,
  essentialsSelector,
  (flags = {}, isPredictive, hasEssentials) =>
    orgHasUnifiedContacts(flags, isPredictive, hasEssentials)
);

const isSandboxOrgSelector = createSelector(
  orgObjSelector,
  ({ isSandboxOrg = false }) => isSandboxOrg
);

const isSmartFormFillEnabledSelector = createSelector(
  orgFlagsSelector,
  userAppRolesSelector,
  ({ smart_form_fill = false }, app_roles) => {
    const viewOnlyUserRoleIds = [DefaultRole.VIEW_ONLY_USER];
    return smart_form_fill && !app_roles.some((role) => viewOnlyUserRoleIds.includes(role.role_id));
  }
);

const isViewOnly6SenseUserSelector= createSelector(
  orgSelector,
  userIsStaffSelector,
  userAppRolesSelector,
  (orgId, isStaffUser, app_roles) => orgId !== 4 && isStaffUser && app_roles.every((role) =>
    VIEW_ONLY_6SI_STAFF_ROLES.includes(role.role_id))
);

const isViewOnlyEnabledFor6senseAccessSelector = createSelector(
  orgSelector,
  userIsStaffSelector,
  userAppRolesSelector,
  (orgId, isStaffUser, app_roles) => orgId !== 4 && isStaffUser && app_roles.every((role) => VIEW_ONLY_6SI_STAFF_ROLES.includes(role.role_id))
);

const isExternalSixsenseUserSelector = createSelector(
  orgSelector,
  userIsStaffSelector,
  userIsDevSelector,
  userIsInternalSelector,
  userIsSuperuserSelector,
  (orgId, isStaffUser, isDevUser, isInternalUser, isSuperUser) =>
    orgId !== 4 && (isStaffUser || isDevUser || isInternalUser || isSuperUser)
);

// Generic App role checker
const doesUserHaveAppSelector = createSelector(
  userAppRolesSelector,
  (userAppRoles) => (appid) => userAppRoles.some(({ app_id }) => app_id === appid)
);

// functionality to have the ABM and SI apps to co-exist
const packagePlanSelector = createSelector(
  orgObjSelector,
  ({ packagePlan } = {}) => packagePlan,
);

// Generic org package checker
const orgHasPackageSelector = createSelector(
  orgObjSelector,
  ({ packages }) => (packageType) => has(packages, packageType) && get(packages, `${packageType}.is_deleted`, true) === false
);

export const isSettingsIframeSelect = createSelector(
  (state) => get(state, 'user.settingsConfig', {}),
  (settingsConfig) => !!(settingsConfig).isSettingsIframe,
);

export const isSettingsRouteSelector = createSelector(
  (state) => get(state, 'user.settingsConfig', {}),
  (settingsConfig) => !!(settingsConfig).isSettingsRoute,
);
export const isSettingsManageRouteSelector = createSelector(
  (state) => get(state, 'user.settingsConfig', {}),
  (settingsConfig) => !!(settingsConfig).isSettingsManageRoute,
);
export const activeSettingsSelector = createSelector(
  (state) => get(state, 'user.settingsConfig', {}),
  (settingsConfig) => (settingsConfig).activeSettings || {},
);

export const settingsConfigSelector = createSelector(
  (state) => get(state, 'user.settingsConfig', {}),
  (settingsConfig) => (settingsConfig) || {},
);


export const resetAppSettingRandomSelector = createSelector(
  (state) => get(state, 'user.settingsConfig', {}),
  (settingsConfig) => (settingsConfig).resetAppSettingRandom,
);

const commandbarMetadataSelector = createSelector(
  userObjectSelector,
  orgObjSelector,
  orgFlagsSelector,
  orgPropertiesSelector,
  (userObj, orgObj, flags, properties) => getCommandbarMetadata(userObj, orgObj, flags, properties)
);

const appSwitcherEnabledAppsSelector = createSelector(
  pipelineIntelligenceEnabledSelector,
  userAppRolesSelector,
  multiAppsListSelector,
  permissionsSelector,
  packagePlanSelector,
  isExternalSixsenseUserSelector,
  (pipelineIntelEnabled, userAppRoles, _orgApps, permissions, packagePlan, is6SenseUser) => {
    // for audience workflows only, workflows should not show up in the
    // app switcher since it lives in abm
    const orgApps = keyBy(_orgApps, 'id');
    const hasDataWorkflows =
      (permissions.has(WorkflowsPermission.DATA_WORKFLOWS_VIEW) || is6SenseUser)
      && packagePlan?.data_workflows?.plan?.is_deleted === false;

    const enabledApps = [];

    const showPipelineIntelligence = PIPELINE_INTELLIGENCE_PERMISSIONS.some(
      (piPermission) => permissions.has(piPermission),
    ) && pipelineIntelEnabled;
    if (showPipelineIntelligence) {
      enabledApps.push('PI');
    }

    (userAppRoles || []).forEach(({ app_id }) => {
      const mapAppName = orgApps[app_id];
      if (mapAppName) {
        enabledApps.push(upperCase(mapAppName.name));
      }
    });

    if (!hasDataWorkflows) {
      remove(enabledApps, (a) => a === 'WORKFLOWS');
    }

    return enabledApps;

  },
);

export {
  orgReadySelector,
  orgSelector,
  orgObjSelector,
  multiAppsListSelector,
  orgNameSelector,
  is6SenseOrgSelector,
  orgLoadingSelector,
  userSelector,
  trackingSinceSelector,
  lastUpdatedSelector,
  lastUpdatedTimeSelector,
  userSelectionLoadingSelector,
  isPredictiveSelector,
  showRepeteSelector,
  userAppRolesSelector,
  userIsInternalSelector,
  userIsStaffSelector,
  userIsSuperuserSelector,
  userIsDevSelector,
  // userHasEpsilonIp2CSelector,
  userLoadedSelector,
  hasCompanyMasterLookupSelector,
  hasBomboraSelector,
  predictiveProductsSelector,
  orgFlagsSelector,
  userObjectSelector,
  packageSelector,
  mapTypeSelector,
  orgPropertiesSelector,
  sepTypeSelector,
  packagesSelector,
  crmTypeSelector,
  sandboxCRMTypeSelector,
  emailServerTypeSelector,
  hasDynamicsIntegrationSelector,
  hasSalesforceIntegrationSelector,
  orgActiveSelector,
  organizationSelector,
  isDevOrInternalUserSelector,
  essentialsSelector,
  hasUnifiedContactsSelector,
  pipelineIntelligenceEnabledSelector,
  isSandboxOrgSelector,
  emailServerTypeObjectSelector,
  isExternalSixsenseUserSelector,
  packagePlanSelector,
  doesUserHaveAppSelector,
  orgHasPackageSelector,
  isSmartFormFillEnabledSelector,
  commandbarMetadataSelector,
  isWorkflowsBetaCustomerSelector,
  isAudienceBetaCustomer,
  isViewOnly6SenseUserSelector,
  isViewOnlyEnabledFor6senseAccessSelector,
  appSwitcherEnabledAppsSelector,
};
