import rollbar from 'lib/rollbar';
import { isNull, isNil, map, mapKeys, snakeCase, get } from 'lodash';
import { actions as globalActions } from 'modules/global';
import { setPermissions } from '@sixsense/rbac';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'store/sagas';
import { GLOBAL_ERROR_MESSAGE } from 'utils/constants';
import { actions, actionTypes } from './index';
import {
  crmTypeSelector, essentialsSelector, isPredictiveSelector, orgSelector, userObjectSelector,
} from './selectors';
import { toOrganization } from './utils';
import { setFlags } from '@sixsense/core/featureFlags';
import { filterFeatureFlags } from 'utils/utils';
import { actions as integrationsActions } from 'modules/integrations';
import { isG2ConfiguredSelector } from 'modules/integrations/selectors';
import { INTEGRATIONS_TO_VALIDATE } from 'modules/integrations/constants';
import { identifyDatadogUser } from 'lib/datadog';
import { pendo_flags } from './constants';
import { identifyUser as identifyLogrocketUser } from 'lib/logrocket';

const { LOAD_USER, LOAD_USER_SUCCESS, LOAD_ORG_REQUEST, LOAD_ORG_SUCCESS } = actionTypes;
const { loadFail, loadSuccess, loadOrgSuccess, loadOrgFailure, loadMultiAppsListSuccess } = actions;
const { showGlobalError } = globalActions;
const { validateIntegrationCreds, setIntegrationStateToValidate } = integrationsActions;


export function* loadUser(request) {
  try {
    const user = yield call(request, 'auth_user/');
    const roleResp = yield call(request, 'auth/v3/role/', 'GET', {}, () => ({}));
    const permissions = get(roleResp, 'permissions_list', []);

    yield put(setPermissions(permissions));
    // user shouldnt really ever come back null
    yield put(loadSuccess(isNull(user) ? {} : user));

    const { mixpanel } = window;
    if (mixpanel) {
      const { organization, ...userProperties } = user;
      mixpanel.identify(user.id);
      mixpanel.people.set(userProperties);
    }

    identifyLogrocketUser(user);
    identifyDatadogUser(user);

    // After the user is loaded lets load the org+
    rollbar.configure({ payload: { user } });
  } catch (e) {
    yield put(showGlobalError(GLOBAL_ERROR_MESSAGE));
    yield put(loadFail());
  }
}

function* loadOrgSaga(request) {
  try {

    const orgId = yield select(orgSelector);
    const orgEndpoint = `org/${orgId}/`;
    const endpoint_params =
      'flags=true&packages=true&map_type=True&properties=True&'
      + 'sep_type=True&crm_type=True&sandbox=False&sync_sources=True&products=True&'
      + 'package_plan=True&is_contact_details_available=True';

    const psnStr = JSON.stringify({ name: 'predictive', state: 'repete_done' });
    const addonStr = JSON.stringify({ name: 'Pipeline Intelligence', choice: true });
    const piEnabledParams = `package_state_and_name=${psnStr}&addon=${addonStr}`;

    const {
      org,
      enrichedOrg: {
        flags,
        packages,
        map_type,
        properties,
        sep_type,
        crm_type,
        crm_account_id,
        sync_sources,
        is_sandbox,
        products,
        package_plan,
        is_contact_details_available,
      },
      piEnabledProducts,
    } = yield all({
      org: call(request, orgEndpoint),
      enrichedOrg: call(
        request,
        `organization/enrich/${orgId}/details/?${endpoint_params}`,
      ),
      piEnabledProducts: call(
        request, `organization/enrich/${orgId}/product/?${piEnabledParams}`),
    });

    let sandboxCRMType = null;
    if (is_sandbox) {
      const response = yield call(
        request, `organization/enrich/${orgId}/details/?crm_type=True&sandbox=True`
      );
      sandboxCRMType = get(response, 'crm_type', null);
    }

    const piEnabled = !!piEnabledProducts.length;

    yield put(setFlags(filterFeatureFlags(flags)));

    // populate org object with enriched organization data
    org.flags = flags;
    org.packages = packages;
    org.mapType = map_type;
    org.properties = properties;
    org.sepType = sep_type;
    org.crmType = is_sandbox ? sandboxCRMType: crm_type;
    org.sandboxCRMType = sandboxCRMType;
    org.crmAccountId = crm_account_id;
    org.sync_sources = sync_sources;
    org.is_sandbox = is_sandbox;
    org.piEnabled = piEnabled;
    org.isSandboxOrg = is_sandbox;
    org.allProducts = products;
    org.packagePlan = package_plan;

    const { mixpanel, pendo } = window;
    if (mixpanel) {
      mixpanel.people.set({ org: org.name.toLowerCase() });
    }

    const activeFlags = pendo_flags.filter((flag) => flags[flag]);
    const SIPlanName = get(package_plan, 'SI.plan.name', '');
    if (SIPlanName) {
      activeFlags.push('si_enabled');
    }
    if (get(properties, 'segments_v2_migration_status', '') === 'complete' &&
       get(flags, 'has_new_segments_experience') === true) {
      activeFlags.push('has_new_segments_experience');
    }
    if (get(properties, 'segments_v2_migration_status', '') === 'freeze_for_confirmation') {
      activeFlags.push('saas_migration_in_progress');
    }
    // If this is a predictive org get the predictive products as well. This is kinda of hacky
    let predictiveProducts;
    if (org.has_predictive && flags.models_ready) {
      const endpoint = 'graphql/';
      const body = {
        body: JSON.stringify({
          query: 'query { products(isPredictive: true) { id, displayName, normalizedName, name }}',
        }),
      };
      const response = yield call(request, endpoint, 'POST', body);
      predictiveProducts = map(
        response.data.products,
        // We snake case because everything in the UI was using snake_case
        // and i didnt want to look through and fix all of the areas. So if u think
        // u r slick and want to remove the snakeCase just know that something will forsure break
        (product) => mapKeys(product, (v, k) => snakeCase(k))
      );
    }


    if (pendo) {
      const user = yield select(userObjectSelector);
      const isPredictive = yield select(isPredictiveSelector);
      const hasEssentials = yield select(essentialsSelector);

      let isSAMLEnabledOrg = null;

      try {
        const authResponse = yield call(
        request,
        `auth/v3/organizations/${user.organization.org_auth_id}/`,
        'GET',
        {},
        () => ({})
      );
        isSAMLEnabledOrg = authResponse?.auth_method === 'SAML';
      } catch (e) {
      // do nothing
      }

      const organization = toOrganization(
        org,
        flags,
        isPredictive,
        hasEssentials,
        predictiveProducts
      );

      let visitorId = user.username;
      let accountId = org.id;

      if (!window.process.env.SIXSENSE_ENV.startsWith('prod')) {
        visitorId = `${window.process.env.SIXSENSE_ENV || 'local'}:${user.username}`;
        accountId = `${window.process.env.SIXSENSE_ENV || 'local'}:${organization.id}`;
      }

      pendo.initialize({
        visitor: {
          id: visitorId,
          email: user.username,
          roleId: user.role_id,
        },
        account: {
          id: accountId,
          product: 'ABM',
          name: organization.name,
          crmAccountId: crm_account_id,
          issamlenabledorg: isSAMLEnabledOrg || undefined,
          contactdetailsavailable: is_contact_details_available,
          packages: Array.from(organization.packages),
          addons: Array.from(organization.addons),
          enabled_flags: activeFlags,
          instance_url: `${window.location.protocol}//${window.location.host}`,
          ...(SIPlanName && { si_plan: SIPlanName }),
        },
      });
      if (window.LogRocket) {
        window.LogRocket.getSessionURL((sessionURL) => {
          pendo.track('Session Replay Created', {
            sessionURL,
          });
        });
      }
    }

    yield all(Object.values(INTEGRATIONS_TO_VALIDATE).map(
      (currentKey) => put(setIntegrationStateToValidate(currentKey)
    )));

    const response = yield call(
      request,
      `organization/${orgId}/apps/`,
      'GET'
    );
    // const response = [{"id":1,"name":"ABM","enabled":true},{"id":2,"name":"SI","enabled":true}];
    const appsList = (response || []).filter((app) => app.enabled)
      .sort((app1, app2) => app1.id - app2.id);
    yield put(loadMultiAppsListSuccess(appsList));

    yield put(loadOrgSuccess({ ...org, predictiveProducts }));
  } catch (e) {
    yield put(loadOrgFailure());
  }
}

function* loadProductsAndOrgSaga(request) {
  yield all([
    call(loadOrgSaga, request),
  ]);
}

function* validateIntegrationCredsSaga() {
  const crmType = yield select(crmTypeSelector);
  const normalizedCRMType = isNil(crmType) ? '' : crmType.toLowerCase();
  const isG2Configured = yield select(isG2ConfiguredSelector);

  if (normalizedCRMType === 'salesforce') {
    yield put(validateIntegrationCreds(INTEGRATIONS_TO_VALIDATE.SALESFORCE));
  }
  if (normalizedCRMType === 'dynamics') {
    yield put(validateIntegrationCreds(INTEGRATIONS_TO_VALIDATE.DYNAMICS));
  }
  if (normalizedCRMType === 'hubspotcrm') {
    yield put(validateIntegrationCreds(INTEGRATIONS_TO_VALIDATE.HUBSPOTCRM));
  }
  if (isG2Configured) {
    yield put(validateIntegrationCreds(INTEGRATIONS_TO_VALIDATE.G2));
  }
}

/**
 * Saga manages page-load calls
 */
export function* watchLoadUserSuccess(request) {
  // React to user loading successfully
  yield takeLatest(LOAD_USER_SUCCESS, loadProductsAndOrgSaga, request);
}

/**
 * Saga manages page-load calls
 */
export function* watchAuth(request) {
  yield takeLatest(LOAD_USER, loadUser, request);
}

function* watchLoadOrg(request) {
  yield takeLatest(LOAD_ORG_REQUEST, loadOrgSaga, request);
}

function* watchLoadOrgSuccess() {
  yield takeLatest(LOAD_ORG_SUCCESS, validateIntegrationCredsSaga);
}

export default [
  fetchSaga(watchLoadUserSuccess),
  fetchSaga(watchAuth),
  fetchSaga(watchLoadOrg),
  watchLoadOrgSuccess,
];
