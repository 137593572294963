import React from "react";
import PropTypes from "prop-types";
import {
  CAMPAIGN_PERFORMANCE_STATE_TOOLTIPS,
  CAMPAIGN_PERFORMANCE_STATES,
  CAMPAIGN_STATUS_X,
} from "../../constants";
import { get } from "lodash";
import { getCampaignPerformanceIndicatorColor } from "../../utils";
import { Badge, Popover, Link, Flex, Text } from "@sixsense/core/components";
import { Ban, CheckCircle } from "@sixsense/core/icons";
import { RootColor } from "@sixsense/core/style/variables";
import { css } from "styles/emotion";

const toString = (campaign) => {
  const performanceState = get(
    campaign,
    "campaign_performance.performance_state"
  );
  return CAMPAIGN_PERFORMANCE_STATES[performanceState].label;
};

const CampaignPerformanceIndicator = ({
  campaign,
  hideTooltip,
  tooltipPlacement = "top",
  showAsBadge,
}) => {
  const isDeactivatedCampaign =
    get(campaign, "campaign_status") === CAMPAIGN_STATUS_X.deactivated;
  const isCompletedCampaign =
    get(campaign, "campaign_status") === CAMPAIGN_STATUS_X.completed;

  const performanceState = get(
    campaign,
    "campaign_performance.performance_state"
  );
  const label = toString(campaign);

  const indicatorColor = getCampaignPerformanceIndicatorColor(performanceState);

  const getCampaignIndicatorStatus = () => {
    if (isCompletedCampaign && !showAsBadge) {
      return (
        <Flex alignItems="center">
          <CheckCircle color={`var(${RootColor.Green.DEFAULT})`} />
          <Text.Body className="ml2">{label}</Text.Body>
        </Flex>
      );
    }
    if (isDeactivatedCampaign && !showAsBadge) {
      return (
        <Flex alignItems="center">
          <Ban /> <Text.Body className="ml2">{label}</Text.Body>
        </Flex>
      );
    }

    return (
      <Badge color={indicatorColor} dot mode="light">
        {label}
      </Badge>
    );
  };

  const statusIndicator = getCampaignIndicatorStatus();

  const campaignHasNoEndDate = get(campaign, "end_date", false);
  const tooltip = campaignHasNoEndDate
    ? CAMPAIGN_PERFORMANCE_STATE_TOOLTIPS[performanceState]
        .tooltip_with_end_date
    : CAMPAIGN_PERFORMANCE_STATE_TOOLTIPS[performanceState]
        .tooltip_with_no_end_date;

  const tooltipMessage = (
    <React.Fragment>
      {tooltip}
      <a
        href="https://support.6sense.com/hc/en-us/articles/360055344253"
        target="_blank"
        rel="noopener noreferrer"
        className="ml1"
      >
        <Link>Learn More</Link>
      </a>
    </React.Fragment>
  );

  return (
    <Flex alignItems="center">
      {hideTooltip ? (
        statusIndicator
      ) : (
        <Popover
          body={tooltipMessage}
          trigger="hover"
          placement={tooltipPlacement}
          destroyTooltipOnHide
          title={label}
        >
          <span
            className={css`
              cursor: default;
            `}
          >
            {statusIndicator}
          </span>
        </Popover>
      )}
    </Flex>
  );
};
CampaignPerformanceIndicator.propTypes = {
  campaign: PropTypes.object,
  hideTooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  showAsBadge: PropTypes.bool,
};
CampaignPerformanceIndicator.defaultProps = {
  hideTooltip: false,
  tooltipPlacement: "right",
  showAsBadge: false,
};
CampaignPerformanceIndicator.toString = toString;

export default CampaignPerformanceIndicator;
