import React, { useState } from 'react';
import { css } from 'styles/emotion';
import { Flex, TextArea, Select, useToast } from '@sixsense/core/components';
import { Input } from 'v2-components';
import { classNames } from 'utils/utils';
import {
  COMPANY_WEBSITE_PLACEHOLDER,
  COMPANY_AND_PRODUCT_PLACEHOLDER,
  COMPANY_COMPETITORS_PLACEHOLDER,
  GTM_PARTNERS_PLACEHOLDER,
  ProductSummaryAttributesType,
  TOAST_KEY_MAP,
} from '../constant';
import { validateUrl } from '../utils';
import { AlertCircle } from '@sixsense/core/icons';

const styles = {
  labelStyle: css({
    marginBottom: '8px',
    fontSize: '14px',
  }),
  textArea: css({
    height: '81px',
    fontSize: '14px !important',
    overflow: 'auto !important',
    '::placeholder': {
      color: '#99A0AA',
    },
  }),
  textAreaCount: css({
    marginBottom: '8px',
    color: '#696F7B',
    marginLeft: 'auto',
  }),
  inputStyle: css({
    borderRadius: '6px',
    width: '100%',
    height: '36px',
    padding: '8px 12px',
    fontSize: '14px',
    '&:focus': {
      boxShadow: '0px 0px 0px 4px #CCF0FD',
    },
    '::-webkit-input-placeholder': {
      color: '#99A0AA',
    },
  }),
  errorInput: css({
    border: '1px solid #D92D20 !important',
  }),
  errorMsg: css({
    color: '#D92D20',
    fontSize: '12px',
    lineHeight: '18px',
    marginTop: '3px',
  }),
  multiSelect: css({
    'span, input': {
      fontSize: '14px !important',
    },
    input: {
      minWidth: '100px !important',
    },
    label: {
      padding: '10px 12px !important',
    },
  }),
};

type CompanyDetailsProps = {
  companyAttributes: ProductSummaryAttributesType;
  updateSharedState: any;
};

export const CompanyProductDetails = ({
  companyAttributes,
  updateSharedState,
}: CompanyDetailsProps) => {
  const [websiteurl, setWebsiteUrl] = useState(
    companyAttributes?.company_website
  );
  const [websiteUrlError, setWebsiteUrlError] = useState<Boolean>(false);
  const [focusedField, setFocusedField] = useState(null);
  const [companyProductInfo, setCompanyProductInfo] = useState(
    companyAttributes?.company_product_information || ''
  );
  const [gtmPartnersInfo, setGtmPartnersInfo] = useState(
    companyAttributes?.gtm_partners_and_integrations || ''
  );
  const [competitorsInputValue, setCompetitorsInputValue] = useState('');
  const [competitorsSelectedTags, setCompetitorsSelectedTags] = useState(
    companyAttributes?.competitors?.map((competitor) => ({
      label: competitor,
      value: competitor,
    })) || []
  );
  const toast = useToast();

  const getCreatableOptions = (inputText: string) =>
    Array.from(
      new Set(
        inputText
          .split(',')
          .map((v) => v.trim())
          .filter((v) => !!v)
      )
    );

  const handleWebsiteUrlChange = (e) => {
    const value = e?.target?.value || '';
    const isValidUrl = validateUrl(value);
    setWebsiteUrlError(!isValidUrl);
    updateSharedState('company_website', isValidUrl ? value : '');
    setWebsiteUrl(value);
  };

  const handleCompetitorKeyDown = (event) => {
    if (competitorsSelectedTags?.length >= 20) {
      setCompetitorsInputValue('');
      toast.show({
        type: 'icon',
        title: 'Oops!',
        subtitle: 'You can only add up to 20 competitors.',
        icon: AlertCircle,
        iconColor: 'Error',
        duration: 5000,
        key: TOAST_KEY_MAP.COMPETITORS_VALIDATION,
      });
      return;
    }
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      if (competitorsInputValue) {
        setCompetitorsSelectedTags((prevTags) => {
          const isDuplicate = prevTags.some((tag) => tag.value === competitorsInputValue);
          if (!isDuplicate) {
            return [
              ...prevTags,
              {
                label: competitorsInputValue,
                value: competitorsInputValue,
              },
            ];
          }
          return prevTags;
        });
        setCompetitorsInputValue('');
      }
    }
  };

  const handleCompetitorChanges = () => {
    const competitors = competitorsSelectedTags?.map(
      (competitor) => competitor.value
    );
    updateSharedState('competitors', competitors);
  };

  const onCompetitorsChange = (selectedOptions) => {
    setCompetitorsSelectedTags(selectedOptions || []);
  };

  const handleCompetitorCreateOption = (inputText) =>
    getCreatableOptions(inputText)?.map((v) => ({
      label: v,
      value: v,
    }));

  return (
    <div>
      <Flex direction="column">
        <label className={styles.labelStyle}>Website</label>
        <div>
          <Input
            type="text"
            className={classNames(
              styles.inputStyle,
              websiteUrlError && styles.errorInput
            )}
            placeholder={COMPANY_WEBSITE_PLACEHOLDER}
            value={websiteurl}
            onChange={handleWebsiteUrlChange}
            onFocus={() => setFocusedField('websiteurl')}
            autoFocus={focusedField === 'websiteurl'}
          />
          {websiteUrlError && (
            <div className={styles.errorMsg}>
              {`Invalid URL. Sample url: ${COMPANY_WEBSITE_PLACEHOLDER}`}
            </div>
          )}
        </div>
      </Flex>
      <Flex direction="column" style={{ paddingTop: '20px' }}>
        <Flex style={{ alignItems: 'center' }}>
          <label className={styles.labelStyle}>
            Company and Product Information
          </label>
          <label className={styles.textAreaCount}>
            {companyProductInfo?.length}/5000
          </label>
        </Flex>

        <TextArea
          id="companyproductinfo"
          className={styles.textArea}
          type="textarea"
          value={companyProductInfo}
          onChange={(v) => {
            setCompanyProductInfo(v);
            updateSharedState('company_product_information', v);
          }}
          placeholder={COMPANY_AND_PRODUCT_PLACEHOLDER}
          onFocus={(e) => setFocusedField(e.target.id)}
          autoFocus={focusedField === 'companyproductinfo'}
          maxLength={5000}
        />
      </Flex>
      <Flex direction="column" style={{ paddingTop: '20px' }}>
        <label className={styles.labelStyle}>Competitors</label>
        <Select
          css={css({ width: '100%' })}
          className={styles.multiSelect}
          placeholder={COMPANY_COMPETITORS_PLACEHOLDER}
          size="lg"
          dropdownIcon={null}
          clearable
          multi
          isValidNewOption
          formatCreateLabel
          isSearchable
          value={competitorsSelectedTags}
          onCreateOption={handleCompetitorCreateOption}
          inputValue={competitorsInputValue}
          onInputChange={(input) => setCompetitorsInputValue(input)}
          onChange={onCompetitorsChange}
          onKeyDown={handleCompetitorKeyDown}
          onFocus={() => setFocusedField('competitors')}
          autoFocus={focusedField === 'competitors'}
          onBlur={handleCompetitorChanges}
        />
      </Flex>
      <Flex direction="column" style={{ paddingTop: '20px' }}>
        <Flex style={{ alignItems: 'center' }}>
          <label className={styles.labelStyle}>
            GTM Partners or Integrations
          </label>
          <label className={styles.textAreaCount}>
            {gtmPartnersInfo?.length}/5000
          </label>
        </Flex>

        <TextArea
          id="gtmpartnersinfo"
          className={styles.textArea}
          type="textarea"
          value={gtmPartnersInfo}
          onChange={(v) => {
            setGtmPartnersInfo(v);
            updateSharedState('gtm_partners_and_integrations', v);
          }}
          placeholder={GTM_PARTNERS_PLACEHOLDER}
          onFocus={(e) => setFocusedField(e.target.id)}
          autoFocus={focusedField === 'gtmpartnersinfo'}
          maxLength={5000}
        />
      </Flex>
    </div>
  );
};
