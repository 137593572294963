import React, { ReactNode } from 'react';
import { Flex, Text, Tooltip, Toggle } from '@sixsense/core/components';
import { InfoCircle } from '@sixsense/core/icons';

type ContentToggleProps = {
  text: string;
  configKey: string;
  toggleValue: boolean;
  tooltip?: ReactNode;
  className?: string;
  handleToggleClick: (key: string) => {};
};

const ToggleContent = ({
  text,
  configKey,
  toggleValue,
  handleToggleClick,
  tooltip,
  className,
}: ContentToggleProps) =>
  text ? (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className={`${className}`}
    >
      <Flex gap={4} alignItems="center">
        <Text color={'#6C737F'} size={14}>
          {text}
        </Text>
        {tooltip && (
          <Tooltip overlay={tooltip} placement="top">
            <InfoCircle size={16} />
          </Tooltip>
        )}
      </Flex>
      <div>
        <Toggle
          value={toggleValue}
          onChange={() => handleToggleClick(configKey)}
        />
      </div>
    </Flex>
  ) : (
    <></>
  );

export default ToggleContent;
