import React from "react";
import { CAMPAIGN_SOURCE, CAMPAIGN_SOURCE_LABELS } from "utils/constants";
import { AD_TYPE_IDS, AD_TYPE_LABELS } from "routes/AdvertisingDS/constants";
import sixSenseIcon from "images/6sense-icon.svg";
import LinkedInIcon from "images/linkedin_hq.svg";

export const CAMPAIGNS_ROUTE = "campaigns";
export const SAVE_CLONE_CAMPAIGN_FORM = "SAVE_CLONE_CAMPAIGN_FORM";
export const DEACTIVATE_CAMPAIGN_FORM = "DEACTIVATE_CAMPAIGN_FORM";
export const LINKEDIN_CAMPAIGNS_AD_TYPE_LABEL = "LinkedIn Campaigns";
export const SIXSENSE_CAMPAIGNS_AD_TYPE_LABEL = "6sense Campaigns";
export const SINGLE_IMAGE_AD_FILTER = {
  label: AD_TYPE_LABELS[AD_TYPE_IDS.linkedInSingleImageAd],
  value: AD_TYPE_IDS.linkedInSingleImageAd,
  icon: LinkedInIcon,
};
export const LINKEDIN_VIDEO_AD_FILTER = {
  label: AD_TYPE_LABELS[AD_TYPE_IDS.linkedInVideoAd],
  value: AD_TYPE_IDS.linkedInVideoAd,
  icon: LinkedInIcon,
};
export const BID_TYPES = {
  cpm: "cpm",
  cpc: "cpc",
};

export const CAMPAIGN_CONFIG_FORM = "CAMPAIGN_CONFIG_FORM";

export const CAMPAIGN_DOWNLOAD_FORM = "CAMPAIGN_DOWNLOAD_FORM";

export const ROUTE_STATE_KEY = "campaigns";

export const LINKEDIN_ACTION_PREFIX = "LINKEDIN_ADVERTISING";

export const ALL = "all";
export const CAMPAIGN_AND_AD_GROUPS = "campaignsAndAdGroups";
export const ONLY_CAMPAIGNS = "campaigns";
export const CAMPAIGN = "campaign";
export const BID_TYPE_KEY = "cost_type";
export const DEFAULT_BID_TYPE = BID_TYPES.cpm;
const ACCOUNTS = "account";

export const GROUPING_LABEL = {
  [ALL]: "Campaigns AdGroups and Ads",
  [CAMPAIGN_AND_AD_GROUPS]: "Campaigns AdGroups",
  [ONLY_CAMPAIGNS]: "Campaigns",
};

export const CAMPAIGN_ACTION_DELETE = "delete";

export const DOWNLOAD_OPTIONS = [
  {
    label: "Campaigns",
    value: ONLY_CAMPAIGNS,
  },
  {
    label: "Campaigns, AdGroups",
    value: CAMPAIGN_AND_AD_GROUPS,
  },
  {
    label: "Campaigns, AdGroups and Ads",
    value: ALL,
  },
];
export const SINGLE_DOWNLOAD_OPTIONS = [
  {
    label: "Campaign",
    value: ONLY_CAMPAIGNS,
  },
  {
    label: "Campaign, AdGroups",
    value: CAMPAIGN_AND_AD_GROUPS,
  },
  {
    label: "Campaign, AdGroups and Ads",
    value: ALL,
  },
];

export const DOWNLOAD_TYPE_OPTION = {
  campaign: {
    label: "Campaign",
    value: CAMPAIGN,
  },
  account: {
    label: "Accounts",
    value: ACCOUNTS,
  },
};

export const ACCOUNT_DOWNLOAD_OPTION = [
  { label: "6sense Accounts", value: "6sense" },
  { label: "CRM Accounts", value: "crm" },
];
export const CAMPAIGN_SHEET_COLS_HEADER = {
  campaignName: "Campaign Name",
  adGroup: "AdGroup",
  ad: "Ad",
  externalId: "External ID",
  dataType: "Data Type",
  state: "State",
  startDate: "Start Date",
  endDate: "End Date",
  accountsReached: "Accounts Reached",
  impressions: "Impressions",
  matchedImpressions: "Matched Impressions",
  mediaClicks: "Clicks",
  matchedMediaClicks: "Matched Clicks",
  ctr: "CTR",
  accountCtr: "ACTR",
  cpm: "CPM",
  cpc: "eCPC",
  vtr: "VTR",
  avtr: "AVTR",
  budget: "Budget",
  spend: "Spend",
  newlyEngaged: "Accounts Newly Engaged (Lifetime)",
  increasedEngagement: "Accounts With Increased Engagement (Lifetime)",
  avgIncreasedEngagement: "Avg. Increase in Account Engagement (Lifetime)",
  viewabilityPercent: "Viewability",
  videoError: "Video Error",
  videoStart: "Video Start",
  videoSkip: "Video Skip",
  video25: "Video 25% Complete",
  video50: "Video 50% Complete",
  video75: "Video 75% Complete",
  video100: "Video 100% Complete",
  audienceReached: "Audience Reached",
  audienceMatched: "Audience Matched",
  purchaseOrder: "Purchase Order",
  product: "Product",
};

const commonCampaignOmittedField = [
  "campaignId",
  "createdDate",
  "placement_group_id",
  "placement_id",
  "campaign_subtype",
];

export const videoCampaignFields = [
  "videoRequest",
  "videoError",
  "videoStart",
  "videoSkip",
  "video25",
  "video50",
  "video75",
  "video100",
];

export const sixsenseCampaignOmittedField = [
  ...commonCampaignOmittedField,
  "matchedImpressions",
  "matchedMediaClicks",
  "externalId",
  "audienceReached",
  "audienceMatched",
];

export const retargetingCampaignOmittedField = [
  ...commonCampaignOmittedField,
  "matchedImpressions",
  "matchedMediaClicks",
  "externalId",
];

export const externalCampaignOmittedField = [
  ...commonCampaignOmittedField,
  ...videoCampaignFields,
  "campaignState",
  "startDate",
  "endDate",
  "cpm",
  "cpc",
  "budget",
  "spend",
  "audienceReached",
  "audienceMatched",
];

export const VIDEO_CAMPAIGN_FIELDS = [
  "Video Error",
  "Video Start",
  "Video Skip",
  "Video 25% Complete",
  "Video 50% Complete",
  "Video 75% Complete",
  "Video 100% Complete",
];

export const CAMPAIGN_ACCOUNTS_LIST_HEADER = [
  "6sense Company Name",
  "6sense Country",
  "6sense Domain",
  "Impressions",
  "Clicks",
  "Spend",
  "Website Engagement",
  "Latest Impression",
  "Influenced Form Fills",
  "Delivery",
];

export const CAMPAIGN_CRM_ACCOUNT_LIST_HEADER = [
  "CRM Account ID",
  "6sense ID",
  "CRM Account Name",
  "CRM Country",
  "CRM Domain",
  "Impressions",
  "Clicks",
  "Spend",
  "Website Engagement",
  "Latest Impression",
  "Influenced Form Fills",
  "Delivery",
];

export const CAMPAIGN_CATEGORIES = Object.freeze({
  all: "all",
  active: "active",
  scheduled: "scheduled",
  completed: "completed",
  draft: "draft",
  deactive: "deactive",
  needAction: "needAction",
});

export const CAMPAIGN_STATUS_X = {
  active: "active",
  scheduled: "scheduled",
  completed: "completed",
  draft: "draft",
  deactivated: "deactivated",
};

export const CAMPAIGN_STATUS_LABELS = {
  [CAMPAIGN_STATUS_X.active]: "Active",
  [CAMPAIGN_STATUS_X.scheduled]: "Scheduled",
  [CAMPAIGN_STATUS_X.completed]: "Completed",
  [CAMPAIGN_STATUS_X.draft]: "Draft",
  [CAMPAIGN_STATUS_X.deactivated]: "Deactivated",
};

// Todo: get rid of this constant
export const CAMPAIGN_STATUS = {
  active: { key: "active", label: "Active" },
  scheduled: { key: "scheduled", label: "Scheduled" },
  completed: { key: "completed", label: "Completed" },
  draft: { key: "draft", label: "Draft" },
  deactivated: { key: "deactivated", label: "Deactivated" },
};

export const PLACEMENT_STATE = {
  Deleted: "Deleted",
  Active: "Active",
};

export const campaignConfigStatsDefinition = [
  {
    label: "Accounts Reached",
    content: `Number of targeted accounts shown
    ad impressions since start of your campaign.`,
    isCommon: true,
  },
  {
    label: "Accounts Newly Engaged",
    content: `Number of accounts reached
    that had no engagement on your website in the 30-day period preceding the first campaign
    impression, but visited your website within 30 days of a campaign impression.`,
    isCommon: true,
  },
  {
    label: "Accounts With Increased Engagement",
    content: `Number of
    accounts reached that showed increased engagement on your website between your campaign's
    first and last ad impression on the account and the 30 day period following the last
    impression, than in the 30-day period preceding the first impression on the account.`,
    isCommon: true,
  },
  {
    label: "Avg. Increase in Account Engagement",
    content: `Average
    increase in engagement on your website for accounts showing increased engagement.`,
    isCommon: true,
  },
  {
    label: "Account VTR",
    content: `Accounts reached where visitors came
    to your website within 30 days of an impression without
    clicking through an ad, compared to total accounts reached.`,
    isCommon: true,
  },
  {
    label: "Account CTR",
    content: `Accounts reached where visitors came to your
    website by clicking through an ad, compared to total accounts reached.`,
    isCommon: true,
  },
  {
    label: "Serving Period",
    content: `Date range between which impressions
    are served for a campaign.`,
    isExternal: true,
  },
  {
    label: "Flight Dates",
    content: "Campaign's start and end date.",
    isExternal: false,
  },
  {
    label: "Total Spent",
    content: `Amount of $ spent from the configured
    budget, since start of your campaign.`,
    isExternal: false,
  },
  {
    label: "Impressions / Total Impressions",
    content: "Number of impressions gathered by the campaign.",
    isCommon: true,
  },
  {
    label: "Matched Impressions",
    content: `Number of impressions shown to the targeted
    accounts during the campaign flight.`,
    isExternal: true,
  },
  {
    label: "Clicks / Total Clicks",
    content: "Number of clicks during the campaign flight.",
    isCommon: true,
  },
  {
    label: "Matched Clicks",
    content:
      "Number of clicks by targeted accounts during the campaign flight.",
    isExternal: true,
  },
  {
    label: "CTR",
    content: `Percentage of Ad clickthroughs generated out of matched ad
    impressions served for a campaign.`,
    isCommon: true,
  },
];

export const campaignListStatsDefinition = [
  {
    label: "Accounts Reached",
    content: `Number of targeted accounts shown
    ad impressions since start of your campaign.`,
    isCommon: true,
  },
  {
    label: "Matched Impressions",
    content: `Number of impressions shown to the targeted accounts
    during the campaign flight.`,
    isExternal: true,
  },
  {
    label: "Match Rate",
    content: `Matched Impressions as a percentage
    of Total Impressions for a campaign.`,
    isExternal: true,
  },
  {
    label: "Account VTR",
    content: `Accounts reached where visitors came
    to your website within 30 days of an impression without
    clicking through an ad, compared to total accounts reached.`,
    isCommon: true,
  },
  {
    label: "Account CTR",
    content: `Accounts reached where visitors came to your
    website by clicking through an ad, compared to total accounts reached.`,
    isCommon: true,
  },
  {
    label: "Serving Period",
    content: `Date range between which impressions
    are served for a campaign.`,
    isExternal: true,
  },
  {
    label: "Flight Dates",
    content: "Campaign's start and end date.",
    isExternal: false,
  },
  {
    label: "Total Spent",
    content: `Amount of $ spent from the configured
    budget, since start of your campaign.`,
    isExternal: false,
  },
];

export const AUDIT_TRAIL_FAILED_MSG = "Creative Audit Failed";

// TODO: Remove past tense keys in future.
export const CAMPAIGN_PERFORMANCE_STATES = {
  scheduled: { key: "scheduled", label: "Scheduled" },
  launch_in_progress: { key: "launch_in_progress", label: "Launch" },
  steady_delivery: { key: "steady_delivery", label: "Steady Delivery" },
  under_delivery: { key: "under_delivery", label: "Under Delivery" },
  low_delivery: { key: "low_delivery", label: "Low Delivery" },
  overpaced_delivery: {
    key: "overpaced_delivery",
    label: "Overpaced Delivery",
  },
  no_delivery: { key: "no_delivery", label: "No Delivery" },
  launch_aborted: { key: "launch_aborted", label: "Launch Aborted" },
  delivery_paused: { key: "delivery_paused", label: "Delivery Paused" },
  monitoring: { key: "monitoring", label: "Monitoring" },
  creative_audit_in_progress: {
    key: "creative_audit_in_progress",
    label: "Audit in Progress",
  },
};

const SCHEDULED_DELIVERY_TOOLTIP = (
  <span>
    Scheduled indicates that the campaign is scheduled <br />
    for delivery in future
  </span>
);
const DELIVERY_PAUSED_TOOLTIP = (
  <span>
    Delivery Paused indicates that the campaign delivery <br />
    is paused indefinitely
  </span>
);
const LAUNCH_ABORTED_TOOLTIP = (
  <span>
    Launch Aborted indicates that the Campaign launch <br />
    has been terminated
  </span>
);
const LAUNCH_IN_PROGRESS_TOOLTIP = (
  <span>
    Launch indicates that the campaign is being setup <br />
    and will start delivering soon
  </span>
);

const STEADY_DELIVERY_TOOLTIP_MESSAGE = {
  tooltip_with_no_end_date: (
    <span>
      Steady Delivery indicates that the campaign delivery is 85% of the daily
      spend cap.
    </span>
  ),
  tooltip_with_end_date: (
    <span>
      Steady Delivery indicates that the campaign delivery is healthy and may
      exhaust &gt;85% of budget.
    </span>
  ),
};

const UNDER_DELIVERY_TOOLTIP_MESSAGE = {
  tooltip_with_no_end_date: (
    <span>
      Under Delivery indicates that the campaign delivery is between 50% to 85%
      of the daily spend cap.
    </span>
  ),
  tooltip_with_end_date: (
    <span>
      Under Delivery indicates that the campaign delivery is average and may
      exhaust about 50% to 85% of budget.
    </span>
  ),
};

const LOW_DELIVERY_TOOLTIP_MESSAGE = {
  tooltip_with_no_end_date: (
    <span>
      Low Delivery indicates that the campaign delivery <br /> is &#60;50% of
      the daily spend cap.
    </span>
  ),
  tooltip_with_end_date: (
    <span>
      Low Delivery indicates that the campaign delivery is low and may exhaust
      &#60;50% of budget.
    </span>
  ),
};

const NO_DELIVERY_TOOLTIP_MESSAGE = {
  tooltip_with_no_end_date: (
    <span>
      No Delivery indicates that the campaign has not <br />
      recorded any delivery
    </span>
  ),
  tooltip_with_end_date: (
    <span>
      No Delivery indicates that the campaign has not <br />
      recorded any delivery
    </span>
  ),
};

const OVERPACED_DELIVERY_TOOLTIP_MESSAGE = {
  tooltip_with_no_end_date: (
    <span>
      Overpaced Delivery indicates that the campaign delivery is more than
      optimum.
    </span>
  ),
  tooltip_with_end_date: (
    <span>
      Overpaced Delivery indicates that the campaign delivery is more than
      optimum and may end before time.
    </span>
  ),
};

const MONITORING_TOOLTIP = (
  <span>
    Monitoring indicates that the campaign delivery is being <br />
    monitored for better forecast
  </span>
);
const CREATIVE_AUDIT_IN_PROGRESS = "Audit in Progress";
export const CAMPAIGN_PERFORMANCE_STATE_TOOLTIPS = {
  launch_in_progress: {
    tooltip_with_no_end_date: LAUNCH_IN_PROGRESS_TOOLTIP,
    tooltip_with_end_date: LAUNCH_IN_PROGRESS_TOOLTIP,
  },
  steady_delivery: STEADY_DELIVERY_TOOLTIP_MESSAGE,
  under_delivery: UNDER_DELIVERY_TOOLTIP_MESSAGE,
  low_delivery: LOW_DELIVERY_TOOLTIP_MESSAGE,
  overpaced_delivery: OVERPACED_DELIVERY_TOOLTIP_MESSAGE,
  no_delivery: NO_DELIVERY_TOOLTIP_MESSAGE,
  launch_aborted: {
    tooltip_with_no_end_date: LAUNCH_ABORTED_TOOLTIP,
    tooltip_with_end_date: LAUNCH_ABORTED_TOOLTIP,
  },
  delivery_paused: {
    tooltip_with_no_end_date: DELIVERY_PAUSED_TOOLTIP,
    tooltip_with_end_date: DELIVERY_PAUSED_TOOLTIP,
  },
  scheduled: {
    tooltip_with_no_end_date: SCHEDULED_DELIVERY_TOOLTIP,
    tooltip_with_end_date: SCHEDULED_DELIVERY_TOOLTIP,
  },
  [CAMPAIGN_PERFORMANCE_STATES.monitoring.key]: {
    tooltip_with_no_end_date: MONITORING_TOOLTIP,
    tooltip_with_end_date: MONITORING_TOOLTIP,
  },
  [CAMPAIGN_PERFORMANCE_STATES.creative_audit_in_progress.key]: {
    tooltip_with_no_end_date: CREATIVE_AUDIT_IN_PROGRESS,
    tooltip_with_end_date: CREATIVE_AUDIT_IN_PROGRESS,
  },
};

export const PLACEMENT_AUDIT_STATES = {
  pending: "pending",
  error: "error",
  audited: "audited",
  unknown: "unknown",
};

export const CAMPAIGN_STATES = {
  submitted: "submitted",
  draft: "draft",
};

const CAMPAIGN_DOWNLOAD_BASE_META = [
  {
    value: "period",
    label: "Reporting Period:",
  },
  {
    value: "type_of_report",
    label: "Report Type:",
  },
  {
    value: "time_of_report",
    label: "Time of Report:",
  },
];

export const CAMPAIGN_DOWNLOAD_META = [
  CAMPAIGN_DOWNLOAD_BASE_META[0],
  CAMPAIGN_DOWNLOAD_BASE_META[1],
  {
    value: "grouping",
    label: "Grouped By:",
  },
  CAMPAIGN_DOWNLOAD_BASE_META[2],
];

const SIX_SENSEID = "6SENSEID";
const CRMID = "CRMID";

export const ACCOUNT_DISPLAY_LABEL = {
  [SIX_SENSEID]: "6sense Accounts",
  [CRMID]: "CRM Accounts",
};

export const FLIGHT_STATES = {
  NO_FLIGHT: "no_flight",
  ACTIVE: "active",
  INACTIVE: "inactive",
  ENDED: "ended",
};

export const mappedCategoryTabNames = Object.freeze({
  active: "active",
  draft: "drafts",
  completed: "completed",
  deactive: "deactivated",
  scheduled: "scheduled",
  all: "all",
  needAction: "Need Action",
});

// Note: ad types are also known as campaign sub type on the backend
export const AD_TYPE_FILTERS = {
  sixsenseCampaigns: {
    isSubTitle: true,
    label: SIXSENSE_CAMPAIGNS_AD_TYPE_LABEL,
    icon: sixSenseIcon,
  },
  banner: {
    label: "Banner Ad",
    value: "banner",
    icon: sixSenseIcon,
  },
  native: {
    label: "Native Ad",
    value: "native",
    icon: sixSenseIcon,
  },
  video: {
    label: "Video Ad",
    value: "video",
    icon: sixSenseIcon,
  },
  html5: {
    label: "Html5 Ad",
    value: "html5",
    icon: sixSenseIcon,
  },
  html5Dynamic: {
    label: "Html5 Dynamic Ad",
    value: "html5_dynamic",
    icon: sixSenseIcon,
  },
  linkedInCampaigns: {
    isSubTitle: true,
    label: LINKEDIN_CAMPAIGNS_AD_TYPE_LABEL,
    icon: LinkedInIcon,
  },
  singleImageAd: {
    ...SINGLE_IMAGE_AD_FILTER,
  },
  linkedinVideoAd: {
    ...LINKEDIN_VIDEO_AD_FILTER,
  },
};

export { CAMPAIGN_SOURCE };

export { CAMPAIGN_SOURCE_LABELS };

export const CAMPAIGN_FIELDS_LABELS = {
  segment: "Segment",
  schedule: "Schedule",
  totalCampaignBudget: "Total Campaign Budget",
  maxDailySpend: "Max Daily Spend",
  mediaCPM: "Media CPM",
  maxCPC: "Max CPC",
  bidType: "Bidding Type",
  frequencyCap: "Frequency Cap",
  daypart: "Daypart",
  alertRecipients: "Alert Recipients",
  digitalBillingRecord: "Digital Billing Record",
  campaignDescription: "Campaign Description",
  persona: "Persona",
  contextualTargeting: "Contextual Targeting",
  deviceType: "Device Type",
  inventoryType: "Inventory Type",
  domain: "Domain",
  country: "Country",
  language: "Language",
  profileLanguage: "Profile Language",
  adFormat: "Ad Format",
  flightDates: "Flight Dates (minimum 1 week)",
  maxCPM: "Max CPM",
  inventoryTypeAndViewability: "Inventory Type & Viewability",
  brandSafety: "Brand Safety",
  additionalDetails: "Additional Details",
  product: "Product",
  purchaseOrder: "Purchase Order",
  optimization: "Optimization",
};

export const CAMPAIGN_FIELDS = {
  campaignSource: "campaign_source",
  campaignName: "campaign_name",
  campaignState: "campaign_state",
  folder: "folder",
  segment: "segment",
  adProvider: "ad_provider",
  trackerDistributionMethod: "tracker_distribution_method",
  flightStartDate: "start_date",
  flightEndDate: "end_date",
  isAlwaysOn: "is_always_on",
  budget: "budget",
  dailySpendAmount: "daily_spend_amount",
  bidAmount: "bid_amount",
  bidType: "cost_type",
  frequencyCapping: "frequency_capping",
  jobTargeting: "job_targeting",
  deviceTargets: "device_targets",
  targetedCountries: "country_targets",
  targetedLanguages: "language_targets",
  inventoryTarget: "inventory_target",
  publisherList: "publisher_list",
  contextualSubtopics: "contextual_subtopics",
  daypartTargeting: "daypart_targeting",
  daypartTimezone: "daypart_timezone",
  alertCampaignCreator: "alert_campaign_creator",
  alertRecipients: "alert_recipients",
  emailConfiguration: "campaign_meta.alert_recipients",
  digitalBillingRecord: "purchase_order_number",
  campaignDescription: "description",
  campaignSubtype: "campaign_subtype",
  placementGroups: "placement_groups",
  clickTrackers: "click_trackers",
  renameAdGroup: "rename-ad-group",
  emcNewAdGroupName: "emc-new-ad-group-name",
  audience: "audience",
  linkedinPage: "linkedin_ads_page",
  optimizeViewability: "optimize_viewability",
  brandSafety: "brand_safety_categories",
  product: "product",
  purchaseOrder: "purchase_order",
  hasAccountPacing: "has_account_smoothing",
};

export const BID_TYPE_BID_AMOUNT_LABEL_MAP = {
  [BID_TYPES.cpm]: CAMPAIGN_FIELDS_LABELS.maxCPM,
  [BID_TYPES.cpc]: CAMPAIGN_FIELDS_LABELS.maxCPC,
};

export const BID_TYPE_BID_AMOUNT_INFO = {
  [BID_TYPES.cpm]: `This represents your maximum cost per 1,000 impressions (or CPM),
  including media, data and services costs. Your actual CPM will
  generally be lower than your maximum CPM.`,
  [BID_TYPES.cpc]: `This represents your maximum cost per landing page click (or CPC),
  including media, data and services costs. Your actual CPC will
  generally be lower than your maximum CPC.`,
};

export const ALL_LANGUAGES_LABEL = "Target all languages";
export const noEndDateIndication = "This campaign has no end date";
export const DATE_FORMAT = "MMMM D, YYYY";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";

export const NO_INVENTORY_LIST_LABEL =
  "Continue with existing Global Exclusion List";

export const PREMIUM_LIST_LABEL =
  "Apply Premium Publisher Inclusion List for this campaign";

export const FORM_FIELD_KEYS = {
  PUBLISHER_LIST: "publisher_list",
  LIST_TYPE: "list_type",
  URLs: "publisher_urls",
};

export const PUBLISHER_LIST_TYPE = {
  BLACKLIST: "blacklist",
  WHITELIST: "whitelist",
  PREMIUMLIST: "premium_inclusion",
};

export const PUBLISHER_LIST_TYPE_LABELS = {
  [PUBLISHER_LIST_TYPE.BLACKLIST]:
    "Apply Global Exclusion List + Campaign Exclusion List.",
  [PUBLISHER_LIST_TYPE.WHITELIST]: "Target specific domains for this campaign.",
  [PUBLISHER_LIST_TYPE.PREMIUMLIST]:
    "Target using the 6sense Preferred Publisher List for this campaign.",
};

export const AD_INVENTORY_TYPES = {
  appAndWeb: "app_and_web",
  webOnly: "web_only",
  appOnly: "app_only",
};

export const INVENTORY_TARGET_OPTIONS = [
  { label: "App & Web", value: AD_INVENTORY_TYPES.appAndWeb },
  { label: "Web only", value: AD_INVENTORY_TYPES.webOnly },
  { label: "App Only", value: AD_INVENTORY_TYPES.appOnly },
];

export const BID_TYPE_OPTIONS = {
  [BID_TYPES.cpm]: { label: "CPM Bidding", value: "cpm" },
  [BID_TYPES.cpc]: { label: "CPC Bidding", value: "cpc" },
};

export const SYSTEM_SEGMENT = "system";

export const CAMPAIGN_LIST_PAGE_6SENSE_ACCOUNTS_DOWNLOAD_ID =
  "campaignListPage6SenseAccountsDownload";
export const CAMPAIGN_LIST_PAGE_CRM_ACCOUNTS_DOWNLOAD_ID =
  "campaignListPageCRMAccountsDownload";
export const CDP_DOMAIN_DISTRIBUTION_DOWNLOAD_ID =
  "cdpDomainDistributionDownload";
export const CDP_JLJF_DISTRIBUTION_DOWNLOAD_ID = "cdpJLJFDistributionDownload";
export const CDP_CAMPAIGN_TREND_DOWNLOAD_ID = "cdpCampaignTrendDownload";
export const CDP_EXPORT_ADS_CAMPAIGN_DOWNLOAD = "cdpExportAdsCampaignDownload";
export const CDP_EXPORT_ADS_ACCOUNTS_DOWNLOAD = "cdpExportAdsAccountsDownload";
export const CDP_INFLUENCED_PIPELINE_DOWNLOAD = "cdpInfluencedPipelineDownload";
export const CDP_6SENSE_ACCOUNTS_REACHED_DOWNLOAD =
  "cdp6SenseAccountsReachedDownload";
export const CDP_CRM_ACCOUNTS_REACHED_DOWNLOAD =
  "cdpCRMAccountsReachedDownload";
export const INVENTORY_LIST_FIELD_DOWNLOAD = "inventoryListFieldDownload";
export const PREMIUM_PUBLISHER_LIST_DOWNLOAD = "premiumPublisherListDownload";
export const GLOBAL_AD_INVENTORY_LIST_DOWNLOAD =
  "globalAdInventoryListDownload";
export const EMC_TRACKER_DOWNLOAD = "emcTrackerListDownload";

export const LOG_DOWNLOAD_EVENT_OBJECT_ID_MAP = {
  [CAMPAIGN_LIST_PAGE_6SENSE_ACCOUNTS_DOWNLOAD_ID]: 200,
  [CAMPAIGN_LIST_PAGE_CRM_ACCOUNTS_DOWNLOAD_ID]: 201,
  [CDP_DOMAIN_DISTRIBUTION_DOWNLOAD_ID]: 300,
  [CDP_JLJF_DISTRIBUTION_DOWNLOAD_ID]: 301,
  [CDP_CAMPAIGN_TREND_DOWNLOAD_ID]: 302,
  [CDP_EXPORT_ADS_CAMPAIGN_DOWNLOAD]: 303,
  [CDP_EXPORT_ADS_ACCOUNTS_DOWNLOAD]: 304,
  [CDP_INFLUENCED_PIPELINE_DOWNLOAD]: 305,
  [CDP_6SENSE_ACCOUNTS_REACHED_DOWNLOAD]: 306,
  [CDP_CRM_ACCOUNTS_REACHED_DOWNLOAD]: 307,
  [PREMIUM_PUBLISHER_LIST_DOWNLOAD]: 401,
  [GLOBAL_AD_INVENTORY_LIST_DOWNLOAD]: 402,
  [INVENTORY_LIST_FIELD_DOWNLOAD]: 403,
  [EMC_TRACKER_DOWNLOAD]: 501,
};
