import { omit } from 'lodash/fp';

/**
 * Initialstate of auth HOC
 */
const initialState = {
  loading: false,
  orgId: null,
  authOrg: {},
  OAuth: {
    config: {
      timeoutDuration: null,
      initiationParams: {},
    },
  },
  authOrgName: '',
  authUser: null,
  authToken: null,
  authOrgId: null,
  success: false,
  error: false,
  message: null,
};

/**
 * Bootstrap authentication state
 */
const INITIALIZE_AUTH = 'AUTH/INITIALIZE_AUTH';
const INITIALIZE_AUTH_SUCCESS = 'AUTH/INITIALIZE_AUTH_SUCCESS';

function initialize() {
  return { type: INITIALIZE_AUTH };
}

function initializeSuccess() {
  return { type: INITIALIZE_AUTH_SUCCESS };
}

/**
 * Get authenticated organization
 */
const LOAD_AUTH_ORG = 'AUTH/LOAD_AUTH_ORG';
const SET_AUTH_ORG = 'AUTH/SET_AUTH_ORG';
const SET_AUTH_ORG_NAME = 'AUTH/SET_AUTH_ORG_NAME';
const SET_AUTH_ORG_ERROR = 'AUTH/SET_AUTH_ORG_ERROR';

function loadAuthOrg(orgId) {
  return { type: LOAD_AUTH_ORG, orgId };
}

function setAuthOrg(authOrg) {
  return { type: SET_AUTH_ORG, authOrg, submitting: false };
}

function setAuthOrgName(authOrgName) {
  return { type: SET_AUTH_ORG_NAME, authOrgName };
}

function setAuthOrgError(errorMessage) {
  return { type: SET_AUTH_ORG_ERROR, error: true, errorMessage };
}

/**
 * Logout auth user from application
 */
const LOGOUT = 'AUTH/LOGOUT';

function logout() {
  return { type: LOGOUT };
}

const REFRESH = 'AUTH/REFRESH';

function refresh() {
  return { type: REFRESH };
}

/**
 * Load auth user
 */
const LOAD_AUTH_USER = 'AUTH/LOAD_AUTH_USER';
const LOAD_AUTH_USER_SUCCESS = 'AUTH/LOAD_AUTH_USER_SUCCESS';
const LOAD_AUTH_USER_FAILED = 'AUTH/LOAD_AUTH_USER_FAILED';
const SET_AUTH_USER = 'AUTH/SET_AUTH_USER';

function loadAuthUser(userId, token) {
  return { type: LOAD_AUTH_USER, userId, token };
}

function loadAuthUserSuccess(authUser) {
  return { type: LOAD_AUTH_USER_SUCCESS, authUser };
}

function loadAuthUserFailed(message) {
  return { type: LOAD_AUTH_USER_FAILED, message };
}

function setAuthUser(authUser) {
  return { type: SET_AUTH_USER, authUser };
}

/**
 * Set auth org id
 */
const SET_AUTH_ORG_ID = 'AUTH/SET_AUTH_ORG_ID';

function setOrgId(authOrgId) {
  return { type: SET_AUTH_ORG_ID, authOrgId };
}

/**
 * Set auth token
 */
const SET_AUTH_TOKEN = 'AUTH/SET_AUTH_TOKEN';

function setAuthToken(authToken) {
  return { type: SET_AUTH_TOKEN, authToken };
}

/**
 * Password Bout to expire bruv.
 */
const PASSWORD_UPDATE_REQUIRED = 'AUTH/PASSWORD_UPDATE_REQUIRED';
const PASSWORD_UPDATED = 'AUTH/PASSWORD_UPDATED';
const PASSWORD_UPDATE_NOT_REQUIRED = 'AUTH/PASSWORD_UPDATE_NOT_REQUIRED';

function passwordUpdateRequired(updateMessage) {
  return { type: PASSWORD_UPDATE_REQUIRED, updateMessage };
}
function passwordUpdated() {
  return { type: PASSWORD_UPDATED };
}
function passwordUpdateNotRequired() {
  return { type: PASSWORD_UPDATE_NOT_REQUIRED };
}

/**
 * OAuth util
 */
const INITIALIZE_OAUTH = 'AUTH/INITIALIZE_OAUTH';

/**
 * This action is reusable to implement oauth logins when implementing the same
 * using the backend oauth framework, this implementation triggers actions/functions
 * on success and failure and takes care of the rest.
 *
 * @param {*} initiationParams
 * initiationParams:
 *  - username {string}
 *  - is_sandbox {bool}
 *  - sync_source_type {string}
 *  - onSuccessCallback {func}
 *  - onFailureCallback {func}
 *  - timeoutDuration {number} deafault to 180
 */
function initializeOAuth(initiationParams) {
  return { type: INITIALIZE_OAUTH, initiationParams };
}

function authReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH: {
      const { authOrgName, updateMessage } = state;
      return { ...initialState, authOrgName, updateMessage };
    }
    case PASSWORD_UPDATE_NOT_REQUIRED:
    case PASSWORD_UPDATED: {
      return { ...state, updateMessage: undefined };
    }
    case PASSWORD_UPDATE_REQUIRED: {
      const { updateMessage } = action;
      return { ...state, updateMessage };
    }
    case SET_AUTH_USER: {
      const { authUser } = action;

      return { ...state, authUser };
    }
    case INITIALIZE_AUTH: {
      return { ...state };
    }
    case SET_AUTH_TOKEN: {
      const { authToken } = action;
      return { ...state, authToken };
    }
    case SET_AUTH_ORG_ID: {
      const { authOrgId } = action;

      return { ...state, authOrgId };
    }
    case SET_AUTH_ORG: {
      const { authOrg } = action;
      const { name } = authOrg;

      return {
        ...state,
        authOrg,
        authOrgName: name,
        submitting: false,
        error: false,
        errorMessage: false,
      };
    }
    case SET_AUTH_ORG_ERROR: {
      const { errorMessage } = action;
      return {
        ...state,
        error: true,
        errorMessage,
        submitting: false,
      };
    }
    case SET_AUTH_ORG_NAME: {
      const { authOrgName } = action;
      return { ...state, authOrgName };
    }
    case LOAD_AUTH_USER_SUCCESS: {
      const { authUser } = action;
      return { ...state, authUser };
    }
    case LOAD_AUTH_USER_FAILED: {
      const { message } = action;
      return { ...state, message, error: true };
    }

    case INITIALIZE_OAUTH: {
      const { initiationParams } = action;
      const sanitisedInitiationParams =
        omit(initiationParams, 'onSuccessCallback', 'onFailureCallback');
      return {
        ...state,
        OAuth: {
          ...state.OAuth,
          config: {
            ...state.OAuth.config,
            initiationParams: sanitisedInitiationParams,
          },
        },
      };
    }
    default:
      return state;
  }
}

export const actions = {
  initialize,
  initializeSuccess,
  setAuthOrgName,
  setAuthOrg,
  loadAuthUser,
  loadAuthUserSuccess,
  loadAuthUserFailed,
  loadAuthOrg,
  setAuthOrgError,
  setAuthToken,
  setAuthUser,
  logout,
  refresh,
  setOrgId,
  passwordUpdateRequired,
  passwordUpdated,
  passwordUpdateNotRequired,
  initializeOAuth,
};

export const actionTypes = {
  INITIALIZE_OAUTH,
  INITIALIZE_AUTH,
  SET_AUTH_ORG,
  LOAD_AUTH_USER,
  LOAD_AUTH_ORG,
  LOGOUT,
};

export default authReducer;

