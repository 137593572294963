import { Permission } from '@sixsense/rbac';
// eslint-disable-next-line max-len
import { FACEBOOK_ADS_INTEGRATION_ROUTE } from './routes/SocialAdsIntegration/routes/FacebookAdsIntegration/constants';
// eslint-disable-next-line max-len
import { LINKEDIN_SEGMENT_SYNC_INTEGRATION_ROUTE } from './routes/SocialAdsIntegration/routes/LinkedinAdsIntegration/constants';
// eslint-disable-next-line max-len
import { GOOGLE_ADS_INTEGRATION_ROUTE } from './routes/SocialAdsIntegration/routes/GoogleAdsIntegration/constants';
import { OUTREACH } from './routes/Manage/constants';
import { FEATURE_FLAGS } from 'constants/featureFlags';

export const MAP = 'showMAP';
export const CRM = 'showCRM';
export const MARKETO_API_FORM = 'form_marketo_api_limit';
export const ELOQUA_API_FORM = 'form_eloqua_api_limit';
export const PARDOT_API_FORM = 'form_pardot_api_limit';
export const HUBSPOT_API_FORM = 'form_hubspot_api_limit';
export const SFDC_API_FORM = 'form_sfdc_api_limit';
export const SFDC_DOMAIN_CONFIG_FORM = 'form_sfdc_domain_config';

export const PASSWORD_PLACEHOLDER = '**************';

export const verifySuccessMessage =
  'The credentials were successfully verified. \n' +
  'You can now save your configuration.';
export const verifyFailMessage =
  'The credentials could not be verified.\n' +
  'Please check all your settings and try again.';

export const REST = 'REST';
export const BULK = 'BULK';
export const SOAP = 'SOAP';
export const BATCH = 'BATCH';

export const STRING_MAX_API_LIMIT = 'Api limit should be less than {max}';

export const PASSWORD_POLICY_OPTIONS = [
  { label: 'after 90 days', value: 90 },
  { label: 'Never', value: null },
];

export const VIEW_MODES = {
  SUMMARY: 'SUMMARY',
  CONFIGURING: 'CONFIGURING',
};

export const DEFAULT_TABS = [
  {
    value: 'about',
    label: 'About',
    default: true,
  },
  { value: 'help', label: 'Help & FAQs' },
];

export const INTEGRATION_STATUS = {
  NOT_CONFIGURED: 'NOT_CONFIGURED',
  CONFIGURED: 'CONFIGURED',
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR: 'ERROR',
  UNKNOWN: 'UNKNOWN',
  SYNC_DISABLED: 'SYNC_DISABLED',
  ENABLED: 'ENABLED',
};


/**
 * The edit mode for integration forms.
 *
 * @enum {string}
 */
export const FORM_EDIT_MODE = {
  NONE: 'NONE',
  API_LIMITS: 'API_LIMITS',
  ALL: 'ALL',
  DOMAIN_CONFIG: 'DOMAIN_CONFIG',
};

export const tabItemsFactory = (
  props,
  {
    canViewCRM,
    canViewMAP,
    canViewFB,
    canViewSEP,
    canViewSlack,
    canViewLinkedinCampaignManager,
    canViewGoogleIntegration,
    isSandboxOrg,
  }
) => {
  const {
    hasSFDCIntegration, hasDynamicsIntegration, slackAlertsEnabled, sfdc_sandbox_import,
  } = props;
  return [
    // canViewCRM && {
    //   value: 'crm',
    //   label: 'Customer Relationship Management (CRM)',
    //   isHidden: isSandboxOrg,
    //   description:
    //     'Setup your Production CRM, configure API limits and view your data sync status',
    // },
    canViewCRM && {
      value: 'sandbox',
      label: 'Customer Relationship Management (CRM) Sandbox',
      isHidden: (() => {
        if (isSandboxOrg) return false;
        if (props) return !(hasSFDCIntegration || (sfdc_sandbox_import && !hasDynamicsIntegration));
        return undefined;
      })(),
      description:
        'Setup your Sandbox CRM to test out 6sense score exports in Sandbox only',
    },
    canViewMAP && {
      value: 'map',
      label: 'Marketing Automation Platform (MAP)',
      description:
        'Setup your MAP, configure API limits and view data sync statuses',
    },
    {
      value: GOOGLE_ADS_INTEGRATION_ROUTE,
      label: 'Google Ads',
      description: 'Integrate your Google Ads account',
      isHidden: !canViewGoogleIntegration,
    },
    canViewFB && {
      value: FACEBOOK_ADS_INTEGRATION_ROUTE,
      label: 'Facebook Ads',
      description: 'Integrate your Facebook Ad Accounts',
      hideHeader: true,
    },
    {
      value: LINKEDIN_SEGMENT_SYNC_INTEGRATION_ROUTE,
      label: 'LinkedIn Ads',
      description: 'Integrate your LinkedIn Campaign Manager',
      isHidden: !canViewLinkedinCampaignManager,
    },
    canViewSEP && {
      value: 'sep',
      label: 'Sales Enablement Platform (SEP)',
      description: 'Integrate your organization’s SEP instance',
    },
    canViewSlack && {
      value: 'slack',
      label: 'Slack',
      isHidden: props ? slackAlertsEnabled !== true : undefined,
      description: 'Connect your Slack workspace to 6sense to setup alerts',
    },
    canViewCRM && {
      value: 'email-server',
      label: 'Email Server Integration',
      description: 'Connect your Email servers to 6sense',
    },

    /* having an empty label here since the label for the data syncs page
  is populated by the PageHeader HOC
  */
    {
      value: 'data-syncs',
      label: '',
      isHidden: true,
    },
  ].filter((val) => val && !val.isHidden);
};

export const CRM_NO_EDIT_PERMISSIONS_MESSAGE = `Looks like you don't have sufficient permissions. 
Please contact an administrator.`;

export const CRM_PERMISSIONS = [
  Permission.SETTINGS_INTEGRATION_CRM_EDIT,
  Permission.SETTINGS_INTEGRATION_CRM_VIEW,
];

export const DATA_SYNC_PERMISSIONS = [
  Permission.SETTINGS_INTEGRATION_CRM_EDIT,
  Permission.SETTINGS_INTEGRATION_CRM_VIEW,
  Permission.SETTINGS_INTEGRATION_MAP_EDIT,
  Permission.SETTINGS_INTEGRATION_MAP_VIEW,
];

export const FAILED_AUTH = 'FAILED_AUTH';
export const OAUTH_COMPLETED = 'COMPLETED';
export const OAUTH_POLL_TIMEOUT = 180;

export const CRM_API_CONFIG_TOOLTIP = `6sense uses these API limits to sync data daily in batches.
Setting it to 0 or a very low number could result in your data syncs breaking.`;

export const OAUTH_MESSAGES = {
  COMPLETED:
  "Your organization's CRM is connected to 6sense via Salesforce OAuth",
  FAILED_AUTH: 'Authentication failed, Please Edit Credentials'
  + ' and Sign-in again to connect 6sense to Salesforce OAuth',
  RATE_LIMIT_EXCEEDED:
  '6sense could not connect to Salesforce because API limits have been exceeded.',
  REQUESTED: 'Establishing connection via Salesforce OAuth',
};

export const ACCESS_TOKEN_TOOLTIP = `If you opt out of entering the token, please
whitelist 6sense in Salesforce. If you do not have an IP whitelist, 
you must enter a token.`;

export const SALESFORCE = 'Salesforce';
export const DYNAMICS = 'Dynamics';
export const HUBSPOT_CRM = 'HubspotCrm';
export const PARDOT = 'Pardot';
export const HUBSPOT = 'Hubspot';
export const ELOQUA = 'Eloqua';
export const MARKETO = 'Marketo';
export const SLACK = 'Slack';
export const SALESLOFT = 'salesloft';
export const Outreach = 'outreach';
export const HVS = 'hvs';
export const API_KEY = 'ApiKey';
export const LINKEDIN_CAMPAIGN_MANAGER = 'Linkedin Campaign Manager ';
export const SALESWHALE = 'saleswhale';
export const SNOWFLAKE = 'snowflake';
export const GONG = 'Gong';
export const TECHTARGET = 'TechTarget';
export const SOURCEFORGE = 'SourceForge';

export const validIntegrations = [
  SALESFORCE,
  DYNAMICS,
  HUBSPOT_CRM,
  PARDOT,
  HUBSPOT,
  ELOQUA,
  MARKETO,
  API_KEY,
  SLACK,
  SALESLOFT,
  OUTREACH,
  LINKEDIN_CAMPAIGN_MANAGER,
  SNOWFLAKE,
];

export const INTEGRATIONS_CONFIG = {
  [SALESFORCE]: {
    credentialsFormName: 'form_sfdc_credentials_v2',
    apiFormName: 'form_sfdc_api_v2',
    domainFormName: 'form_sfdc_domain_v2',
    oAuthFormName: 'form_sfdc_oauth_credentials_v2',
    credsApiPath: 'sfdccredential',
    syncSourceIntegrationTypeId: 2,
  },
  [DYNAMICS]: {
    credentialsFormName: 'form_dynamics_credentials_v2',
    credsApiPath: 'dynamicscredential',
    appIdFormName: 'dynamics_app_id',
    syncSourceIntegrationTypeId: 10,
  },
  [HUBSPOT_CRM]: {
    credentialsFormName: 'form_hubspot_credentials_v2',
    credsApiPath: 'hubspotcrmcredential',
    syncSourceIntegrationTypeId: 14,
  },
  [HVS]: {
    syncSourceIntegrationTypeId: 26,
    apiFormName: 'form_hvs_api',
    credsApiPath: 'hvscredential',
  },
  [SALESWHALE]: {
    syncSourceIntegrationTypeId: 29,
  },
  [MARKETO]: {
    syncSourceIntegrationTypeId: 1,
    credentialsFormName: 'form_marketo_credentials_v2',
  },
  [ELOQUA]: {
    syncSourceIntegrationTypeId: 3,
    credentialsFormName: 'form_eloqua_credentials_v2',
  },
  [PARDOT]: {
    syncSourceIntegrationTypeId: 8,
    oAuthFormName: 'form_pardot_oauth_credentials_v2',
    apiFormName: 'form_pardot_api_v2',
  },
  [HUBSPOT]: {
    syncSourceIntegrationTypeId: 9,
    credentialsFormName: 'form_hubspot_credentials_v2',
  },
  [SNOWFLAKE]: {
    syncSourceIntegrationTypeId: 31,
  },
  [GONG]: {
    syncSourceIntegrationTypeId: 43,
  },
};

export const INTEGRATION_STATE = {
  ACTIVE: 'active',
  IN_ACTIVE: 'inactive',
};

export const ONBOARDING_STATES = {
  ONBOARDING: 'ONBOARDING',
  ONBOARDED_WITHOUT_DATASYNC: 'ONBOARDED_WITHOUT_DATASYNC',
  ONBOARDED_WITH_DATASYNC: 'ONBOARDED_WITH_DATASYNC',
};

export const commonBreadcrumbConfig = [
  { href: '/settings', title: 'Settings' },
  { href: '/settings/integration', title: 'Integrations' },
];

export const BADGE_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  FAILURE: 'error',
  PENDING: 'warning',
};

export const CONFIGURATION_TYPES = {
  OAUTH: 'OAUTH',
  BASIC: 'BASIC',
};


export const FEATURE_FLAGS_AND_IDENTIFIERS_MAPPING = {

  [FEATURE_FLAGS.peerspotEnabled]: [
    'PeerSpot',
  ],
  [FEATURE_FLAGS.gartnerEnabled]: [
    'buyer_discovery',
  ],
  [FEATURE_FLAGS.techTargetEnabled]: [
    'TechTarget',
  ],
  [FEATURE_FLAGS.gongEnabled]: [
    'gong',
  ],
  [FEATURE_FLAGS.sourceForgeEnabled]: [
    'SourceForge',
  ],
};

export const READ_ONLY_ACCESS_MESSAGE ='You only have read-only access.';

export const SETTINGS_INTEGRATION_EDIT_PERMISSIONS = [
  Permission.SETTINGS_INTEGRATION_CRM_EDIT,
  Permission.SETTINGS_KEYWORDS_EDIT,
  Permission.SETTINGS_BOMBORA_EDIT,
  Permission.SETTINGS_WEB_TAG_EDIT,
  Permission.SETTINGS_API_KEY_EDIT,
  Permission.SETTINGS_INTEGRATION_CRM_EDIT,
  Permission.SETTINGS_INTEGRATION_PARTNER_INTEGRATION_EDIT,
  Permission.SETTINGS_INTEGRATION_MAP_EDIT,
  Permission.SETTINGS_INTEGRATION_SEP_EDIT,
  Permission.SETTINGS_INTEGRATION_SLACK_EDIT,
];
