export const PERMITTED_USERS = [
  // Leadership
  'rahul.sachdev@6sense.com',
  'danny.khatib@6sense.com',
  'amar@6sense.com',
  'jerome.levadoux@6sense.com',
  'tony.mamone@6sense.com',
  'deepak.anchala@6sense.com',
  'anurag.bhatt@6sense.com',
  'rafael.flores@6sense.com',
  'bharat.singh@6sense.com',
  'viral@6sense.com',
  'pv.peruvemba@6sense.com',
  'premal@6sense.com',
  'cleve.pasarell@6sense.com',
  'sai.nanga@6sense.com',
  'rahul@6sense.com',
  'ashish.krishna@6sense.com',
  'rajeev.kumar@6sense.com',
  'ruchira.kumar@6sense.com',
  'samira.golsefid@6sense.com',
  'pranit.kenjale@6sense.com',
  // Segments Team
  'eva@6sense.com',
  'khushboo.shah@6sense.com',
  'fernanda.munoz@6sense.com',
  'kush.dave@6sense.com',
  'ashutosh.kaur@6sense.com',
  'michael@6sense.com',
  'greg.chamberlain@6sense.com',
  'cole.boothman@6sense.com',
  'leah.talkov@6sense.com',
  'omar.abbasi@6sense.com',
  'radwan.abuodeh@6sense.com',
  'sriharsha.alla@6sense.com',
  'bridger.hahn@6sense.com',
  'vasanth.kumar@6sense.com',
  'david.murray@6sense.com',
  'karan.pandya@6sense.com',
  'jainish.parikh@6sense.com',
  'nitish.rath@6sense.com',
  'nikita.salunkhe@6sense.com',
  'sheeth.rahiman@6sense.com',
  'keane.johnson@6sense.com',
  'rohan.juneja@6sense.com',
  // Product
  'imran.kabir@6sense.com',
  'michael.walter@6sense.com',
  'thu.nguyen@6sense.com',
  'tristan.biles@6sense.com',
  'sonia.jaswal@6sense.com',
  'pruthvi.srungavarapu@6sense.com',
  'arijeet.satapathy@6sense.com',
  'sara.akhavi@6sense.com',
  'ameya.paratkar@6sense.com',
  'alex.lin@6sense.com',
  'john.iannone@6sense.com',
  'jackson.wolfe@6sense.com',
  // Other Eng
  'frank.chu@6sense.com',
  'harsh.kohli@6sense.com',
  'john.iaconis@6sense.com',
  'lucas.donaldson@6sense.com',
  'vikash.kumar@6sense.com',
  'param.viswanathan@6sense.com',
  'pankaj.garg@6sense.com',
  // Late additions
  'rishi.agarwal@6sense.com',
  'tarun.bansal@6sense.com',
  'sagar.shukla@6sense.com',
  'salma.bargach@6sense.com',
  'justin.dielmann@6sense.com',
  'ken@6sense.com',
  'alan.strahinic@6sense.com',
  'maggie.shillington@6sense.com',
  'gaurav.kumar@6sense.com',
  'amit.patel@6sense.com',
  'julia.martin@6sense.com',
  'raghib.ali@6sense.com',
  'kajal.kumari@6sense.com',
  'shantanu.sinha@6sense.com',
  'kyle.wu@6sense.com',
  'ashlee.finch@6sense.com',
  'rupinder.singh@6sense.com',
  'soumya.dixit@6sense.com',
];
