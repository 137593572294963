/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  Flex, Card, Select,
  Text, Badge, Toggle, Tooltip,
  Link, Loading,
  ButtonGroup,
} from '@sixsense/core/components';
import {
  CRMSummaryConfig,
  EXPORT_OPTIONS,
  savedConfigsState,
  SepSummaryConfig,
  unSavedChangesState,
} from '../state';
import { css } from 'styles/emotion';
import {
  useRequest,
  useSetSharedValue,
  useSharedState, useSharedValue,
} from '@sixsense/core';
import { SI_PlANS } from 'routes/Settings/constants';
import { useOrg } from 'hooks';
import {
  sepExportEnrichSettingOptions,
  sepExportEnrichSettingTitle,
} from '../constants';
import { InfoCircle } from '@sixsense/core/icons';
import { AccountSelectContent } from '../components';

type Props = {
  type: "crm" | "sep";
  typeValue: string;
  crmType?: string;
  siPackagePlanName?: string;
  isSandbox?: boolean;
  readOnly?: boolean;
};

const styles = {
  loadingWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: '40px',
  }),
  cardActions: css({
    height: '100%',
    paddingLeft: '16px',
  }),
  cardTitle: css({
    textTransform: 'none',
  }),
  innerCardItem: css({
    minHeight: '60px',
  }),
  selectWidth: css({
    width: '410px',
  }),
  boxBorderTop: css({
    border: '1px solid #F3F4F6',
  }),
  cardItem: css({
    padding: '20px 16px',
    borderBottom: '1px solid #F3F4F6',
    minHeight: '60px',
  }),
};

const CRMSEPContainer = ({
   type, typeValue, crmType, siPackagePlanName, isSandbox, readOnly,
  }: Props) => {
  const org = useOrg();
  const setSavedConfig = useSetSharedValue(savedConfigsState);
  const savedConfig = useSharedValue(savedConfigsState);
  const [unSavedFlag, setUnSavedFlag] = useSharedState(unSavedChangesState);
  const request = useRequest();
  const config = savedConfig[type];
  const [exportOptions, setExportOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [apiCalled, setApiCalled] = useState(false);
  const metadata =
    type === 'crm' ? CRMSummaryConfig[typeValue] : SepSummaryConfig[typeValue];
  if (isSandbox && type === 'crm' && typeValue === 'Salesforce' && metadata.header.mainTitle) {
    metadata.header.mainTitle = `${typeValue} (Sandbox)`;
  }

  const exportOptionsFiltered = EXPORT_OPTIONS.filter((el) => el.integrations.includes(typeValue));

  const getUnifiedMappingProfiles = async (objType, sysName) => {
    try {
      const result = await request(
        // eslint-disable-next-line max-len
        `cart/si/mapping_profile/org/${org.id}/system/${sysName}/object/${objType}`,
      );
      return result;
    } catch (ex) {
      return [];
    }
  };
  const fetchMappingData = async () => {
    setApiCalled(true);
    const mappingDataResults = exportOptionsFiltered.map(async (el) => {
      const mappingData = await getUnifiedMappingProfiles(el.value, typeValue);
      return { ...el, mappingData };
    });
    const resolvedExportOptions = await Promise.all(mappingDataResults);
    setExportOptions(resolvedExportOptions);
    setShowLoader(false);
  };

  if (exportOptions.length === 0 && !apiCalled) {
    fetchMappingData();
  }

  const handleSelectChange = (selectedValue, id) => {
    if (selectedValue === 'newmapping') {
      window.open('/settings/manage?activeContext=mapping-profiles', '_blank');
    } else {
      setSavedConfig({
        ...savedConfig,
        [type]: {
          ...config,
          ...{
            [id]: selectedValue,
          },
        },
      });
      if (!unSavedFlag) {
        setUnSavedFlag(true);
      }
    }

  };

  const handleToggleClick = (configKey) => {
    if (configKey === 'contact_account_sync_enabled' && config[configKey]) {
      setSavedConfig({
        ...savedConfig,
        [type]: {
          ...config,
          ...{
            contact_push_on_account_failure_enabled: false,
            [configKey]: !config[configKey],
          },
        },
      });
    } else {
      setSavedConfig({
        ...savedConfig,
        [type]: {
          ...config,
          [configKey]: !config[configKey],
        },
      });
    }
    if (!unSavedFlag) {
      setUnSavedFlag(true);
    }
  };
  const handleEnrichExportToggle = (configKey, EnrichValue) => {
    setSavedConfig({
      ...savedConfig,
      [type]: {
        ...config,
        [configKey]: EnrichValue,
      },
    });
    if (!unSavedFlag) {
      setUnSavedFlag(true);
    }
  };

  const handleDropdownToggleClick = (id, dropdownSelectedValue) => {
    setSavedConfig({
      ...savedConfig,
      [type]: {
        ...config,
        ...{
          [id]: !config[id],
        },
      },
    });
    if ((!dropdownSelectedValue && !config[id])) {
      setUnSavedFlag(false);
    } else {
      setUnSavedFlag(true);
    }
  };

  const Loader = () => (
    <div className={styles.loadingWrapper}>
      <Loading type="spinner" size={25} />
      <Text >
        {'Loading......'}
      </Text>
    </div>
  );

  const {
    header: {
      logo = '',
      mainTitle = '',
      subTitle = '',
      category = '',
    } = {},
  } = metadata || {};

  return (
    <React.Fragment key={crmType}>
      <Card style={{ margin: 0 }} className="borderRadius2">
        <Card.Header
          title={
            <Flex className={styles.cardTitle} gap={8}>
              <Text size={16} >{mainTitle}</Text>
              <Badge color="success" mode="light" ><Text size={12} >Active</Text></Badge>
            </Flex>
          }
          subTitle={<Text size={14} >{subTitle}</Text>}
          leadingType={'avatar'}
          avatarProps={{
            src: (
              <img
                src={logo} style={{
                  objectFit: 'contain',
                  backgroundColor: '#fff',
                }} alt=""
              />
            ),
            name: '',
          }}
          divider
          actions={[
            <Flex className={styles.cardActions} alignItems="center">
              <Badge color="gray" mode="light" >
                <Text size={12} color="#38414E">{category}</Text>
              </Badge>
            </Flex>,
          ]}
        />
        <Card.Content
          style={{ padding: '6px 10px' }}
        >
          {type === 'crm' &&
            <Card className="borderRadius2">
              <Card.Header
                style={{ padding: '16px 20px' }}
                title={
                  <Text className={styles.cardTitle} size={16} >
                    {type === 'crm' ? 'Leads and Contacts Settings'
                      : 'Prospect Settings'}
                  </Text>
                }
              />
              <AccountSelectContent
                config={config}
                metadata={metadata}
                handleToggleClick={handleToggleClick}
                handleSelectChange={handleSelectChange}
              />
            </Card>
          }
          <Card className="borderRadius2">
            <Card.Header
              style={{ padding: 0 }}
              className={styles.cardTitle}
              title={<Text size={16} className={styles.cardTitle}>Allowed Object for Export</Text>}
            />
            <Card.Content style={{ padding: 0 }}>
              <Flex direction="column" >
                {showLoader && <Loader />}
                {
                  exportOptions.length > 0 && exportOptions.map((el) => {
                    const selectOptions =
                      el.mappingData.map((elem) => (
                        { label: elem.mappingName, value: elem.mappingId, key: elem.mappingId }
                      )) || [];
                    selectOptions.push(el?.mappingData?.length >= 5 ?
                      { label: '+ New Mapping Profile (5 limit reached)', value: 'limit' } :
                    {
                      label: <Link className={'linkText'}>+ New Mapping Profile</Link>,
                      value: 'newmapping',
                    });
                    return (
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        key={el.mappingId}
                        className={styles.cardItem}
                      >
                        <Text size={14} color={'#6C737F'} >
                          {el.value}
                        </Text>
                        <Flex gap={16} alignItems="center">
                          <Select.Labeled
                            id={`mapping_profile_${el.value}`}
                            hideSelectedOptions={false}
                            options={selectOptions}
                            className={styles.selectWidth}
                            value={
                              selectOptions.find((opt) => opt.value === config[el.dropdownKey])
                            }
                            onChange={({ value }) =>
                              handleSelectChange(value, el.dropdownKey)}
                            placeholder="Select Mapping Profile"
                            disabled={!config[el.toggleKey] || readOnly}
                          />
                          <Toggle
                            id={config[el.dropdownKey]}
                            value={config[el.toggleKey]}
                            onChange={() =>
                              handleDropdownToggleClick(el.toggleKey, config[el.dropdownKey])
                            }
                          />
                        </Flex>
                      </Flex>
                    );
                  })
                }
              </Flex>
            </Card.Content>
          </Card>
          {
            type === 'sep' && crmType && siPackagePlanName !== SI_PlANS.PLAN_F ?
              <Card className="borderRadius2">
                <Card.Header
                  title={
                    <Text className={styles.cardTitle} size={16}>
                      Export Constraints
                    </Text>
                  }
                />
                <Card.Content style={{ padding: 0 }}>
                  <Flex className={`${styles.cardItem} mt4`} justifyContent="space-between" >
                    <Flex gap={4} alignItems="center">
                      <Text size={14} color={'#6C737F'} >
                        Allow export of </Text>
                      <Tooltip overlay={metadata?.tooltips?.label6} placement="top">
                        <InfoCircle size={16} />
                      </Tooltip>
                    </Flex>
                    <ButtonGroup
                      className={styles.selectWidth}
                      value={config.export_only_crm_existing_records_to_sep?.toString()}
                      onChange={
                        (v) => v !== config.export_only_crm_existing_records_to_sep?.toString()
                          && (handleToggleClick('export_only_crm_existing_records_to_sep'))
                      }
                    >
                      <ButtonGroup.Button value={'false'} className="borderRadius2 w50">
                        CRM and non-CRM records</ButtonGroup.Button>
                      <ButtonGroup.Button value={'true'} className="borderRadius2 w50">
                        CRM records only</ButtonGroup.Button>
                    </ButtonGroup>
                  </Flex>
                  <Flex className={`${styles.cardItem} mt4`} justifyContent="space-between">
                    <Flex gap={4} alignItems="center">
                      <Text size={14} color={'#6C737F'} >
                        {sepExportEnrichSettingTitle}
                      </Text>
                      <Tooltip overlay={metadata?.tooltips?.label7} placement="top">
                        <InfoCircle size={16} />
                      </Tooltip>
                    </Flex>
                    <ButtonGroup
                      className={styles.selectWidth}
                      value={config.enrich_when_exporting_to_sep?.toString()}
                      onChange={(v) => handleEnrichExportToggle('enrich_when_exporting_to_sep', v)}
                    >
                      {sepExportEnrichSettingOptions.map(({ label, optionValue }) => (
                        <ButtonGroup.Button value={optionValue} className="borderRadius2 w50">
                          {label}</ButtonGroup.Button>
                      ))}
                    </ButtonGroup>
                  </Flex>
                </Card.Content>
              </Card>
              : null
          }
        </Card.Content>
      </Card>
    </React.Fragment>
  );
};

export default CRMSEPContainer;
