import React from 'react';
import { Icon, Link, Text } from 'v2-components';
import { css } from 'styles/emotion';
import { Flex, Grid as GridType } from '@sixsense/core/components';
import { Role } from '../types';
import { getRolePermissionConfigByAppId } from './constants';
import { Permission } from '@sixsense/rbac';
import { pxToRem } from '@sixsense/core/style';
import { useOrg } from 'hooks';
import { useSelector } from '@sixsense/core/versioned/react-redux';
import { featureFlagsSelector } from '@sixsense/core/featureFlags';
import {
  getRolesTabSelectionSelector,
  getRoleByIdSelector,
} from '../selectors';

const Grid: any = GridType;

const renderIcon = (role: Role, view: Permission, edit: Permission) => {
  let type = 'visibility_off';
  let color = Icon.COLORS.GREY2;
  if (role.permissions.includes(edit)) {
    type = 'check';
    color = Icon.COLORS.GREY1;
  } else if (role.permissions.includes(view)) {
    color = Icon.COLORS.GREY1;
    type = 'visibility';
  }
  return <Icon type={type} size="24px" color={color} />;
};

const styles = {
  keyLabel: css({
    fontSize: pxToRem(13),
    color: '#001F32',
  }),
  roleName: css({
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: pxToRem(13),
    fontWeight: 'bold',
    padding: `${pxToRem(10)} 0`,
  }),
  groupTitle: css({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    justifyContent: 'flex-end',
    textAlign: 'right',
    color: '#001F32',
    fontSize: pxToRem(13),
    textTransform: 'uppercase',
    paddingRight: pxToRem(14),
    backgroundColor: '#fff',
  }),
  permissionTitle: css({
    display: 'flex',
    alignItems: 'center',
    fontSize: pxToRem(13),
    color: '#001F32',
    padding: `${pxToRem(10)} ${pxToRem(20)}`,
  }),
  permissionIcon: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  lockIcon: css({
    marginLeft: 8,
    flex: 1,
  }),
};


const RoleComparisonTable = () => {
  const { addons, packages } = useOrg();
  const { flags } = useSelector(featureFlagsSelector);
  const {
    selectedAppId = null,
    selectedRoleIds = [],
  } = useSelector(getRolesTabSelectionSelector);
  const getRoleById = useSelector(getRoleByIdSelector);
  const roles = selectedRoleIds.map((id) => getRoleById(id));

  return (
    <div>
      <Flex alignItems="center" className={styles.keyLabel}>
        <b>KEY:</b>
        <div style={{ width: 16 }} />
        <Icon type="check" size="24px" color={Icon.COLORS.GREY1} />
        <div style={{ width: 8 }} />
          View & Edit
          <div style={{ width: 24 }} />
        <Icon type="visibility" size="24px" color={Icon.COLORS.GREY1} />
        <div style={{ width: 8 }} />
          View Only
          <div style={{ width: 24 }} />
        <Icon type="visibility_off" size="24px" color={Icon.COLORS.GREY2} />
        <div style={{ width: 8 }} />
          No Access
        <div style={{ flex: 1 }} />
        <Icon
          type="lock"
          color={Icon.COLORS.GREY1}
          size={Icon.SIZE.SMALL}
        />
        <div style={{ width: 4 }} />
        <Text type={Text.TYPE.SUBBODY} color={Text.COLOR.GREY1}>
          Feature(s) not included in your subscription.
        </Text>
      </Flex>
      <Grid templateColumns={`10% 15% repeat(${roles.length}, ${75 / roles.length}%)`}>
        {roles.map((role, i) => (
          <Grid.Cell key={role.id} row={1} column={3 + i} className={styles.roleName}>
            <Link isInternal link={`/settings/usermanagement/roles/${role.id}`}>
              {role.name}
            </Link>
          </Grid.Cell>
      ))}
      </Grid>
      {getRolePermissionConfigByAppId(selectedAppId).map((group, gIdx) => (
        <Grid
          key={group.title}
          templateColumns={`10% 15% repeat(${roles.length}, ${75 / roles.length}%)`}
          style={{ backgroundColor: gIdx % 2 === 0 ? '#E5F2FA' : '#fff' }}
        >
          <Grid.Cell
            rowStart={1}
            rowEnd={group.permissions.length + 1}
            column={1}
            className={styles.groupTitle}
          >
            {group.title}
          </Grid.Cell>
          {group.permissions
          .filter(({ featureCheck = () => true }) => featureCheck({ flags }))
          .map(({ accessCheck = () => true, ...config }, i) => (
            <React.Fragment key={config.title}>
              <Grid.Cell row={i + 1} column={2} className={styles.permissionTitle}>
                {config.title}{' '}
                {accessCheck({ addons, packages }) ? undefined : (
                  <Icon
                    type="lock"
                    color={Icon.COLORS.GREY}
                    size={Icon.SIZE.SMALL}
                    className={styles.lockIcon}
                  />
                )}
              </Grid.Cell>
              {roles.map((role, rIdx) => (
                <Grid.Cell
                  key={`${config.title}:${role.id}`}
                  row={i + 1}
                  column={3 + rIdx}
                  className={styles.permissionIcon}
                >
                  {renderIcon(role, config.view, config.edit)}
                </Grid.Cell>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      ))}
    </div>
  );
};

export default RoleComparisonTable;
