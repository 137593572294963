import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  orgFlagsSelector,
  orgSelector,
  isPredictiveSelector,
  packageSelector,
  hasBomboraSelector,
  crmTypeSelector,
} from 'modules/user/selectors';
import { connect } from 'react-redux';
import shallowEqual from 'shallowequal';

const MetaContext = createContext();
const { Provider, Consumer } = MetaContext;

/**
 * This is a context to pass around an organization's flags and packages so we
 * no longer need to connect a component everytime we want to use a flag or validate
 * a package
 *
 * The FlagConsumer can be used when composing a component that will pass and object
 * of the org/user's flags and packages into the component's props
 *
 * You can also use the 'OrgCondition' component which will allows you to
 * show or hide children based on a particular flag
 */
const FlagProvider = (props) => {
  const { flags, packages, children } = props;
  return (
    <Provider value={{ flags, packages }}>
      {children}
    </Provider>
  );
};

FlagProvider.propTypes = {
  children: PropTypes.any,
  flags: PropTypes.any,
  packages: PropTypes.any,
};

const mapStateToProps = (state) => ({
  flags: {
    ...orgFlagsSelector(state),
    is6sense: orgSelector(state) === 4,
    has_predictive: isPredictiveSelector(state),
    hasPredictive: isPredictiveSelector(state),
    slackAlertsEnabled: true, // Easier to turn off quickly here, but clean this up sometime...
    crmMapDataEnabled: isPredictiveSelector(state),
    hasBombora: hasBomboraSelector(state),
    hasSFDCIntegration: crmTypeSelector(state) === 'Salesforce',
    hasDynamicsIntegration: crmTypeSelector(state) === 'Dynamics',
    reportsLandingPageEnabled: true,
  },
  packages: packageSelector(state),
});

const FlagContext = connect(mapStateToProps, undefined, undefined, {
  areStatePropsEqual: ({ flags: nextFlags, ...nextProps }, { flags, ...props }) =>
    shallowEqual(nextFlags, flags) && shallowEqual(nextProps, props),
})(FlagProvider);

const FlagConsumer = (WrappedComponent) => (props) => (
  <Consumer>
    {(flags) => <WrappedComponent {...props} {...flags} />}
  </Consumer>
);

/**
 * @typedef {Object} Flags
 * @property {boolean} is6sense
 * @property {boolean} has_predictive
 * @property {boolean} hasPredictive
 * @property {boolean} has_crm_opportunity
 * @property {boolean} slackAlertsEnabled
 * @property {boolean} crmMapDataEnabled
 * @property {boolean} has_zeniq
 * @property {boolean} is_in_demo_state
 * @property {boolean} reportsLandingPageEnabled
 * @property {boolean} allow_xle_mps
 * @property {boolean} enable_gobal_vm_reporting
 * @property {boolean} orch_credit_reporting_enabled
 */

/**
 * @returns {Flags}
 */
export function useFlags() {
  const { flags } = useContext(MetaContext);
  return flags;
}

FlagContext.FlagConsumer = FlagConsumer;
FlagContext.Consumer = Consumer;

export default FlagContext;
