import { createSelector } from 'reselect';
import { toLower, groupBy, sortBy, get, isEmpty } from 'lodash';
import { permissionsSelector, Permission } from '@sixsense/rbac';
import {
  orgObjSelector,
  crmTypeSelector,
  mapTypeSelector,
  orgFlagsSelector,
  isSandboxOrgSelector,
  emailServerTypeSelector,
  sandboxCRMTypeSelector,
  isExternalSixsenseUserSelector,
  packagePlanSelector,
  userObjectSelector,
} from 'modules/user/selectors';
import {
  areG2CredsValidSelector,
  isTrustRadiusConfiguredSelector,
  isSaleswhaleConfiguredSelector,
  isSnowflakeConfiguredSelector,
  isS3ConfiguredSelector,
} from 'modules/integrations/selectors';

import {
  ACCESS_DENIED_MSG,
  ALREADY_EXISTS,
  SANDBOX_DISABLED,
  CRM_DISABLED_DUE_TO_SANDBOX,
  SALESLOFT,
  OUTREACH,
  MICROSOFT,
  GOOGLE,
  STATUS_CODES,
  STATE_KEYS,
  SIAPP_PLAN_TYPE,
  SI_ROLES_ID,
  GOOGLE_INTEGRATION_DISABLED,
  ABM_ROLES_ID,
  WF_ROLES_ID,
  CE_ROLES_ID,
} from './constants';

import {
  SALESFORCE,
  DYNAMICS,
  HUBSPOT_CRM,
  ELOQUA,
  PARDOT,
  HUBSPOT,
  MARKETO,
  HVS,
} from '../../constants';

// eslint-disable-next-line max-len
import { LINKEDIN_SEGMENT_SYNC_INTEGRATION_ROUTE } from '../SocialAdsIntegration/routes/LinkedinAdsIntegration/constants';
// eslint-disable-next-line max-len
import { FACEBOOK_ADS_INTEGRATION_ROUTE } from '../SocialAdsIntegration/routes/FacebookAdsIntegration/constants';
// eslint-disable-next-line max-len
import { GOOGLE_ADS_INTEGRATION_ROUTE } from '../SocialAdsIntegration/routes/GoogleAdsIntegration/constants';
import { isFeatureFlagEnabledForOrg as isAdvFeatureFlagEnabledForOrg }
from 'routes/Advertising/featureGating/utils';
import { FEATURE_TITLE } from '../LinkedinAdvertising/constants';
import {
  LINKEDIN_ADS_INTEGRATION_SETUP_ROUTE,
  LINKEDIN_ADVERTISING_STATE_KEY,
} from 'utils/constants';
import { integrationsWithoutStatus } from './components/MyIntegrations';
import { SNOWFLAKE_ROUTE } from '../Snowflake/constants';
import { S3_ROUTE } from '../S3/constants';
import { FEATURE_FLAGS } from 'constants/featureFlags';
import { isIntegrationFeatureFlagEnabledForOrg, getMaintenanceInfo } from '../../utils';

const hasPermissions = (availablePermissions, permissionsArray) =>
  permissionsArray.some((_permission) => {
    const hasPermission = availablePermissions.has(_permission);
    return hasPermission;
  });

const applyPermissionsAndRules = (objArr, customPermissionsAndRules) =>
  objArr.map((obj) => {
    const value = customPermissionsAndRules[obj.name];
    if (typeof value === 'undefined') return obj;
    const returnVal = { ...obj, ...value };
    return returnVal;
  });

export const integrationManageSelector = (state) => state['integration-manage'];

export const g2StatusSelector = createSelector(
  areG2CredsValidSelector,
  (g2Valid) => (g2Valid ? STATUS_CODES.SUCCESS : STATUS_CODES.ERROR)
);

export const allIntegrationStatusSelector = createSelector(
  integrationManageSelector,
  (state) => state?.allIntegrationStatus
);

export const integrationStatusManageSelector = (integrationName) =>
  createSelector(allIntegrationStatusSelector, (state) =>
    get(state, integrationName, {})
  );

export const isPredictivePackageSelector = createSelector(
    orgObjSelector,
    ({ packages } = {}) => !get(packages, 'predictive.is_deleted', true)
  );

export const isAdminForSIPlan = (SIPlanName, _userObj) => {
  // const userObj = {
  //   app_roles: [
  //     {
  //       role_id: 3,
  //       app_id: 1,
  //     },
  //     {
  //       role_id: 28,
  //       app_id: 2,
  //     },
  //   ],
  // };
  let adminIds = [];
  switch (SIPlanName) {
    case SIAPP_PLAN_TYPE.C:
      adminIds = [
        SI_ROLES_ID.C_PRIMARY_ADMINISTRATOR,
        SI_ROLES_ID.C_ADMINISTRATOR,
      ];
      break;
    case SIAPP_PLAN_TYPE.D:
      adminIds = [
        SI_ROLES_ID.D_PRIMARY_ADMINISTRATOR,
        SI_ROLES_ID.D_ADMINISTRATOR,
      ];
      break;
    case SIAPP_PLAN_TYPE.E:
      adminIds = [
        SI_ROLES_ID.E_FULL_ADMINISTRATOR,
        SI_ROLES_ID.E_FULL_PRIMARY_ADMINISTRATOR,
      ];
      break;
    case SIAPP_PLAN_TYPE.F:
      adminIds = [
        SI_ROLES_ID.E_LITE_PRIMARY_ADMINISTRATOR,
        SI_ROLES_ID.E_LITE_ADMINISTRATOR,
      ];
      break;
    default:
      break;
  }

  const { app_roles=[] } = _userObj;

  return app_roles.some((role) => adminIds.includes(role.role_id));
};

export const checkIsAdminForABM = (_userObj) => {
  const { app_roles = [] } = _userObj;
  const adminIds = [
    ABM_ROLES_ID.PRIMARY_ADMINISTRATOR,
    ABM_ROLES_ID.ADMINISTRATOR,
    ABM_ROLES_ID.OPERATION_USER,
  ];
  return app_roles.some((role) => adminIds.includes(role.role_id));
};

export const checkIsAdminForWorkflows = (_userObj) => {
  const { app_roles = [] } = _userObj;
  const adminIds = [
    WF_ROLES_ID.PRIMARY_ADMINISTRATOR,
    WF_ROLES_ID.WORKFLOW_ADMINISTRATOR,
  ];
  return app_roles.some((role) => adminIds.includes(role.role_id));
};

export const checkIsWorkflowsUser = (_userObj) => {
  const { app_roles = [] } = _userObj;
  const adminIds = [
    WF_ROLES_ID.PRIMARY_ADMINISTRATOR,
    WF_ROLES_ID.WORKFLOW_ADMINISTRATOR,
    WF_ROLES_ID.REVOPS_USER,
    WF_ROLES_ID.VIEW_ONLY_USER,
  ];
  return app_roles.some((role) => adminIds.includes(role.role_id));
};

export const checkIsAdminForCE = (_userObj) => {
  const { app_roles = [] } = _userObj;
  const adminIds = [
    CE_ROLES_ID.PRIMARY_ADMINISTRATOR,
    CE_ROLES_ID.CE_ADMINISTRATOR,
  ];
  return app_roles.some((role) => adminIds.includes(role.role_id));
};

// This function checks if a user role has access to particular integration or not
const disabledMessageForCRM = (isSandboxOrg, accessDenied=false) => {
  if (isSandboxOrg) {
    return CRM_DISABLED_DUE_TO_SANDBOX;
  } else if (accessDenied) {
    return ACCESS_DENIED_MSG;
  }
  return ALREADY_EXISTS('CRM');
};

const customPermissionAndRulesSelector = createSelector(
  integrationManageSelector,
  orgObjSelector,
  permissionsSelector,
  orgFlagsSelector,
  crmTypeSelector,
  isSandboxOrgSelector,
  mapTypeSelector,
  emailServerTypeSelector,
  isTrustRadiusConfiguredSelector,
  sandboxCRMTypeSelector,
  isExternalSixsenseUserSelector,
  isSaleswhaleConfiguredSelector,
  packagePlanSelector,
  userObjectSelector,
  isPredictivePackageSelector,
  (
    state,
    orgObj,
    permissions,
    flags,
    crmType,
    isSandboxOrg,
    mapType,
    emailServerType,
    isTrustRadiusConfigured,
    sandboxCRMType,
    isExternalSixsenseUser,
    isSaleswhaleConfigured,
    packagePlan,
    userObj,
    hasPredictive,
  ) => {
    const hasSFDCIntegration = crmType === SALESFORCE;
    const hasHubspotCRMIntegration = crmType === HUBSPOT_CRM;
    const isHubspotEnabled = flags.hubspot_crm_enabled;
    const isG2Enabled = flags.g2_integration;
    const isWebtagOnelinerEnabled = flags.enable_ol_webtag;
    const isSnowflakeSetupAllowed =
      flags.export_destination_snowflake_setup_enabled;
    const isS3SetupAllowed = flags.export_destination_s3_setup_enabled;
    // Note: Get rid of this flag from CC?
    // const isAPIKeyManagementEnabled = flags.enable_apikey_management;
    const { sepType } = orgObj;
    const SIPlanName = get(packagePlan, 'SI.plan.name', '');
    const hasGoogleAds = get(flags, FEATURE_FLAGS.hasGoogleAds, false);
    const {
      integrationStatuses: {
        bombora = {},
        facebook = {},
        [STATE_KEYS.linkedinSegments]: linkedinSegments = {},
        [STATE_KEYS.linkedinAdvertising]: linkedinAdvertising = {},
        apikey = {},
        webtag = {},
        slack = {},
        googleAds = {},
        g2 = {},
        PeerSpot = {},
        buyer_discovery = {},
        TechTarget = {},
        Gong={},
        SourceForge = {},
      },
    } = state;

    const isValidSIAdmin = isEmpty(userObj.app_roles)
      ? false
      : userObj.app_roles.some(
          (role) =>
            role.role_id === SI_ROLES_ID.SALES_PRIMARY_ADMIN ||
            role.role_id === SI_ROLES_ID.SALES_ADMIN
        );

    const isAdminForSIPlanC = isAdminForSIPlan(SIAPP_PLAN_TYPE.C, userObj);
    const isAdminForSIPlanD = isAdminForSIPlan(SIAPP_PLAN_TYPE.D, userObj);
    const isAdminForSIPlanE = isAdminForSIPlan(SIAPP_PLAN_TYPE.E, userObj);
    const isAdminForSIPlanF = isAdminForSIPlan(SIAPP_PLAN_TYPE.F, userObj);
    const isAdminForABM = checkIsAdminForABM(userObj);
    const isAdminForWorkflows = checkIsAdminForWorkflows(userObj);
    const isAdminForCE = checkIsAdminForCE(userObj);

    const isAdminForApps = isAdminForABM ||
    isAdminForSIPlanC ||
    isAdminForSIPlanD ||
    isAdminForSIPlanE ||
    isAdminForSIPlanF ||
    isAdminForWorkflows;

    /* Note : This was done because for CE app, user roles were
    not overridden to View Only user during Maintenance mode */
    const { with_read_only = false } = getMaintenanceInfo();

    const isAdminForCEStandalone = isAdminForCE && !isAdminForApps;

    return {
      crm: {
        hidden: isExternalSixsenseUser
          ? false
          : !(
              hasPermissions(permissions, [
                Permission.SETTINGS_INTEGRATION_CRM_VIEW,
                Permission.SETTINGS_INTEGRATION_CRM_EDIT,
              ]) && (isAdminForApps || isAdminForCE)
            ),
      },
      salesforce: {
        label: 'Salesforce (Production)',
        path: '/settings/integration/crm?intType=Salesforce',
        disabled: isSandboxOrg || (crmType && crmType !== SALESFORCE),
        connected: isSandboxOrg ? false : crmType === SALESFORCE,
        disabledMessage: disabledMessageForCRM(isSandboxOrg, isAdminForCE),
      },
      dynamics: {
        label: 'Microsoft Dynamics',
        path: '/settings/integration/crm?intType=Dynamics',
        disabled: isSandboxOrg || (crmType && crmType !== DYNAMICS),
        connected: isSandboxOrg ? false : crmType === DYNAMICS,
        disabledMessage: disabledMessageForCRM(isSandboxOrg, isAdminForCE),
      },
      hubspotcrm: {
        label: 'HubSpot CRM (Production)',
        path: '/settings/integration/crm?intType=HubspotCrm',
        disabled: isSandboxOrg || (crmType && crmType !== HUBSPOT_CRM) ||
        with_read_only,
        connected: isSandboxOrg ? false : crmType === HUBSPOT_CRM,
        hidden: !isHubspotEnabled,
        disabledMessage: disabledMessageForCRM(isSandboxOrg, with_read_only),
      },
      // crm_sandbox: {
      //   disabled: isSandboxOrg
      //     ? false
      //     : !(
      //         hasSFDCIntegration ||
      //         (flags.sfdc_sandbox_import && !hasDynamicsIntegration)
      //       ),
      //   hidden: !hasPermissions(permissions, [
      //     Permission.SETTINGS_INTEGRATION_CRM_VIEW,
      //     Permission.SETTINGS_INTEGRATION_CRM_EDIT,
      //   ]),
      // },
      salesforce_sandbox: {
        label: 'Salesforce (Sandbox)',
        path: '/settings/integration/sandbox?intType=Salesforce',
        description:
          'Connect your Salesforce Sandbox to test capabilities and workflows',
        disabled: isAdminForCEStandalone || (isSandboxOrg
          ? false || (crmType && crmType !== SALESFORCE)
          : !(hasPredictive && (hasSFDCIntegration || (flags.sfdc_sandbox_import && !crmType)))),
        disabledMessage: SANDBOX_DISABLED,
        connected: sandboxCRMType === 'Salesforce',
        hidden: isExternalSixsenseUser ? false : !(isAdminForApps || isValidSIAdmin),
      },
      hubspotcrm_sandbox: {
        label: 'Hubspot CRM (Sandbox)',
        path: '/settings/integration/sandbox?intType=HubspotCrm',
        description:
          'Connect your Hubspot Sandbox to test capabilities and workflows',
        disabled: isAdminForCEStandalone || (isSandboxOrg
          ? false || (crmType && crmType !== HUBSPOT_CRM)
          // eslint-disable-next-line max-len
          :!(hasPredictive && (hasHubspotCRMIntegration || flags.hubspotcrm_sandbox_import && !crmType))),
        disabledMessage: SANDBOX_DISABLED,
        connected: sandboxCRMType === 'Hubspot',
        // Enabling only for sandbox org. Might remove in future.
        hidden: (isExternalSixsenseUser ? false : !isAdminForABM) || !isSandboxOrg,
      },
      map: {
        hidden: isExternalSixsenseUser
          ? false
          : !(
              hasPermissions(permissions, [
                Permission.SETTINGS_INTEGRATION_MAP_VIEW,
                Permission.SETTINGS_INTEGRATION_MAP_EDIT,
              ]) &&
              (isAdminForABM || isAdminForSIPlanE || isAdminForSIPlanF)
            ),
      },
      marketo: {
        path: '/settings/integration/map?intType=Marketo',
        disabled: mapType && mapType !== MARKETO,
        connected: mapType === MARKETO,
        disabledMessage: () => ALREADY_EXISTS('MAP'),
      },
      eloqua: {
        path: '/settings/integration/map?intType=Eloqua',
        disabled: mapType && mapType !== ELOQUA,
        connected: mapType === ELOQUA,
        disabledMessage: () => ALREADY_EXISTS('MAP'),
      },
      pardot: {
        path: '/settings/integration/map?intType=Pardot',
        disabled: mapType && mapType !== PARDOT,
        connected: mapType === PARDOT,
        disabledMessage: () => ALREADY_EXISTS('MAP'),
        largeImage: true,
      },
      hubspot: {
        label: 'HubSpot',
        path: '/settings/integration/map?intType=Hubspot',
        disabled: mapType && mapType !== HUBSPOT,
        connected: mapType === HUBSPOT,
        disabledMessage: () => ALREADY_EXISTS('MAP'),
      },
      mail_server: {
        hidden: isExternalSixsenseUser
          ? false
          : !isAdminForABM,
      },
      google: {
        path: `/settings/integration/email-server?intType=${GOOGLE}`,
        connected: emailServerType === GOOGLE,
        disabled: emailServerType && emailServerType !== GOOGLE,
        disabledMessage: () => ALREADY_EXISTS('MAIL SERVER'),
      },
      microsoft: {
        path: `/settings/integration/email-server?intType=${MICROSOFT}`,
        connected: emailServerType === MICROSOFT,
        disabled: emailServerType && emailServerType !== MICROSOFT,
        disabledMessage: () => ALREADY_EXISTS('MAIL SERVER'),
      },
      partner: {
        hidden: isExternalSixsenseUser
          ? false
          : !(
              isAdminForABM ||
              isAdminForSIPlanD ||
              isAdminForSIPlanE ||
              isAdminForSIPlanF
            ),
      },
      trustradius: {
        label: 'TrustRadius',
        path: '/settings/integration/trustradius',
        connected: isTrustRadiusConfigured,
        hidden: isExternalSixsenseUser
          ? false
          : !hasPermissions(permissions, [
            Permission.SETTINGS_INTEGRATION_PARTNER_INTEGRATION_VIEW,
            Permission.SETTINGS_INTEGRATION_PARTNER_INTEGRATION_EDIT,
          ]) &&
            (isAdminForABM ||
              (isValidSIAdmin &&
                [
                  SIAPP_PLAN_TYPE.D,
                  SIAPP_PLAN_TYPE.E,
                  SIAPP_PLAN_TYPE.F,
                ].includes(SIPlanName))),
      },
      G2: {
        path: '/settings/integration/g2',
        hidden: isExternalSixsenseUser
          ? false
          : !hasPermissions(permissions, [
            Permission.SETTINGS_INTEGRATION_PARTNER_INTEGRATION_VIEW,
            Permission.SETTINGS_INTEGRATION_PARTNER_INTEGRATION_EDIT,
          ]) &&
            (isAdminForABM ||
              (isValidSIAdmin &&
                [
                  SIAPP_PLAN_TYPE.D,
                  SIAPP_PLAN_TYPE.E,
                  SIAPP_PLAN_TYPE.F,
                ].includes(SIPlanName))),
        disabled: !isG2Enabled,
        disabledMessage: ACCESS_DENIED_MSG,
        connected: g2.successState === STATUS_CODES.SUCCESS,
      },
      bombora: {
        path: '/settings/integration/bombora',
        hidden: isExternalSixsenseUser
          ? false
          : !hasPermissions(permissions, [
            Permission.SETTINGS_BOMBORA_VIEW,
            Permission.SETTINGS_BOMBORA_EDIT,
          ]) &&
            (isAdminForABM ||
              (isValidSIAdmin &&
                [
                  SIAPP_PLAN_TYPE.D,
                  SIAPP_PLAN_TYPE.E,
                  SIAPP_PLAN_TYPE.F,
                ].includes(SIPlanName))),
        connected: bombora.successState === STATUS_CODES.SUCCESS,
        largeImage: true,
      },
      sep: {
        hidden: isExternalSixsenseUser
          ? false
          : !(
              hasPermissions(permissions, [
                Permission.SETTINGS_INTEGRATION_SEP_VIEW,
                Permission.SETTINGS_INTEGRATION_SEP_EDIT,
              ]) &&
              (isAdminForABM ||
                isAdminForSIPlanC ||
                isAdminForSIPlanD ||
                isAdminForSIPlanE ||
                isAdminForSIPlanF)
            ),
      },
      salesloft: {
        path: '/settings/integration/sep?intType=salesloft',
        connected: sepType && sepType === SALESLOFT,
        disabled: sepType && sepType !== SALESLOFT,
        disabledMessage: () => ALREADY_EXISTS('SEP'),
      },
      hvs: {
        label: 'Salesforce Sales Engagement',
        path: '/settings/integration/hvs',
        connected: sepType && sepType === HVS,
        disabled: crmType !== SALESFORCE || (sepType && sepType !== HVS),
        disabledMessage: () => {
          if (sepType && sepType !== HVS) {
            return ALREADY_EXISTS('SEP');
          }
          if (crmType !== SALESFORCE) {
            return `In order to set up Salesforce Sales Engagement, you must connect 
            Salesforce CRM to 6sense. Once Salesforce CRM is connected, you can then set 
            up Salesforce Sales Engagement`;
          }
          return null;
        },
      },
      saleswhale: {
        label: '6sense Conversational Email',
        path: '/settings/integration/6sense-conversational-email',
        connected: isSaleswhaleConfigured,
        hidden: isExternalSixsenseUser
          ? false
          : !isAdminForABM,
      },
      outreach: {
        path: '/settings/integration/sep?intType=outreach',
        connected: sepType && sepType === OUTREACH,
        disabled: sepType && sepType !== OUTREACH,
        disabledMessage: () => ALREADY_EXISTS('SEP'),
      },
      slack: {
        hidden: isExternalSixsenseUser
          ? false
          : !(
              hasPermissions(permissions, [
                Permission.SETTINGS_INTEGRATION_SLACK_VIEW,
                Permission.SETTINGS_INTEGRATION_SLACK_EDIT,
              ]) &&
              (isAdminForABM ||
                isAdminForSIPlanC ||
                isAdminForSIPlanD ||
                isAdminForSIPlanE ||
                isAdminForSIPlanF)
            ),
        connected: slack.successState === STATUS_CODES.SUCCESS,
        path: '/settings/integration/slack',
      },
      googleAds: {
        label: '6sense Segments for Google Ads',
        hidden: false,
        connected: googleAds.successState === STATUS_CODES.SUCCESS,
        path: `/settings/integration/${GOOGLE_ADS_INTEGRATION_ROUTE}/`,
        description: `Use 6sense account segments and first party contacts to create customer match 
          segments directly in your Google Ads account`,
        disabled: !hasGoogleAds,
        disabledMessage: GOOGLE_INTEGRATION_DISABLED,
      },
      linkedin_segments: {
        label: '6sense Segments for LinkedIn',
        displaySequence: 1,
        hidden: false,
        path: `/settings/integration/${LINKEDIN_SEGMENT_SYNC_INTEGRATION_ROUTE}/`,
        description: 'Integrate your LinkedIn Ad Accounts',
        connected: linkedinSegments.successState === STATUS_CODES.SUCCESS,
      },
      [LINKEDIN_ADVERTISING_STATE_KEY]: {
        displaySequence: 2,
        label: FEATURE_TITLE,
        hidden: false,
        path: `/${LINKEDIN_ADS_INTEGRATION_SETUP_ROUTE}`,
        connected: linkedinAdvertising.successState === STATUS_CODES.SUCCESS,
      },
      facebook: {
        label: '6sense Segments for Meta',
        hidden: false,
        path: `/settings/integration/${FACEBOOK_ADS_INTEGRATION_ROUTE}/`,
        description: 'Integrate your Meta Business Accounts',
        connected: facebook.successState === STATUS_CODES.SUCCESS,
      },
      apikey: {
        label: 'API Token',
        hidden: isExternalSixsenseUser
          ? false
          : !(
              hasPermissions(permissions, [
                Permission.SETTINGS_API_KEY_EDIT,
                Permission.SETTINGS_API_KEY_VIEW,
              ]) &&
              (isAdminForABM ||
                isAdminForSIPlanC ||
                isAdminForSIPlanD ||
                isAdminForSIPlanE ||
                isAdminForSIPlanF)
            ),
        path: '/settings/integration/apitokenmanagement',
        connected: apikey.successState === STATUS_CODES.SUCCESS,
      },
      webtag: {
        label: 'WebTag',
        hidden: isExternalSixsenseUser
          ? false
          : !(
              hasPermissions(permissions, [
                Permission.SETTINGS_WEB_TAG_VIEW,
                Permission.SETTINGS_WEB_TAG_EDIT,
              ]) &&
              (isAdminForABM ||
                isAdminForSIPlanC ||
                isAdminForSIPlanD ||
                isAdminForSIPlanE ||
                isAdminForSIPlanF)
            ),
        path: isWebtagOnelinerEnabled
          ? '/settings/integration/webtag'
          : '/settings/integration/tagSetup',
        connected: webtag.successState === STATUS_CODES.SUCCESS,
      },
      snowflake: {
        hidden: isExternalSixsenseUser
          ? false
          : !isSnowflakeSetupAllowed ||
            !(
              hasPermissions(permissions, [
                Permission.SETTINGS_INTEGRATION_CRM_VIEW,
                Permission.SETTINGS_INTEGRATION_CRM_EDIT,
              ]) && isAdminForABM
            ),
        path: `/settings/integration/${SNOWFLAKE_ROUTE}`,
        connected: isSnowflakeConfiguredSelector,
      },
      s3: {
        hidden: isExternalSixsenseUser
          ? false
          : !isS3SetupAllowed ||
            !(
              hasPermissions(permissions, [
                Permission.SETTINGS_INTEGRATION_CRM_VIEW,
                Permission.SETTINGS_INTEGRATION_CRM_EDIT,
              ]) && isAdminForABM
            ),
        path: `/settings/integration/${S3_ROUTE}`,
        connected: isS3ConfiguredSelector,
      },
      PeerSpot: {
        label: 'PeerSpot',
        path: '/settings/integration/peerspot',
        showEnabledStatus: PeerSpot.successState === STATUS_CODES.ENABLED,
        hidden: isExternalSixsenseUser ? false : !isAdminForABM,
      },
      buyer_discovery: {
        label: 'Buyer Discovery by Gartner Digital Markets',
        path: '/settings/integration/gartner-digital-markets',
        showEnabledStatus:
          buyer_discovery.successState === STATUS_CODES.ENABLED,
        hidden: isExternalSixsenseUser ? false : !isAdminForABM,
      },
      gong: {
        path: '/settings/integration/gong',
        label: 'Gong',
        showEnabledStatus: Gong.successState === STATUS_CODES.ENABLED,
        hidden: isExternalSixsenseUser ? false :
        !(isAdminForSIPlanC ||
          isAdminForSIPlanD ||
          isAdminForSIPlanE ||
          isAdminForSIPlanF),
      },
      TechTarget: {
        label: 'TechTarget',
        path: '/settings/integration/techtarget',
        showEnabledStatus: TechTarget.successState === STATUS_CODES.ENABLED,
        hidden: isExternalSixsenseUser ? false : !isAdminForABM,
      },
      SourceForge: {
        label: 'SourceForge',
        path: '/settings/integration/sourceforge',
        showEnabledStatus: SourceForge.successState === STATUS_CODES.ENABLED,
        hidden: isExternalSixsenseUser ? false : !isAdminForABM,
      },
    };
  }
);

export const categoriesSelector = createSelector(
  integrationManageSelector,
  customPermissionAndRulesSelector,
  (state, customPermissions) => {
    const { categories } = state;
    return applyPermissionsAndRules(categories, customPermissions);
  }
);

export const groupedCatgoriesSelector = createSelector(
  categoriesSelector,
  (categories) => {
    const recommendedCategories = ['crm', 'map', 'web_and_api_key'];
    const _categories = categories.map((category) => {
      const type = recommendedCategories.includes(category.name)
        ? 'recommended'
        : 'other';
      return { ...category, type };
    });
    return groupBy(_categories, 'type');
  }
);

export const categoryIdToNameMapSelector = createSelector(
  categoriesSelector,
  (categories) =>
    categories.reduce((acc, category) => {
      acc[category.id] = category.long_label;
      return acc;
    }, {})
);

export const categoryNameToIdMapSelector = createSelector(
  categoriesSelector,
  (categories) =>
    categories.reduce((acc, category) => {
      acc[category.name] = category.id;
      return acc;
    }, {})
);

export const integrationsSelector = createSelector(
  integrationManageSelector,
  customPermissionAndRulesSelector,
  orgFlagsSelector,
  (state, customPermissions, orgFeatureFlags) => {
    const { integrations, categories } = state;

    /**
     * This step is to create a separate integrations entry for sandbox values
     * because in the db we are not storing two integrations instead we are using
     * one entry with a `sandbox_available` flag
     *
     * Note: keeping this code intact because maybe down the road I can use it again,
     * if this code is stable for 1yr then nuke it
     */
    const sandboxIntegrations = integrations
      .filter((integration) => integration.sandbox_available)
      .map((integration) =>
        // // get current category name
        // const currentCategory = categories.find(
        //   (category) => category.id === integration.category
        // );
        // // find sandbox category and use its id
        // const sandboxCategoryName = `${currentCategory.name}_sandbox`;
        // const sandboxCategory = categories.find(
        //   (category) => category.name === sandboxCategoryName
        // );
        ({
          ...integration,
          name: `${integration.name}_sandbox`,
          // category: sandboxCategory ? sandboxCategory.id : integration.category,
        })
      );
    const integrations_exclusion_list = ['files', 'beacon', 'site catalyst'];
    const _integrations = integrations
      .filter(
        (integration) => !integrations_exclusion_list.includes(integration.name)
      )
      .filter((integration) =>
        isAdvFeatureFlagEnabledForOrg({
          orgFeatureFlags,
          featureIdentifier: integration.name,
        })
      )
      .filter((integration) =>
        isIntegrationFeatureFlagEnabledForOrg({
          orgFeatureFlags,
          featureIdentifier: integration.name,
        })
      )
      .concat(sandboxIntegrations);

    // find the parent of the category id
    const parentCategoryMap = [...categories].reduce((acc, category) => {
      acc[category.id] = category.parent ? category.parent : category.id;
      return acc;
    }, {});
    /**
     * there maybe a case where a category has a parent category.
     * So we need to map the integrations to that parent category
     */
    const integrationsWithProperCategoryIds = _integrations.map(
      (integration) => ({
        ...integration,
        category: parentCategoryMap[integration.category],
      })
    );
    return applyPermissionsAndRules(
      integrationsWithProperCategoryIds,
      customPermissions
    );
  }
);

export const searchKeywordSelector = createSelector(
  integrationManageSelector,
  (state) => state.searchKeyword
);

export const groupedIntegrationsSelector = createSelector(
  integrationsSelector,
  categoriesSelector,
  searchKeywordSelector,
  (integrations, categories, searchKeyWord) => {
    // group them on basis of categoryId
    const groupedIntegrations = groupBy(
      integrations.filter((integration) => {
        const label = toLower(
          integration.label ? integration.label : integration.name
        );
        const searchKw = toLower(searchKeyWord);
        return label.includes(searchKw);
      }),
      'category'
    );
    const rearrangedGroupedIntegrations = Object.entries(
      groupedIntegrations
    ).reduce(
      (acc, [categoryId, categoryIntegrations]) => ({
        ...acc,
        [categoryId]: sortBy(categoryIntegrations, 'displaySequence'),
      }),
      {}
    );

    const hiddenCategoryIds = categories
      .filter((category) => category.hidden)
      .map((category) => category.id);

    // set hidden field of individual category
    const _groupedIntegrations = Object.keys(
      rearrangedGroupedIntegrations
    ).reduce((acc, categoryId) => {
      if (hiddenCategoryIds.includes(parseInt(categoryId))) {
        acc[categoryId] = rearrangedGroupedIntegrations[categoryId].map(
          (integration) => ({ ...integration, hidden: true })
        );
      } else {
        acc[categoryId] = rearrangedGroupedIntegrations[categoryId];
      }
      return acc;
    }, {});

    const disabledCategoryIds = categories
      .filter((category) => category.disabled)
      .map((category) => category.id);

    // set disabled field of individual category
    return Object.keys(_groupedIntegrations).reduce((acc, categoryId) => {
      if (disabledCategoryIds.includes(parseInt(categoryId))) {
        acc[categoryId] = _groupedIntegrations[categoryId].map(
          (integration) => ({
            ...integration,
            disabled: true,
            disabledMessage: ACCESS_DENIED_MSG,
          })
        );
      } else {
        acc[categoryId] = _groupedIntegrations[categoryId];
      }
      return acc;
    }, {});
  }
);

export const selectedTabSelector = createSelector(
  integrationManageSelector,
  (state) => state.selectedTab
);

// gets the specific integrations state
// export const getIntegrationStatusSelector = (integrationStateKey) => createSelector(
//   integrationManageSelector,
//   (state) => state[integrationStateKey]
// );

export const getIntegrationStatusesSelector = createSelector(
  integrationManageSelector,
  integrationsSelector,
  searchKeywordSelector,
  groupedIntegrationsSelector,
  (
    { allIntegrationStatus = {}, integrationStatuses = {} },
    integrations,
    searchKeyWord,
    grouppedIntegrations
  ) => {
    const availableIntegrations = Object.keys(allIntegrationStatus);
    return integrations
      .filter((integration) => availableIntegrations.includes(integration.name))
      .filter((integration) => {
        const label = toLower(
          integration.label ? integration.label : integration.name
        );
        const searchKw = toLower(searchKeyWord);
        return label.includes(searchKw);
      })
      .map((integration) => {
        // to be removed once advertising integrations apis can be called internally
        const integrationStatus = integrationsWithoutStatus(integration.id)
          ? integrationStatuses[integration.name]
          : allIntegrationStatus[integration.name];

        const grouppedIntegrationObj = grouppedIntegrations[
          parseInt(integration.category)
        ].find((_integration) => _integration.name === integration.name);
        return {
          ...integration,
          ...grouppedIntegrationObj,
          loading: integrationStatus.loading,
          successState: integrationStatus.successState,
        };
      });
  }
);

export const topLevelLoadingSelector = createSelector(
  integrationManageSelector,
  ({ topLevelLoading }) => topLevelLoading
);

export const allStatusLoadingSelector = createSelector(
  allIntegrationStatusSelector,
  ({ loading }) => loading
);
