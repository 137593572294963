import React from 'react';
import { css } from 'styles/emotion';
import { Card, Flex, Button, Text } from '@sixsense/core/components';
import { pxToRem } from '@sixsense/core/style';
import { destroyAllParamsNavigate } from 'utils/navigate';

const styles = {
  button: css({
    marginTop: pxToRem(8),
    width: '314px',
  }),
};

type NoDataProps = {
  title: string;
  noDataStr: string;
};

const NoDataComponent = ({ title, noDataStr }: NoDataProps) => (
  <Card.Content
    style={{
      background: 'white',
      border: '1px solid #C6CACE',
    }}
  >
    <Flex direction="column">
      <Text.SectionHeading size={16}>
        {title}
      </Text.SectionHeading>
      <Button
        onClick={() => {
          destroyAllParamsNavigate('/settings/integration/manage');
        }}
        className={styles.button}
        hierarchy="secondary"
      >
        {noDataStr}
      </Button>
    </Flex>
  </Card.Content>
);

export default NoDataComponent;
