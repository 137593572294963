import React, { useEffect, useState } from 'react';
import { css } from 'styles/emotion';
import {
  Link,
  Flex,
  TextArea,
  FileUpload,
  FileUploaderProvider,
  useToast,
} from '@sixsense/core/components';
import { Input } from 'v2-components';
import { classNames } from 'utils/utils';
import { Plus, Minus, AlertCircle } from '@sixsense/core/icons';
import {
  PAIN_POINTS_PLACEHOLDER,
  VALUE_PROPOSITION_PLACEHOLDER,
  ProductSummaryAttributesType,
  UPLOAD_STATUS,
  TOAST_KEY_MAP,
} from '../constant';
import { get } from 'lodash';
import { validateFiles } from '../utils';
import S3GenAIFileUploader from '../commonComponent/S3GenAIFileUploader';
import FileListItem from './FileListItem';


const styles = {
  labelStyle: css({
    marginBottom: '8px',
    fontSize: '14px',
  }),
  textArea: css({
    height: '81px',
    fontSize: '14px !important',
    overflow: 'auto !important',
    '::placeholder': {
      color: '#99A0AA',
    },
  }),
  textAreaCount: css({
    marginBottom: '8px',
    color: '#696F7B',
    marginLeft: 'auto',
  }),
  inputStyle: css({
    borderRadius: '6px',
    width: '100%',
    height: '36px',
    padding: '8px 12px',
    fontSize: '14px',
    '&:focus': {
      boxShadow: '0px 0px 0px 4px #CCF0FD',
    },
    '::-webkit-input-placeholder': {
      color: '#99A0AA',
    },
  }),
  addRemoveButton: css({
    fontSize: '14px',
    lineHeight: '20px',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
  }),
  removeBtnCss: css({
    color: '#38414E !important',
  }),
  addRemoveIcon: css({
    marginRight: '8px',
  }),
};

type MarketingSalesMaterialProps = {
  marketingSalesAttributes: ProductSummaryAttributesType;
  updateSharedState: any;
  orgId: any;
};
interface MarketingDataProps {
  targeted_persona: string;
  pain_points: string;
  value_proposition: string;
}

export const MarketingSalesMaterial = ({
  marketingSalesAttributes,
  updateSharedState,
  orgId,
}: MarketingSalesMaterialProps) => {
  const [focusedField, setFocusedField] = useState(null);
  const [personaGroup, setPersonaGroup] = useState(
    marketingSalesAttributes?.personas || [
      { targeted_persona: '', pain_points: '', value_proposition: '' },
    ]
  );

  const [existingFiles, setExistingFiles] = useState(
    marketingSalesAttributes?.documents?.map((doc) => ({
      ...doc,
      file: {
        name: doc.file_name || doc.file.name,
      },
      uploadStatus: {
        status: UPLOAD_STATUS.DONE,
      },
    })) || []
  );

  const [items, setItems] = useState([]);
  const toast = useToast();
  const [fetchOptions, setFetchOptions] = useState({});

  useEffect(() => {
    const headers = new Headers();
    const csrfToken = get(window, '__CSRF_TOKEN__', '');

    headers.append('X-CSRFToken', csrfToken);

    if (csrfToken) {
      setFetchOptions({ headers });
    }
  }, []);

  // Handler to update form input
  const handleInputChange = (index, value, name) => {
    const updatedGroups = [...personaGroup];
    updatedGroups[index][name] = value;
    setPersonaGroup(updatedGroups);
  };

  const fileUploader = new S3GenAIFileUploader({
    signedUrlEndpoint: `/sales-ai-settings/${orgId}/admin/email-writer-generate-signed-url/`,
    fetchOptions,
  });

  useEffect(() => {
    const existingFilesLength =
      existingFiles?.filter((file) => file.action !== 'remove').length || 0;
    const totalFilesLength = existingFilesLength + (items?.length || 0);
    if (totalFilesLength > 3) {
      // Calculate how many files to keep from items
      const filesToKeep = Math.max(3 - existingFilesLength, 0);
      // Keep only the required number of files in items
      const allowedItems = items.slice(0, filesToKeep);
      setItems([...allowedItems]);
      // Show the toast message
      toast.show({
        type: 'icon',
        title: 'Oops!',
        subtitle: 'Maximum only 3 files allowed.',
        icon: AlertCircle,
        iconColor: 'Warning',
        duration: 5000,
        key: TOAST_KEY_MAP.TOO_MANY_FILES,
      });
    }
  }, [items, existingFiles]);

  const handleFileChange = (o) => {
    setItems((prevItems) => {
      const nextItems = o(prevItems);
      const existingFilesLength =
        existingFiles?.filter((file) => file.action !== 'remove').length || 0;
      if ((nextItems?.length + existingFilesLength) > 3) {
        toast.show({
          type: 'icon',
          title: 'Oops!',
          subtitle: 'Maximum only 3 files allowed.',
          icon: AlertCircle,
          iconColor: 'Warning',
          duration: 5000,
          key: TOAST_KEY_MAP.TOO_MANY_FILES,
        });
        return prevItems;
      }
      const documents =
        marketingSalesAttributes?.documents ? [...marketingSalesAttributes.documents] : [];
      nextItems.forEach((item) => {
        const isUploadCompleted =
          item.uploadStatus?.status === UPLOAD_STATUS.DONE;
        if (isUploadCompleted) {
          const { document_id } = item.uploadStatus?.url;
          const documentExists = documents.some(
            (doc) => doc.document_id === document_id
          );
          if (!documentExists) {
            documents.push({
              ...item,
              document_id,
              action: 'add',
              category: 'marketing_and_sales_case_studies',
            });
          }
        }
      });
      const idsInNextItems = new Set(nextItems.map((item) => item.id));
      const filteredDocuments = documents.filter(
        (item) => idsInNextItems.has(item.id) || item.action === 'remove' || !item.action
      );
      const seenIds = new Set();
      const uniqueDocuments = [...filteredDocuments, ...existingFiles].filter((item) => {
        const uniqueId = item.id || item.document_id;
        // Check if the id has already been seen
        if (seenIds.has(uniqueId)) {
          return false; // Skip this item as it is a duplicate
        }
        // Add the unique id to the Set and keep this item
        seenIds.add(uniqueId);
        return true;
      });
      updateSharedState('documents', [...uniqueDocuments]);
      return nextItems;
    });
  };

  const acceptedFileTypes = '.pdf,.docx,.txt';

  useEffect(() => {
    updateSharedState('personas', personaGroup);
  }, [personaGroup]);

  const addPersonaGroup = () => {
    if (personaGroup?.length < 20) {
      setPersonaGroup([
        ...personaGroup,
        { targeted_persona: '', pain_points: '', value_proposition: '' },
      ]);
    } else {
      toast.show({
        type: 'icon',
        title: 'Oops!',
        subtitle: 'You can only add up to 20 personas.',
        icon: AlertCircle,
        iconColor: 'Error',
        duration: 5000,
      });
    }
  };

  const removePersonaGroup = (index) => {
    const updatedGroups = personaGroup?.filter((_, i) => i !== index);
    setPersonaGroup(updatedGroups);
  };

  const painPointsPlaceholder = PAIN_POINTS_PLACEHOLDER;

  const onExistingFileRemove = (index: number) => {
    /* id is available on newly added files which are not yet submitted
    ** to productAttributes API. Hence we remove the entire file,
    ** for file that is uploaded, we update the action to 'remove' */
    if (existingFiles[index]?.id) {
      existingFiles.splice(index, 1);
    } else {
      existingFiles[index] = {
        ...existingFiles[index],
        action: 'remove',
      };
    }
    updateSharedState('documents', [...items, ...existingFiles]);
    setExistingFiles([...existingFiles]);
  };

  return (
    <Flex direction="column">
      {personaGroup?.map(
        (
          {
            targeted_persona,
            pain_points,
            value_proposition,
          }: MarketingDataProps,
          index: number
        ) => (
          <Flex direction="column" style={{ paddingBottom: '32px' }}>
            <Flex direction="column">
              <Flex justifyContent="space-between">
                <label className={styles.labelStyle}>
                  {index + 1}: Target Persona
                </label>
                {personaGroup?.length > 1 && (
                  <Flex direction="column">
                    <Link
                      className={`${styles.addRemoveButton} ${styles.removeBtnCss}`}
                      onClick={() => removePersonaGroup(index)}
                    >
                      {' '}
                      <Minus size={16} className={styles.addRemoveIcon} />
                      Remove
                    </Link>
                  </Flex>
                )}
              </Flex>
              <div>
                <Input
                  title="targetedPersona"
                  id="targetedpersona"
                  type="text"
                  className={classNames(styles.inputStyle)}
                  placeholder="Examples: VP of Human Resources or Chief Security Officer"
                  value={targeted_persona}
                  onChange={({ target }) =>
                    handleInputChange(index, target.value, 'targeted_persona')
                  }
                  onFocus={(e) => setFocusedField(e.target.id)}
                  autoFocus={focusedField === 'targetedpersona'}
                  onBlur={() => updateSharedState('personas', personaGroup)}
                />
              </div>
            </Flex>
            <Flex direction="column" style={{ paddingTop: '20px' }}>
              <Flex style={{ alignItems: 'center' }}>
                <label className={styles.labelStyle}>Pain Points</label>
                <label className={styles.textAreaCount}>
                  {pain_points?.length}/5000
                </label>
              </Flex>

              <TextArea
                id="painpoints"
                className={styles.textArea}
                type="textarea"
                value={pain_points}
                onChange={(value) =>
                  handleInputChange(index, value, 'pain_points')
                }
                placeholder={painPointsPlaceholder}
                onFocus={(e) => setFocusedField(e.target.id)}
                autoFocus={focusedField === 'painpoints'}
                maxLength={5000}
                onBlur={() => updateSharedState('personas', personaGroup)}
              />
            </Flex>
            <Flex direction="column" style={{ paddingTop: '20px' }}>
              <Flex style={{ alignItems: 'center' }}>
                <label className={styles.labelStyle}>Value Proposition</label>
                <label className={styles.textAreaCount}>
                  {value_proposition?.length}/5000
                </label>
              </Flex>

              <TextArea
                id="valueproposition"
                className={styles.textArea}
                type="textarea"
                value={value_proposition}
                onChange={(value) =>
                  handleInputChange(index, value, 'value_proposition')
                }
                placeholder={VALUE_PROPOSITION_PLACEHOLDER}
                onFocus={(e) => setFocusedField(e.target.id)}
                autoFocus={focusedField === 'valueproposition'}
                maxLength={5000}
                onBlur={() => updateSharedState('personas', personaGroup)}
              />
            </Flex>
          </Flex>
        )
      )}
      {personaGroup?.length <= 20 ? (
        <Flex direction="column" style={{ width: '190px' }}>
          <Link className={styles.addRemoveButton} onClick={addPersonaGroup}>
            <Plus size={16} className={styles.addRemoveIcon} />
            Add another persona
          </Link>
        </Flex>
      ) : null}
      <Flex direction="column" style={{ paddingTop: '32px' }}>
        <label className={styles.labelStyle}>Case Studies</label>
        <FileUploaderProvider value={fileUploader}>
          <FileUpload
            items={items}
            onChange={handleFileChange}
            accept={acceptedFileTypes}
            showFileIcon
            multiple
            uploadUserMessage="TXT, PDF or DOCX (max. 1 Mb)"
            validateFile={(file) => validateFiles(file, items)}
          />
        </FileUploaderProvider>
        {existingFiles?.map((iteratingFile, index) => (
          iteratingFile.action !== 'remove' && (
            <Flex direction="column" style={{ paddingTop: '10px' }} key={iteratingFile.id}>
              <FileListItem
                item={iteratingFile}
                showFileIcon
                onRemove={() => onExistingFileRemove(index)}
              />
            </Flex>
          )
        ))}
      </Flex>
    </Flex>
  );
};
