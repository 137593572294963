// selectors
// These selectors just get accountDetail and down, not the whole state
import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash';
import { App, SalesDefaultRole } from '@sixsense/rbac';
import { SI_PlANS } from './constants';
import { getAdminIdListByAppId, getPrimaryAdminListByAppId } from
  'routes/Settings/routes/UserManagementV2/AppManageConfig/apps.roles.config';

const stateSelector = (state) => state;

const settingsSelector = createSelector(
  stateSelector,
  (state) => state.settings,
);

const tokenSelector = createSelector(
  settingsSelector,
  (settings) => settings.token,
);

const tokenThirdPartySelector = createSelector(
  settingsSelector,
  (settings) => settings.tokenThirdParty,
);

const loadSelector = createSelector(
  tokenSelector,
  tokenThirdPartySelector,
  (token, tokenThirdParty) =>
    [token, tokenThirdParty].every((item) => item.loaded),
);

const authTypeSelector = createSelector(
  settingsSelector,
  (settings) => settings?.authType
);

const siPackagePlanNameSelector = createSelector(
  stateSelector,
  (state) => get(state, 'user.user.organization.packagePlan.SI.plan.name', '')
);

const isOrgSiOnlySelector = createSelector(
  stateSelector,
  (state) => {
    const userRoles = get(state, 'user.user.app_roles', []);
    const isABM = userRoles.find((el) => el.app_id === App.ABM);
    const isSales = userRoles.find((el) => el.app_id === App.SALES);
    return (!isABM && isSales);
  }
);

const isPlanTeamOrGrowthSelector = createSelector(
  stateSelector,
  (state) => {
    const plan = get(state, 'user.user.organization.packagePlan.SI.plan.name', '');
    return [SI_PlANS.PLAN_C, SI_PlANS.PLAN_D].includes(plan);
  }
);


// check if user is primary admin
const isPrimaryAdminSelector = createSelector(
  stateSelector,
  (state) => {
    const app_roles = get(state, 'user.user.app_roles', []);

    const isValidSIPA = isEmpty(app_roles)
      ? false
      : app_roles.some(
          (role) =>
            getPrimaryAdminListByAppId()[App.SALES].includes(role.role_id)
        );

    const isValidWorklfowPA = isEmpty(app_roles)
      ? false
      : app_roles.some(
          (role) =>
            getPrimaryAdminListByAppId()[App.WORKFLOWS].includes(role.role_id)
        );

    return isValidSIPA && isValidWorklfowPA;
  }
);

const isWorkflowEnabledSelector = createSelector(
  stateSelector,
  (state) => {
    const app_roles = get(state, 'user.user.app_roles', []);

    const isWorkflowPAAdmin = isEmpty(app_roles)
      ? false
      : app_roles.some(
          (role) =>
            getPrimaryAdminListByAppId()[App.WORKFLOWS].includes(role.role_id) ||
            getAdminIdListByAppId()[App.WORKFLOWS].includes(role.role_id)
        );

    return isWorkflowPAAdmin;
  }
);

const isInternalStaffUserSelector = createSelector(stateSelector, (state) => (
    get(state, 'user.user.is_staff', false) ||
    get(state, 'user.user.is_internaluser', false)
  ));

const siInternalLicenseTypeSelector = createSelector(
  stateSelector,
  isInternalStaffUserSelector,
  (state, internalStaffUser) => {
    const app_roles = get(state, 'user.user.app_roles', []);
    return internalStaffUser && app_roles?.some((role) =>
      [SalesDefaultRole.E_LITE_VIEW_ONLY_6SI_STAFF_ROLE,
        SalesDefaultRole.E_LITE_ADMINISTRATOR,
      ].includes(role.role_id));
  }
);

export {
  settingsSelector,
  tokenSelector,
  tokenThirdPartySelector,
  loadSelector,
  authTypeSelector,
  siPackagePlanNameSelector,
  isOrgSiOnlySelector,
  isPlanTeamOrGrowthSelector,
  isPrimaryAdminSelector,
  isWorkflowEnabledSelector,
  siInternalLicenseTypeSelector,
  isInternalStaffUserSelector,
};
