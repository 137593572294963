import {
  flow,
  get,
  includes,
  isArray,
  isEmpty,
  isEqual,
  omit,
  partialRight,
  values,
  find,
} from "lodash";
import {
  CAMPAIGN_ACCOUNTS_LIST_HEADER,
  CAMPAIGN_AND_AD_GROUPS,
  CAMPAIGN_CATEGORIES,
  CAMPAIGN_CRM_ACCOUNT_LIST_HEADER,
  CAMPAIGN_DOWNLOAD_META,
  CAMPAIGN_PERFORMANCE_STATES,
  CAMPAIGN_SHEET_COLS_HEADER,
  CAMPAIGN_SOURCE,
  CAMPAIGN_STATES,
  CAMPAIGN_STATUS_X,
  FLIGHT_STATES,
  mappedCategoryTabNames,
  ONLY_CAMPAIGNS,
  PLACEMENT_STATE,
  VIDEO_CAMPAIGN_FIELDS,
  DATE_FORMAT_YYYY_MM_DD,
  SYSTEM_SEGMENT,
} from "./constants";
import { saveAs } from "file-saver";
import moment from "moment";
import {
  capitalize,
  coerceLocaleString,
  formatDate,
  localeString,
  numberToDollar,
  overrideDisplayfp,
  safeNumber,
  safePercent,
  toDisplayfp,
  toFixedfp,
  validNumber,
} from "utils/utils";
import entriesfp from "lodash/fp/entries";
import mapfp from "lodash/fp/map";
import sortByfp from "lodash/fp/sortBy";
import {
  DATE_FORMAT,
  LINKEDIN_ADVERTISING_TOOLTIPS,
  LINKEDIN_PAGE_STATUS,
  SPONSORED_CONTENT_POSTER_STATUS,
} from "utils/constants";
import { getFormattedDate } from "./routes/Analytics/utils";
import { DATE_FILTER_OPTIONS_VALUES } from "./components/DateFilter/constants";
import { Permission, usePermissionCheck } from "@sixsense/rbac";
import { CLASSIFICATION_TYPES } from "routes/AdvertisingDS/ducks/campaignClassifications/constants";
import {
  DISALLOW_EXTERNAL_SIXSENSE_USER,
  VIEWABILITY,
} from "routes/AdvertisingDS/constants";
import { isFeatureFlagEnabledForOrg } from "routes/AdvertisingDS/featureGating/utils";
import { LINKEDIN_JOB_PROFILE_AVG_CTR_KEY } from "routes/Advertising/routes/Campaigns/routes/Analytics/constants";
import { getProductFromCampaignDetails } from "./routes/CreateCampaign/utils";
import { CAMPAIGN_DESCRPITORS_FLAG } from "routes/AdvertisingDS/featureGating/constants";

const getMetaDataRows = (meta) => {
  if (isEmpty(meta)) return "";
  return CAMPAIGN_DOWNLOAD_META.map(
    ({ label, value }) => `${[label, meta[value]].join(",")}\n`
  )
    .join("")
    .concat("\n\n");
};

const campaignAnalyticsSheetData = (
  info,
  { options: { sheetType, omittedField } },
  orgFeatureFlags
) => {
  const fieldsToBeOmitted = [...omittedField, "videoRequest"];
  const campaign = get(info, "meta.campaign");
  if (sheetType === ONLY_CAMPAIGNS) {
    fieldsToBeOmitted.push("adGroup", "ad");
  } else if (sheetType === CAMPAIGN_AND_AD_GROUPS) {
    fieldsToBeOmitted.push("ad");
  }

  if (
    !(
      isFeatureFlagEnabledForOrg({
        orgFeatureFlags,
        featureIdentifier: VIEWABILITY,
      }) &&
      isViewabilityAvailable(
        get(campaign, "campaign_source"),
        get(campaign, "end_date"),
        get(campaign, "campaign_status")
      )
    )
  ) {
    fieldsToBeOmitted.push("viewabilityPercent");
  }

  if (
    !isFeatureFlagEnabledForOrg({
      orgFeatureFlags,
      featureIdentifier: CAMPAIGN_DESCRPITORS_FLAG,
    })
  ) {
    fieldsToBeOmitted.push(...["purchaseOrder", "product"]);
  }

  const columns = values(omit(CAMPAIGN_SHEET_COLS_HEADER, fieldsToBeOmitted));
  const metaDataRows = getMetaDataRows(info.meta);
  const headerColumns = `${columns.join(",")}\n`;
  const sheetData = info.data
    .map((row) => {
      const refinedData = omit(row, fieldsToBeOmitted);
      return `${values(refinedData).join(",")}\n`;
    })
    .join("");
  return `${metaDataRows}${headerColumns}${sheetData}`;
};

const campaignAccountsSheetData = (
  info,
  { byCRMId, isVideoCampaign = true, isListDownload = false, hasAccountPacing }
) => {
  let headers = byCRMId
    ? CAMPAIGN_CRM_ACCOUNT_LIST_HEADER
    : CAMPAIGN_ACCOUNTS_LIST_HEADER;
  if (!hasAccountPacing) {
    headers = headers.filter((header) => header.toLowerCase() !== "delivery");
  }
  if (isListDownload) {
    headers = ["Campaign ID", ...headers];
  }
  if (isVideoCampaign) {
    headers = [...headers, ...VIDEO_CAMPAIGN_FIELDS];
  }
  const metaDataRows = getMetaDataRows(info.meta);
  const headerColumns = `${headers.join(",")}\n`;
  const sheetData = info.data
    .map((row) => {
      const refinedData = omit(row, ["videoRequest"]);
      return `${values(refinedData).join(",")}\n`;
    })
    .join("");
  return `${metaDataRows}${headerColumns}${sheetData}`;
};

export const createCSV = (toString) => (data, config, orgFeatureFlags) => {
  const stringData = toString(data, config, orgFeatureFlags);
  const blob = new Blob([stringData], { type: "text/csv;charset=utf-8" });
  const { csvName = "Sheet" } = config;
  const csvNameWithExtension = `${csvName}.csv`;
  saveAs(blob, csvNameWithExtension);
  return { file_size: blob.size, file_name: csvNameWithExtension };
};

export const downloadCampaignCSV = createCSV(campaignAnalyticsSheetData);

export const downloadAccountListCSV = createCSV(campaignAccountsSheetData);

// [NOTE] deprecated, use campaignStatus() instead
export const getCampaignType = (campaign) => {
  const {
    flight_state,
    start_date,
    campaign_state,
    deactivation_reason,
    campaign_source,
  } = campaign;

  if (campaign_source === CAMPAIGN_SOURCE.LINKEDIN) {
    if (flight_state === "active") return CAMPAIGN_CATEGORIES.active;
    if (flight_state === "ended" || flight_state === "inactive") {
      return CAMPAIGN_CATEGORIES.completed;
    }

    return null;
  }

  if (flight_state === "active" && moment().isSameOrAfter(start_date, "day")) {
    return CAMPAIGN_CATEGORIES.active;
  }
  if (flight_state === "active" && moment().isBefore(start_date, "day")) {
    return CAMPAIGN_CATEGORIES.scheduled;
  }
  if (flight_state === "inactive" && deactivation_reason) {
    return CAMPAIGN_CATEGORIES.deactive;
  }
  if (flight_state === "ended") {
    return CAMPAIGN_CATEGORIES.completed;
  }
  if (campaign_state === "draft") {
    return CAMPAIGN_CATEGORIES.draft;
  }
  return CAMPAIGN_CATEGORIES.all;
};

export const getSegmentName = (campaign) => get(campaign, "segment.name", "");
export const getSegmentId = (campaign) => get(campaign, "segment.id", "");

export const getNeedActionCampaignIndicators = (campaign, minBudgetAmount) => {
  const needAction = get(campaign, "need_action", []);
  const minimum_allowed_budget =
    get(campaign, "li_ads_min_budget_allowed") || minBudgetAmount;

  const OPTIMIZATION = {
    creative_audit_failed: {
      message: "Please re-submit failed creatives for additional inventory.",
      description: null,
    },
    no_active_ads: {
      message: "Please activate Ad placements for delivery.",
      description:
        "All creative ads are deactivated/deleted for this campaign.",
    },
    no_active_accounts: {
      message:
        "The segment has no active accounts. Please modify or change segment " +
        "definition to increase reach.",
      description: "The targeted segment has no active account membership.",
    },
    out_of_budget: {
      message: "Re-load campaign budget to resume delivery.",
      description: "The campaign budget has been fully exhausted.",
    },
    reaching_budget: {
      message: ({ campaign_performance = {} }, meta = {}) => {
        const { estimated_days_remaining } = meta;
        const performance_state = get(
          campaign_performance,
          "performance_state",
          ""
        );
        const isOverpaced =
          performance_state ===
          CAMPAIGN_PERFORMANCE_STATES.overpaced_delivery.key;
        if (isOverpaced && estimated_days_remaining < 10) {
          return "Campaign may end sooner than expected, consider increasing the budget.";
        }

        return "Campaign is nearing budget, consider reviewing it.";
      },
      description: "The campaign budget will be exhausted soon.",
    },
    low_audience_count: {
      message: `Modify segment definition to potentially increase LinkedIn Audience.
        Changes will start reflecting after 24 hrs.`,
      description: "The campaign has low audience count.",
    },
    budget_too_low: {
      message: `Campaign total budget is less than minimum.`,
      description: minimum_allowed_budget
        ? `Total budget should be atleast $${minimum_allowed_budget}.`
        : "",
    },
  };

  return needAction.map(({ key, display_string, severity, meta }) => {
    const optimizationMessage = get(OPTIMIZATION[key], "message", null);
    const optimization =
      typeof optimizationMessage === "string"
        ? optimizationMessage
        : optimizationMessage(campaign, meta);

    return {
      message: display_string,
      optimization,
      tooltip: get(OPTIMIZATION[key], "description", null),
      type: severity === 1 ? "error" : "warning",
    };
  });
};

export const showUnderNeedAction = (campaign) =>
  getNeedActionCampaignIndicators(campaign).length !== 0;

export const sumKeysInObject = (campaignAllData) =>
  campaignAllData.reduce((acc, camp) => ({
    viewTrough: acc.viewTrough + camp.viewTrough,
    videoRequest: camp.videoRequest
      ? acc.videoRequest + camp.videoRequest
      : undefined,
    videoError: camp.videoError ? acc.videoError + camp.videoError : undefined,
    videoStart: camp.videoStart ? acc.videoStart + camp.videoStart : undefined,
    videoSkip: camp.videoSkip ? acc.videoSkip + camp.videoSkip : undefined,
    video25: camp.video25 ? acc.video25 + camp.video25 : undefined,
    video50: camp.video50 ? acc.video50 + camp.video50 : undefined,
    video75: camp.video75 ? acc.video75 + camp.video75 : undefined,
    video100: camp.video100 ? acc.video100 + camp.video100 : undefined,
  }));

const CAMPAIGN_FIELDS = [
  "id",
  "budget",
  "placement",
  "created",
  "ctr",
  "cpm",
  "cpc",
  ["usersViewThrough", "users_view_through"],
  ["accountsReached", "accounts_reached"],
  ["audienceReached", "audience_reached"],
  ["audienceMatched", "audience_matched"],
  ["mediaImpressions", "media_impressions"],
  ["mediaClicks", "media_clicks"],
  ["totalMediaImpressions", "total_media_impressions"],
  ["totalMediaClicks", "total_media_clicks"],
  ["budgetSpent", "budget_spent"],
  ["clientUpdated", "client_updated"],
  ["stateUpdate", "state_update"],
  ["startDate", "start_date"],
  ["totalAccounts", "total_accounts"],
  ["endDate", "end_date"],
  ["performableActions", "performable_actions"],
  ["campaignName", "campaign_name"],
  ["campaignState", "campaign_state"],
  ["networkState", "network_state"],
  ["flightState", "flight_state"],
  ["jobStatus", "job_status"],
  ["translationState", "translation_state"],
  ["newlyEngaged", "newly_engaged"],
  ["increasedEngagement", "increased_engagement"],
  ["avgIncreaseEngagement", "avg_increase_engagement"],
  ["accountCtr", "account_ctr"],
  ["accountVtr", "account_vtr"],
  ["vtr", "vtr"],
  ["editedBy", "edited_by_user"],
  ["updatedBy", "updated_by"],
  ["createdBy", "created_by"],
  ["deactivationReason", "deactivation_reason"],
  ["fpmCtr", "fpm_ctr"],
  ["campaignSource", "campaign_source"],
  ["latestImpression", "latest_impression"],
  ["earliestImpression", "earliest_impression"],
  ["linkedInUpdated", "li_updated_on"],
  ["linkedCreatedOn", "li_created_on"],
  ["viewabilityPercent", "viewability_percent"],
];

const fieldParser = (input, field) => {
  const localKey = isArray(field) ? field[0] : field;
  const key = isArray(field) && field.length > 1 ? field[1] : localKey;
  let value = input[key];
  if (isArray(field)) {
    const func = field[2];
    if (func !== undefined) {
      value = func(value);
    }
  }
  return { [localKey]: value };
};

export const deserializeCampaign = (data) =>
  CAMPAIGN_FIELDS.reduce(
    (memo, field) => ({
      ...memo,
      ...fieldParser(data, field),
    }),
    {}
  );

export const getCampaignStatsToDispay = (campaign) => {
  const campaignData = deserializeCampaign(campaign);
  const campaignStats = deserializeCampaign(get(campaign, "campaign_data", {}));
  const {
    endDate,
    startDate,
    clientUpdated,
    campaignState,
    budget,
    updatedBy,
    deactivationReason,
    created,
    createdBy,
    linkedInUpdated,
    linkedCreatedOn,
    campaignSource,
  } = campaignData;
  const {
    accountsReached = 0,
    audienceReached = 0,
    audienceMatched = 0,
    mediaImpressions = 0,
    mediaClicks = 0,
    totalMediaImpressions = 0,
    newlyEngaged = 0,
    increasedEngagement = 0,
    totalMediaClicks = 0,
    // Below are floats
    accountVtr = 0,
    vtr = 0,
    accountCtr = 0,
    budgetSpent = 0,
    avgIncreaseEngagement = 0,
    earliestImpression,
    latestImpression,
    ctr,
    cpm = 0,
    cpc = 0,
    usersViewThrough = 0,
    viewabilityPercent = 0,
  } = campaignStats;
  /* To get all display values for stats */
  const matchRate = mediaImpressions / totalMediaImpressions;
  const NA = "-";
  const stopValues = [undefined, null, NA, NaN];
  const displayOverride = {
    draft: NA,
  };
  let verifiedBudgetSpent = totalMediaImpressions ? budgetSpent : 0;
  if (campaignSource === CAMPAIGN_SOURCE.LINKEDIN) {
    verifiedBudgetSpent =
      mediaImpressions || totalMediaImpressions ? budgetSpent : 0;
  }
  const toDisplay = toDisplayfp(stopValues, NA);
  const toDisplayEndDate = toDisplayfp(stopValues, "No end date");
  const overrideDisplay = overrideDisplayfp(displayOverride);
  const numberToDollarfp = partialRight(numberToDollar, { insertCommas: true });
  const [
    accountsReachedDisplay,
    mediaImpressionsDisplay,
    matchRateDisplay,
    audienceReachedDisplay,
    audienceMatchedDisplay,
  ] = toDisplay(overrideDisplay(campaignState), validNumber, localeString)(
    accountsReached,
    mediaImpressions,
    matchRate,
    audienceReached,
    audienceMatched
  );
  const [
    mediaClicksDisplay,
    newlyEngagedDisplay,
    increasedEngagementDisplay,
    totalMediaImpressionsDisplay,
    totalMediaClicksDisplay,
    usersViewThroughDisplay,
  ] = toDisplay(overrideDisplay(campaignState), validNumber, localeString)(
    mediaClicks,
    newlyEngaged,
    increasedEngagement,
    totalMediaImpressions,
    totalMediaClicks,
    usersViewThrough
  );
  const [accountCtrDisplay, accountVtrDisplay, ctrDisplay, vtrDisplay] =
    toDisplay(
      overrideDisplay(campaignState),
      validNumber,
      toFixedfp(2),
      (value) => `${value}%`
    )(accountCtr, accountVtr, ctr, vtr);
  const [budgetTotalDisplay, budgetSpentDisplay] = toDisplay(
    overrideDisplay(campaignState),
    validNumber,
    numberToDollarfp
  )(budget, verifiedBudgetSpent);
  const budgetDisplay = toDisplay(overrideDisplay(campaignState))(
    `${budgetSpentDisplay} of ${budgetTotalDisplay}`
  );
  const [
    startDateDisplay,
    earliestImpressionDisplay,
    latestImpressionDisplay,
    lastUpdatedDisplay,
    linkedInUpdatedDisplay,
    linkedCreatedOnDisplay,
  ] = toDisplay(formatDate(DATE_FORMAT))(
    startDate,
    earliestImpression,
    latestImpression,
    clientUpdated,
    linkedInUpdated,
    linkedCreatedOn
  );

  const formatDateUTC = (date) => moment.utc(date).format(DATE_FORMAT);
  const createdDisplay = toDisplay(formatDateUTC)(created);

  const endDateDisplay = toDisplayEndDate(formatDate(DATE_FORMAT))(endDate);
  const flightDateDisplay = includes([startDateDisplay], NA)
    ? NA
    : `${startDateDisplay} - ${endDateDisplay}`;
  const lastUpdatedByDisplay = toDisplay()((updatedBy || {}).username);
  const createdByDisplay = toDisplay()(createdBy ? createdBy.username : "-");
  const deactivationReasonDisplay = toDisplay()(deactivationReason);
  const avgIncreaseEngagementDisplay = toDisplay(
    overrideDisplay(campaignState),
    validNumber,
    (value) => 1 + value / 100,
    toFixedfp(2),
    (value) => `${value}x`
  )(avgIncreaseEngagement);
  const servingPeriodDisplay = includes(
    [earliestImpressionDisplay, latestImpressionDisplay],
    NA
  )
    ? NA
    : `${earliestImpressionDisplay} - ${latestImpressionDisplay}`;
  return {
    accountsReachedDisplay,
    mediaImpressionsDisplay,
    accountVtrDisplay,
    vtrDisplay,
    accountCtrDisplay,
    audienceReachedDisplay,
    audienceMatchedDisplay,
    ctrDisplay,
    lastUpdatedDisplay,
    flightDateDisplay,
    earliestImpressionDisplay,
    latestImpressionDisplay,
    deactivationReasonDisplay,
    lastUpdatedByDisplay,
    budgetDisplay,
    avgIncreaseEngagementDisplay,
    mediaClicksDisplay,
    newlyEngagedDisplay,
    increasedEngagementDisplay,
    totalMediaImpressionsDisplay,
    totalMediaClicksDisplay,
    servingPeriodDisplay,
    createdDisplay,
    createdByDisplay,
    matchRateDisplay: `${safePercent(matchRateDisplay, 1, 3)}%`,
    linkedInUpdatedDisplay,
    linkedCreatedOnDisplay,
    cpmDisplay: numberToDollar(cpm),
    cpcDisplay: numberToDollar(cpc),
    usersViewThroughDisplay,
    viewabilityPercent: `${formatViewabilityPercent(viewabilityPercent)}%`,
  };
};

export const getDomainStatsData = (results, type) => {
  switch (type) {
    case CAMPAIGN_SOURCE.RETARGETING:
      return flow(
        entriesfp,
        sortByfp(([, { impression_count: ct }]) => -ct),
        mapfp(
          ([
            device,
            {
              impression_count: Impressions,
              click_count: Clicks,
              audience_count: Audience,
              account_count,
            },
          ]) => [
            device === "-" ? "play.google.com" : device,
            {
              "Accounts Reached": account_count,
              Audience,
              Impressions,
              Clicks,
            },
          ]
        )
      )(results);
    default:
      return flow(
        entriesfp,
        sortByfp(([, { impression_count: ct }]) => -ct),
        mapfp(
          ([
            device,
            {
              impression_count: Impressions,
              click_count: Clicks,
              account_count,
            },
          ]) => [
            device === "-" ? "play.google.com" : device,
            { "Accounts Reached": account_count, Impressions, Clicks },
          ]
        )
      )(results);
  }
};

export const getJLJFStatsData = (results, type) => {
  switch (type) {
    case CAMPAIGN_SOURCE.RETARGETING:
      return flow(
        sortByfp(({ account_count: ct }) => -ct),
        mapfp(
          ({
            job_function: jf,
            job_level: jl,
            impression_count: Impressions,
            audience_count: Audience,
            click_count: Clicks,
            account_count,
          }) => [
            `${jl || ""} ${jf || ""}`,
            {
              "Accounts Reached": account_count,
              Audience,
              Impressions,
              Clicks,
            },
          ]
        )
      )(results ? results.results : []);
    case CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING:
      return flow(
        sortByfp(({ impression_count: ct }) => -ct),
        mapfp(
          ({
            job_function: jf,
            job_level: jl,
            job_title: jt,
            impression_count: Impressions,
            click_count: Clicks,
          }) => [
            `${jl || jf || jt || ""}`,
            {
              Impressions,
              Clicks,
              [LINKEDIN_JOB_PROFILE_AVG_CTR_KEY]: Number(
                ((Clicks / (Impressions || 1)) * 100).toFixed(2)
              ),
            },
          ]
        )
      )(results ? results.results : []);
    default:
      return flow(
        sortByfp(({ account_count: ct }) => -ct),
        mapfp(
          ({
            job_function: jf,
            job_level: jl,
            impression_count: Impressions,
            click_count: Clicks,
            account_count,
          }) => [
            `${jl || ""} ${jf || ""}`,
            { "Accounts Reached": account_count, Impressions, Clicks },
          ]
        )
      )(results ? results.results : []);
  }
};

export const getValidExternalCampaignId = (externalId) =>
  (externalId && isArray(externalId) && externalId.join(", ")) || "-";

export const get1stNonZero = (arr) => arr.find((num) => num > 0);

const getStats = (data) => ({
  min: get1stNonZero(data) || 0,
  max: data[data.length - 1] || 0,
  avg: data.reduce((acc, val) => acc + val, 0) / data.length || 0,
  total: data.reduce((acc, val) => acc + val, 0) || 0,
});

const sortAsc = (data) => data.sort((a, b) => a - b);

export const getMetaData = (dataset, leftStat, rightStat) => {
  const sortedLeftDataset = sortAsc(dataset.map(([, stat]) => stat[leftStat]));
  const sortedRightDataset = sortAsc(
    dataset.map(([, stat]) => stat[rightStat])
  );
  const leftStatMeta = getStats(sortedLeftDataset);
  const rightStatMeta = getStats(sortedRightDataset);
  return { leftStatMeta, rightStatMeta };
};

export const getDateRangeByFilter = (
  newDateRange,
  availableDataStartDate,
  availableDataEndDate
) => {
  let startRangeDate;
  const endRangeDate = moment(availableDataEndDate);
  if (newDateRange === "all") {
    startRangeDate = moment(availableDataStartDate);
  } else if (newDateRange === "week") {
    startRangeDate = moment.max([
      moment(availableDataStartDate),
      moment(endRangeDate).subtract(6, "d"),
    ]);
  } else if (newDateRange === "month") {
    startRangeDate = moment.max([
      moment(availableDataStartDate),
      moment(endRangeDate).subtract(29, "d"),
    ]);
  }
  return [startRangeDate, endRangeDate];
};

export const getEndpointWithDateFilter = (
  endpoint,
  selectedDateRange,
  hasQueryParam
) => {
  let updatedEndpoint = endpoint;
  if (!isEmpty(selectedDateRange)) {
    const [startDate, endDate] = getFormattedDate(selectedDateRange);
    updatedEndpoint = hasQueryParam
      ? `${updatedEndpoint}&start_date=${startDate}&end_date=${endDate}`
      : `${updatedEndpoint}?start_date=${startDate}&end_date=${endDate}`;
  }
  return updatedEndpoint;
};

export const updateCampaignAnalyticsWithFilteredData = (
  campaignAnalyticsData,
  result,
  campaignSource
) => {
  const updated = campaignAnalyticsData.map((campAnalytics, i) => ({
    ...campAnalytics,
    accountsReached: `"${coerceLocaleString(result[i].account_count)}"`,
    ctr: `${result[i].ctr.toFixed(3)}%`,
    vtr: `${result[i].vtr.toFixed(3)}%`,
    spend:
      result[i].spend !== 0 ? `"${numberToDollar(result[i].spend)}"` : "$0.00",
    cpm: safeNumber(result[i].cpm, 0)
      ? `"${numberToDollar(result[i].cpm)}"`
      : "$0.00",
    cpc: safeNumber(result[i].cpc, 0)
      ? `"${numberToDollar(result[i].cpc)}"`
      : "$0.00",
    accountCtr: `${result[i].account_ctr.toFixed(3)}%`,
    avtr: `${result[i].account_vtr.toFixed(3)}%`,
    viewabilityPercent: `${formatViewabilityPercent(
      result[i].viewability_percent
    )}%`,
    ...(campAnalytics.campaign_subtype === "video"
      ? {
          videoRequest: `"${coerceLocaleString(
            result[i].video_service_count
          )}"`,
          videoError: `"${coerceLocaleString(result[i].video_error_count)}"`,
          videoStart: `"${coerceLocaleString(result[i].video_start_count)}"`,
          videoSkip: `"${coerceLocaleString(result[i].video_skip_count)}"`,
          video25: `"${coerceLocaleString(
            result[i].video_first_quartile_count
          )}"`,
          video50: `"${coerceLocaleString(result[i].video_half_point_count)}"`,
          video75: `"${coerceLocaleString(
            result[i].video_third_quartile_count
          )}"`,
          video100: `"${coerceLocaleString(result[i].video_completion_count)}"`,
        }
      : {}),
    ...(campaignSource === CAMPAIGN_SOURCE.RETARGETING
      ? {
          audienceReached: `${result[i].audience_count}`,
          audienceMatched: `${result[i].matched_audience_count}`,
        }
      : {
          audienceMatched: "-",
          audienceReached: "-",
        }),
    ...(campaignSource === CAMPAIGN_SOURCE.EXTERNAL
      ? {
          impressions: `"${coerceLocaleString(
            result[i].total_impression_count
          )}"`,
          matchedImpressions: `"${coerceLocaleString(
            result[i].impression_count
          )}"`,
          mediaClicks: `"${coerceLocaleString(result[i].total_click_count)}"`,
          matchedMediaClicks: `"${coerceLocaleString(result[i].click_count)}"`,
        }
      : {
          impressions: `"${coerceLocaleString(result[i].impression_count)}"`,
          mediaClicks: `"${coerceLocaleString(result[i].click_count)}"`,
        }),
  }));
  return updated;
};

export const getCampaignPerformanceIndicatorColor = (state) => {
  switch (state) {
    case CAMPAIGN_PERFORMANCE_STATES.no_delivery.key:
    case CAMPAIGN_PERFORMANCE_STATES.low_delivery.key:
      return "error";

    case CAMPAIGN_PERFORMANCE_STATES.steady_delivery.key:
      return "success";

    case CAMPAIGN_PERFORMANCE_STATES.under_delivery.key:
      return "warning";

    case CAMPAIGN_PERFORMANCE_STATES.overpaced_delivery.key:
    case CAMPAIGN_PERFORMANCE_STATES.launch_in_progress.key:
      return "primary";

    default:
      return "gray";
  }
};

export const shouldShowPerformanceState = ({
  campaign_performance,
  campaign_state,
}) => {
  if (campaign_state === CAMPAIGN_STATES.draft || !campaign_performance)
    return false;

  const { show_performance_indicator } = campaign_performance;

  return show_performance_indicator;
};

export const getOverallPlacementAuditState = (campaign) => {
  const { network_state } = campaign;

  return get(network_state, "placements_state.state", null);
};

export const is6SenseCampaign = (campaign) => {
  const campaignSource = get(campaign, "campaign_source");

  return [
    CAMPAIGN_SOURCE.INTERNAL,
    CAMPAIGN_SOURCE.CONTEXTUAL,
    CAMPAIGN_SOURCE.RETARGETING,
    CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
  ].includes(campaignSource);
};

export const isLinkedInCampaign = (campaign) => {
  const campaignSource = get(campaign, "campaign_source");

  return isEqual(campaignSource, CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING);
};

export const linkedInAdFormat = (campaign) => {
  const LINKEDIN_AD_FORMAT = {
    CAROUSEL: {
      label: "Carousel image ad",
      description:
        "Create ads with 2 or more images that will show up in the news feed.",
    },
    SINGLE_VIDEO: {
      label: "Video ad",
      description:
        "Create ads using a video that will show up in the news feed.",
    },
    TEXT_AD: {
      label: "Spotlight ad",
      description:
        "Create text-based ads that will show up in the right column or top " +
        "of the page on LinkedIn.",
    },
    SPOTLIGHT: {
      label: "Spotlight ad",
      description:
        "Create ads that are personalized using profile data and will promote " +
        "an offering throughout the desktop.",
    },
    SPONSORED_INMAIL: {
      label: "Message ad",
      description:
        "Create ads that are delivered to your target audience's LinkedIn " +
        "Messaging inbox.",
    },
    SPONSORED_MESSAGE: {
      label: "Conversation ad",
      description:
        "Create ads that are delivered to your target audience's LinkedIn " +
        "Messaging inbox.",
    },
    STANDARD_UPDATE: {
      label: "Single Image Ad",
      description:
        "Create ads using a single image, article link or your events on LinkedIn " +
        "that will show up in the news feed.",
    },
  };

  const adFormat = campaign.format;
  const adFormatDetails = LINKEDIN_AD_FORMAT[adFormat];

  if (!adFormatDetails) return { label: adFormat, description: "-" };

  return adFormatDetails;
};

export const isDraftCampaign = (campaign_state, flight_state) =>
  campaign_state === CAMPAIGN_STATES.draft &&
  flight_state === FLIGHT_STATES.NO_FLIGHT;

export const getUsers = (users) =>
  users.map(({ user }) => ({
    id: user.id,
    email: user.username,
  }));

const selectFieldsForCSV = (
  {
    placement_id,
    placement_group_id,
    placement_group_name,
    placement_name,
    account_count,
    impression_count,
    click_count,
    total_impression_count,
    total_click_count,
    ctr,
    account_ctr,
    cpm,
    cpc,
    vtr,
    account_vtr,
    spend,
    video_service_count,
    video_start_count,
    video_skip_count,
    video_error_count,
    video_first_quartile_count,
    video_half_point_count,
    video_third_quartile_count,
    video_completion_count,
    external_id,
    audience_count,
    matched_audience_count,
    newlyEngaged,
    increasedEngagement,
    avgIncreasedEngagement,
    state,
    viewability_percent,
    purchaseOrder,
    product,
  },
  dataType,
  campaign,
  overrides = {}
) => {
  const {
    id: campaign_id,
    campaign_name,
    start_date,
    end_date,
    created,
    budget,
    campaign_subtype,
    campaign_source,
  } = campaign;
  const isExternalCampaign = campaign_source === CAMPAIGN_SOURCE.EXTERNAL;
  const isRetargetingCampaign = campaign_source === CAMPAIGN_SOURCE.RETARGETING;
  let videoStats = {
    videoRequest: "-",
    videoError: "-",
    videoStart: "-",
    videoSkip: "-",
    video25: "-",
    video50: "-",
    video75: "-",
    video100: "-",
  };
  if (campaign_subtype === "video") {
    videoStats = {
      videoRequest: `"${coerceLocaleString(video_service_count)}"`,
      videoError: `"${coerceLocaleString(video_error_count)}"`,
      videoStart: `"${coerceLocaleString(video_start_count)}"`,
      videoSkip: `"${coerceLocaleString(video_skip_count)}"`,
      video25: `"${coerceLocaleString(video_first_quartile_count)}"`,
      video50: `"${coerceLocaleString(video_half_point_count)}"`,
      video75: `"${coerceLocaleString(video_third_quartile_count)}"`,
      video100: `"${coerceLocaleString(video_completion_count)}"`,
    };
  }
  const accountVtr = toDisplayfp([undefined, null, "-"], "-")(
    validNumber,
    toFixedfp(3),
    (value) => `${value}%`
  )(safePercent(account_vtr, 1, 3));

  const accountCtr = toDisplayfp([undefined, null, "-"], "-")(
    validNumber,
    toFixedfp(3),
    (value) => `${value}%`
  )(safePercent(account_ctr, 1, 3));

  return {
    campaignId: campaign_id,
    createdDate: isExternalCampaign ? campaign.campaign_data.created : created,
    campaignName: `"${campaign_name}"`,
    placement_group_id,
    placement_id,
    adGroup: `"${placement_group_name}"`,
    ad: `"${placement_name}"`,
    ...(isExternalCampaign
      ? { externalId: external_id ? external_id.toString().trim() : "-" }
      : {}),
    dataType,
    state,
    startDate: start_date,
    endDate: end_date,
    accountsReached: `"${coerceLocaleString(account_count)}"`,
    ...(!isExternalCampaign
      ? { impressions: `"${coerceLocaleString(impression_count)}"` }
      : {}),
    ...(isExternalCampaign
      ? { impressions: `"${coerceLocaleString(total_impression_count)}"` }
      : {}), // when external use total
    ...(isExternalCampaign
      ? { matchedImpressions: `"${coerceLocaleString(impression_count)}"` }
      : {}),
    ...(!isExternalCampaign
      ? { mediaClicks: `"${coerceLocaleString(click_count)}"` }
      : {}),
    ...(isExternalCampaign
      ? { mediaClicks: `"${coerceLocaleString(total_click_count)}"` }
      : {}),
    ...(isExternalCampaign
      ? { matchedMediaClicks: `"${coerceLocaleString(click_count)}"` }
      : {}),
    ctr: `"${safePercent(ctr, 1, 3)}%"`,
    accountCtr: `"${accountCtr}"`,
    cpm: safeNumber(cpm, 0) ? `"${numberToDollar(cpm)}"` : "$0.00",
    cpc: safeNumber(cpc, 0) ? `"${numberToDollar(cpc)}"` : "$0.00",
    vtr: `"${safePercent(vtr, 1, 3)}%"`,
    avtr: `"${accountVtr}"`,
    budget: budget !== 0 ? `"${numberToDollar(budget)}"` : "$0.00",
    spend: spend !== 0 ? `"${numberToDollar(spend)}"` : "$0.00",
    newlyEngaged,
    increasedEngagement,
    avgIncreasedEngagement,
    viewabilityPercent: `${formatViewabilityPercent(viewability_percent)}%`,
    ...(isRetargetingCampaign
      ? {
          audienceReached: `${audience_count}`,
          audienceMatched: `${matched_audience_count}`,
        }
      : {
          audienceMatched: "-",
          audienceReached: "-",
        }),
    ...videoStats,
    purchaseOrder: purchaseOrder || "-",
    product: product || "-",
    ...overrides,
  };
};

export const downloadCampaign = (
  campaign,
  products = [],
  hasPredictiveProducts
) => {
  function extractStats(selectedCampaigns) {
    return selectedCampaigns.map((campaignObj) => {
      const {
        campaign_data: {
          accounts_reached = 0,
          media_impressions = 0,
          media_clicks = 0,
          account_ctr = 0,
          budget_spent = 0,
          account_vtr = 0,
          newly_engaged = 0,
          increased_engagement = 0,
          total_media_clicks = 0,
          total_media_impressions = 0,
          audience_reached = 0,
          audience_matched = 0,
          vtr,
          viewability_percent,
        },
        campaign_subtype,
        purchase_order,
        campaign_meta,
      } = campaignObj;

      const { avgIncreaseEngagementDisplay } =
        getCampaignStatsToDispay(campaignObj);

      const videoStatsDefaultValue = campaign_subtype === "video" ? "0" : "-";
      const campaignType = getCampaignType(campaignObj);

      const totalCostPerMimps = budget_spent / (media_impressions / 1000);
      const totalCostPerClick = budget_spent / media_clicks;
      const totalCtr = media_clicks / media_impressions;

      const purchaseOrder = get(purchase_order, "name") || "-";
      const productName = getProductFromCampaignDetails(
        campaign_meta,
        products,
        hasPredictiveProducts
      );
      const selectedProduct = find(
        products,
        ({ value }) => value === productName
      );
      const product = get(selectedProduct, "label") || "-";

      return selectFieldsForCSV(
        {
          placement_id: 0,
          placement_group_id: 0,
          placement_group_name: "-",
          placement_name: "-",
          external_id: "-",
          account_count: accounts_reached,
          impression_count: media_impressions,
          total_impression_count: total_media_impressions,
          click_count: media_clicks,
          total_click_count: total_media_clicks,
          ctr: totalCtr,
          account_ctr: account_ctr / 100,
          cpm: totalCostPerMimps,
          cpc: totalCostPerClick,
          vtr: vtr / 100,
          account_vtr: account_vtr / 100,
          spend: budget_spent,
          newlyEngaged: newly_engaged,
          increasedEngagement: increased_engagement,
          avgIncreasedEngagement: avgIncreaseEngagementDisplay,
          audience_count: audience_reached,
          matched_audience_count: audience_matched,
          video_service_count: videoStatsDefaultValue,
          video_start_count: videoStatsDefaultValue,
          video_skip_count: videoStatsDefaultValue,
          video_error_count: videoStatsDefaultValue,
          video_first_quartile_count: videoStatsDefaultValue,
          video_half_point_count: videoStatsDefaultValue,
          video_third_quartile_count: videoStatsDefaultValue,
          video_completion_count: videoStatsDefaultValue,
          state: capitalize(mappedCategoryTabNames[campaignType]),
          viewability_percent,
          purchaseOrder,
          product,
        },
        "Campaign",
        campaignObj,
        { campaign_subtype }
      );
    });
  }

  return extractStats([campaign]);
};

export const downloadGroupData = (
  placementGroupAnalytics,
  campaignCategory,
  campaign
) =>
  placementGroupAnalytics
    .filter(() => {
      if (campaign === undefined) {
        return false;
      }
      if (
        !campaign &&
        campaignCategory &&
        campaignCategory !== CAMPAIGN_CATEGORIES.all
      ) {
        const campaignType = getCampaignType(campaign);
        return campaignType === campaignCategory;
      }
      return true;
    })
    .map((groupAnalytics) => {
      const {
        placement_group_id,
        placement_group_name,
        account_count = 0,
        impression_count = 0,
        click_count = 0,
        total_impression_count = 0,
        total_click_count = 0,
        ctr = 0,
        account_ctr = 0,
        vtr = 0,
        account_vtr = 0,
        spend = 0,
        audience_count = 0,
        matched_audience_count = 0,
        is_deleted,
        video_service_count = 0,
        video_start_count = 0,
        video_skip_count = 0,
        video_error_count = 0,
        video_first_quartile_count = 0,
        video_third_quartile_count = 0,
        video_half_point_count = 0,
        video_completion_count = 0,
        viewability_percent = 0,
      } = groupAnalytics;

      const state = is_deleted
        ? PLACEMENT_STATE.Deleted
        : PLACEMENT_STATE.Active;
      const totalCostPerMimps = spend / (impression_count / 1000);
      const totalCostPerClick = spend / click_count;
      return selectFieldsForCSV(
        {
          placement_id: 0,
          placement_group_id,
          placement_group_name,
          placement_name: "-",
          external_id: "-",
          account_count,
          impression_count,
          total_impression_count,
          click_count,
          total_click_count,
          ctr,
          account_ctr,
          cpm: totalCostPerMimps,
          cpc: totalCostPerClick,
          vtr,
          account_vtr,
          spend,
          newlyEngaged: "-",
          increasedEngagement: "-",
          avgIncreasedEngagement: "-",
          audience_count,
          matched_audience_count,
          video_service_count,
          video_start_count,
          video_skip_count,
          video_error_count,
          video_first_quartile_count,
          video_half_point_count,
          video_third_quartile_count,
          video_completion_count,
          state,
          viewability_percent,
        },
        "AdGroup",
        campaign
      );
    });

export const downloadAdData = (
  placementsAnalytics,
  campaignCategory,
  campaign
) =>
  placementsAnalytics
    .filter(() => {
      if (campaign === undefined) {
        return false;
      }
      if (
        !campaign &&
        campaignCategory &&
        campaignCategory !== CAMPAIGN_CATEGORIES.all
      ) {
        const campaignType = getCampaignType(campaign);
        return campaignType === campaignCategory;
      }
      return true;
    })
    .map(
      ({
        placement_id,
        placement_group_id,
        placement_group_name,
        placement_name,
        is_deleted,
        account_count = 0,
        impression_count = 0,
        click_count = 0,
        total_impression_count = 0,
        total_click_count = 0,
        ctr = 0,
        account_ctr = 0,
        cpm = 0,
        cpc = 0,
        vtr = 0,
        account_vtr = 0,
        spend = 0,
        video_service_count = 0,
        video_start_count = 0,
        video_skip_count = 0,
        video_error_count = 0,
        video_first_quartile_count = 0,
        video_third_quartile_count = 0,
        video_half_point_count = 0,
        video_completion_count = 0,
        external_id = "-",
        audience_count = 0,
        matched_audience_count = 0,
        viewability_percent = 0,
      }) => {
        const state = is_deleted
          ? PLACEMENT_STATE.Deleted
          : PLACEMENT_STATE.Active;
        return selectFieldsForCSV(
          {
            placement_id,
            placement_group_id,
            placement_group_name,
            placement_name,
            external_id,
            account_count,
            impression_count,
            total_impression_count,
            click_count,
            total_click_count,
            ctr,
            account_ctr,
            cpm,
            cpc,
            vtr,
            account_vtr,
            spend,
            newlyEngaged: "-",
            increasedEngagement: "-",
            avgIncreasedEngagement: "-",
            audience_count,
            matched_audience_count,
            video_service_count,
            video_start_count,
            video_skip_count,
            video_error_count,
            video_first_quartile_count,
            video_half_point_count,
            video_third_quartile_count,
            video_completion_count,
            state,
            viewability_percent,
          },
          "Ad",
          campaign
        );
      }
    );

export const mapFilterDates = (dateFilter, customDate = moment()) => {
  let startDate = null;
  let endDate = null;
  if (dateFilter && dateFilter.type !== DATE_FILTER_OPTIONS_VALUES.lifetime) {
    if (dateFilter.type === DATE_FILTER_OPTIONS_VALUES.dateRange) {
      const { from, to } = dateFilter;
      startDate = from;
      endDate = to;
    } else {
      startDate = moment
        .utc(customDate)
        .subtract(dateFilter.type, "day")
        .format("YYYY-MM-DD");
      endDate = moment.utc(customDate).format("YYYY-MM-DD");
    }
  }
  return { startDate, endDate };
};

export const campaignActionsCheck = (
  campaign,
  hasArchivedFolder = false,
  userIsStaff = false
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasEditPermission = usePermissionCheck(
    [Permission.CAMPAIGN_EDIT],
    DISALLOW_EXTERNAL_SIXSENSE_USER
  );
  const campaignStatus = get(campaign, "campaign_status", null);
  const campaignSource = get(campaign, "campaign_source");
  const isDraft = campaignStatus === CAMPAIGN_STATUS_X.draft;
  const isArchivedCampaign =
    get(campaign, "folder.classification_type") ===
    CLASSIFICATION_TYPES.CAMPAIGNS_ARCHIVED;

  return {
    canDeactivate:
      (hasEditPermission || userIsStaff) &&
      is6SenseCampaign(campaign) &&
      [CAMPAIGN_STATUS_X.active, CAMPAIGN_STATUS_X.scheduled].includes(
        campaignStatus
      ),
    canActivate:
      (hasEditPermission || userIsStaff) &&
      is6SenseCampaign(campaign) &&
      !isArchivedCampaign &&
      campaignStatus === CAMPAIGN_STATUS_X.deactivated,
    canDelete:
      (hasEditPermission || userIsStaff) &&
      is6SenseCampaign(campaign) &&
      isDraft,
    canEnd:
      (hasEditPermission || userIsStaff) &&
      is6SenseCampaign(campaign) &&
      !isArchivedCampaign &&
      campaignStatus === CAMPAIGN_STATUS_X.deactivated,
    canClone: (hasEditPermission || userIsStaff) && is6SenseCampaign(campaign),
    canArchive:
      (hasEditPermission || userIsStaff) &&
      hasArchivedFolder &&
      !isArchivedCampaign &&
      campaignSource !== CAMPAIGN_SOURCE.EXTERNAL,
  };
};

// TODO: Remove this occurrence and use the one from src/utils
export const generateQueryParams = (paramsObject) => {
  const params = Object.entries(paramsObject).reduce((acc, [key, value]) => {
    if (!value) return acc;

    const param = new URLSearchParams(acc);
    param.set(key, isArray(value) ? value.join() : value);
    return param.toString();
  }, "");

  return params ? `?${params}` : "";
};

export const getDisableLinkedInCampaignEditReason = (campaign, page = null) => {
  const campaignSource = get(campaign, "campaign_source");
  const campaignState = get(campaign, "campaign_state");
  const accessStatus = get(page, "sponsored_content_poster_status");

  if (
    campaignSource === CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING &&
    campaignState === "submitted"
  ) {
    if (get(page, "is_disabled")) {
      return LINKEDIN_ADVERTISING_TOOLTIPS[LINKEDIN_PAGE_STATUS.DISABLED];
    } else if (accessStatus === SPONSORED_CONTENT_POSTER_STATUS.REJECTED) {
      return LINKEDIN_ADVERTISING_TOOLTIPS[
        SPONSORED_CONTENT_POSTER_STATUS.REJECTED
      ];
    } else if (accessStatus === SPONSORED_CONTENT_POSTER_STATUS.REVOKED) {
      return LINKEDIN_ADVERTISING_TOOLTIPS[
        SPONSORED_CONTENT_POSTER_STATUS.REVOKED
      ];
    }
  }
  return "";
};

// Viewability metrics is available only for campaign started post 1st Jan 2023
// Hence the check
// Change in this date will also need a change in
// Download ads csv report API (Initiated from campaign details page)
// i.e. In python method named (insert_data_into_csv)
export const isViewabilityMetricsAvailable = (
  campaignEndDate,
  campaignStatus
) =>
  (!campaignEndDate || moment(campaignEndDate).isSameOrAfter("2023-01-01")) &&
  !isDraftOrScheduledCampaign(campaignStatus);

export const isViewabilityAvailable = (
  campaignSource,
  campaignEndDate,
  campaignStatus
) =>
  [
    CAMPAIGN_SOURCE.INTERNAL,
    CAMPAIGN_SOURCE.RETARGETING,
    CAMPAIGN_SOURCE.CONTEXTUAL,
  ].includes(campaignSource) &&
  isViewabilityMetricsAvailable(campaignEndDate, campaignStatus);

export const isDraftOrScheduledCampaign = (campaignStatus) =>
  [CAMPAIGN_STATUS_X.draft, CAMPAIGN_STATUS_X.scheduled].includes(
    campaignStatus
  );

export const formatViewabilityPercent = (viewabilityPercent = 0) =>
  parseFloat(viewabilityPercent).toFixed(1);

export const getStringFromList = (list, offset) => {
  const listsString = list.slice(0, offset).join(", ");
  return list.length > offset
    ? `${listsString} +${list.length - offset}`
    : listsString;
};

export const getMomentDateWithZeroHours = (date) => {
  if (date && moment(date).isValid()) {
    return date.format
      ? moment.utc(date.format(DATE_FORMAT_YYYY_MM_DD))
      : moment.utc(moment.utc(date).format(DATE_FORMAT_YYYY_MM_DD));
  }
  return date;
};

export const computeCampaignFlightDays = (fromDate, toDate, weekdaysOnly) => {
  const startDate = getMomentDateWithZeroHours(moment(fromDate));
  const endDate = getMomentDateWithZeroHours(moment(toDate));

  if (!startDate || !endDate) return null;
  if (moment.utc(startDate).isAfter(moment.utc(endDate), "days")) return 0;

  const daysBetweenFlightDates = endDate.diff(startDate, "days") + 1;
  if (weekdaysOnly) {
    let weekdays = 0;
    for (let i = 0; i < daysBetweenFlightDates; i++) {
      const dayOfWeek = moment.utc(startDate).add(i, "day").format("dddd");
      if (["Saturday", "Sunday"].includes(dayOfWeek) === false) {
        weekdays += 1;
      }
    }

    return weekdays;
  }

  return daysBetweenFlightDates;
};

const getOption = (id, options) => options.find((option) => option.id === id);

export const profileFiltersToString = (
  value,
  jobLevelOptions,
  jobFunctionOptions
) => {
  const { jobLevel = [], jobFunction = [] } = value;
  if (!jobLevel.length && !jobFunction.length) {
    return "- -";
  }

  const jobLevelLabels = jobLevel
    .map((v) => {
      const jobLevelOption = getOption(v, jobLevelOptions);
      const displayName = jobLevelOption
        ? get(jobLevelOption, "display_name")
        : "";
      const levelRemoved = displayName.replace("Level", "").trim();
      const pluralExceptions = ["staff"].includes(levelRemoved.toLowerCase());
      return pluralExceptions ? levelRemoved : `${levelRemoved}s`;
    })
    .join(" or ");

  const jobFunctionsLabels = jobFunction
    .map((v) => {
      const jobFunctionOption = getOption(v, jobFunctionOptions);
      return jobFunctionOption ? get(jobFunctionOption, "display_name") : "";
    })
    .join(" or ");
  const bothExists = jobLevel.length && jobFunction.length ? "in" : "";

  return `${jobLevelLabels} ${bothExists} ${jobFunctionsLabels}`;
};

export const isAccountPacingAvailable = (campaignSource) =>
  [CAMPAIGN_SOURCE.INTERNAL, CAMPAIGN_SOURCE.CONTEXTUAL].includes(
    campaignSource
  );

export const isSystemSegment = (segment) =>
  !!(get(segment, "segment_type", "") === SYSTEM_SEGMENT);
