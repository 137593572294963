import { fetchSaga } from 'store/sagas';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { orgSelector, crmTypeSelector } from 'modules/user/selectors';
import { actions, actionTypes } from '.';
import { actions as globalActions } from 'modules/global';
import { INTEGRATIONS_TO_VALIDATE } from './constants';
import rollbar from 'lib/rollbar';

const { showNotification } = globalActions;
const { VALIDATE_INTEGRATION_CREDS } = actionTypes;

function* logRollbarError(errorMessage) {
  const orgId = yield select(orgSelector);
  rollbar.error(`${errorMessage} for orgId: ${orgId}`);
}

const { validateIntegrationCredsSuccess, validateIntegrationCredsFailure } = actions;

function* watchValidateIntegrationCreds(request) {
  yield takeEvery(VALIDATE_INTEGRATION_CREDS, validateIntegrationCredsSaga, request);
}

function* validateIntegrationCredsSaga(request, action) {
  switch (action.integrationType) {
    case INTEGRATIONS_TO_VALIDATE.SALESFORCE:
      yield validateCrmCreds(request, action.integrationType);
      break;
    case INTEGRATIONS_TO_VALIDATE.DYNAMICS:
      yield validateCrmCreds(request, action.integrationType);
      break;
    case INTEGRATIONS_TO_VALIDATE.HUBSPOTCRM:
      yield validateCrmCreds(request, action.integrationType);
      break;
    case INTEGRATIONS_TO_VALIDATE.G2:
      yield validateG2Creds(request, action.integrationType);
      break;

    default:
      yield put(validateIntegrationCredsFailure(
        action.integrationType,
        'Validation for this integration is not supported yet.'
      ));
      break;
  }
}

function* validateCrmCreds(request, validatingCrmTypeStateKey) {
  const orgId = yield select(orgSelector);
  const crmType = yield select(crmTypeSelector);

  try {
    /**
     * This endpoint is introduced to check if CRM credentials are whether present or not
     * and if present are valid or not.
     */
    const crmCredsEndpoint =
        `nsyncintegration/${crmType.toLowerCase()}/credentials/validate?orgid=${orgId}`;
    const _res = yield call(request, crmCredsEndpoint);
    const areCrmCredsValid = _res.success;

    // using the passed integration type to set the states correctly
    yield put(validateIntegrationCredsSuccess(validatingCrmTypeStateKey, areCrmCredsValid));
  } catch (error) {
    yield logRollbarError(`Validation endpoint for ${crmType} CRM failed with error: ${error}`);

    yield put(showNotification('error', 'Unable to validate CRM credentials '+
    'right now, please try again after some time.'));
    yield put(validateIntegrationCredsFailure(validatingCrmTypeStateKey, error.errorMessage));
  }
}

function* validateG2Creds(request, g2StateKey) {
  try {
    const orgId = yield select(orgSelector);
    const g2CredsValidationEndpoint =
      `nsyncintegration/partner/g2/credentials/validate?orgid=${orgId}`;
    const _res = yield call(request, g2CredsValidationEndpoint);
    yield put(validateIntegrationCredsSuccess(g2StateKey, _res.success));
  } catch (error) {
    yield logRollbarError(`Validation endpoint for G2 failed with error: ${error}`);

    yield put(showNotification('error', 'Unable to validate G2 credentials '+
    'right now, please try again after some time.'));
    yield put(validateIntegrationCredsFailure(g2StateKey, error.errorMessage));
  }
}

export default [
  fetchSaga(watchValidateIntegrationCreds),
];
