import { defineRequestState, defineSharedState } from '@sixsense/core';
import { CreditType, ValidationType } from './types';
import { App } from '@sixsense/rbac';
import { getCreditDateFormat } from './utils';
import { creditTypeProperties } from './constants';

const PATH_PREFIX = 'creditmanagement';

export const usersListRequestState = defineSharedState({
  path: `${PATH_PREFIX}.userList`,
  defaultValue: [],
});

export const siOrgCreditsRequestState = defineSharedState<CreditType>({
  path: `${PATH_PREFIX}.siOrgCredits`,
  defaultValue: creditTypeProperties,
});
export const wfOrgCreditsRequestState = defineSharedState<CreditType>({
  path: `${PATH_PREFIX}.wfOrgCredits`,
  defaultValue: creditTypeProperties,
});
export const totalOrgCreditsRequestState = defineSharedState({
  path: `${PATH_PREFIX}.totalOrgCredits`,
  defaultValue: creditTypeProperties,
});

export const userRolesState = defineRequestState({
  path: `${PATH_PREFIX}.userRoles`,
  getConfig: ({ orgId }) => ({
    url: `popcorn/v1/organizations/${orgId}/roles/?
include_users_count=false&app_id_in=${App.SALES}`,
  }),
  transformData: (data: any) => {
    if (data?.length) {
      return data;
    }
    return [];
  },
});

export const selectedRoleState = defineSharedState({
  path: `${PATH_PREFIX}.selectedRoleState`,
  defaultValue: -1,
});

export const selectedStatusState = defineSharedState<String>({
  path: `${PATH_PREFIX}.selectedStatusState`,
  defaultValue: 'all',
});
export const selectedBalanceState = defineSharedState({
  path: `${PATH_PREFIX}.selectedBalanceState`,
  defaultValue: null,
});
export const selectedBalanceActionState = defineSharedState<String>({
  path: `${PATH_PREFIX}.selectedBalanceActionState`,
  defaultValue: 'all',
});
export const showFilterPopoverState = defineSharedState<Boolean>({
  path: `${PATH_PREFIX}.showFilterPopoverState`,
  defaultValue: false,
});
export const dataCreditsUsageState = defineSharedState<Boolean>({
  path: `${PATH_PREFIX}.dataCreditsUsageState`,
  defaultValue: true,
});

export const searchUsersState = defineSharedState<String>({
  path: `${PATH_PREFIX}.searchUsersState`,
  defaultValue: '',
});

export const userListState = defineSharedState({
  path: `${PATH_PREFIX}.userListState`,
  defaultValue: [],
});

export const creditValidationState = defineSharedState<ValidationType>({
  path: `${PATH_PREFIX}.credtValidation`,
  defaultValue: {
    disabled: true,
    error: '',
  },
});

export const orgCreditPoolState = defineRequestState({
  path: `${PATH_PREFIX}.orgCreditPool`,
  getConfig: ({ orgId }) => ({
    url: `credit/${orgId}/pools/`,
  }),
  transformData: (data: any) => {
    if (data?.length) {
      return (
        data.map((pool: any) => {
          const expired = pool.is_expired ? 'Expired' : '';
          const label = `${getCreditDateFormat(
            pool.start_date
          )} - ${getCreditDateFormat(pool.expiry_date)}`;
          const title = expired ? `${label} ${expired}` : label;
          return {
            ...pool,
            ...{
              key: pool.id,
              value: pool.id,
              title: title?.toString(),
              label,
              subLabel: expired,
            },
          };
        }) || []
      );
    }
    return [];
  },
});

export const creditPoolState = defineSharedState({
  path: `${PATH_PREFIX}.orgCreditPool`,
  defaultValue: {},
});

export const orgPoolSelectedState = defineSharedState<CreditType>({
  path: `${PATH_PREFIX}.orgPoolSelected`,
  defaultValue: creditTypeProperties,
});
