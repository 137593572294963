import React, { useEffect } from 'react';
import { Flex, Select, Text } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import { useSharedState } from '@sixsense/core';
import { creditPoolState, orgPoolSelectedState } from '../state';
import { SixsenseColor } from '@sixsense/core/style';
import { Check } from '@sixsense/core/icons';
import { MAINTENANCE_INFO } from 'routes/Settings/constants';

const styles = {
  container: css({
    padding: '10px 40px 10px 30px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 2px 0 rgba(74,87,94,0.2)',
  }),
  subBody: css({
    marginLeft: '12px',
  }),
  poolSelect: css({
    height: '36px',
    width: '260px',
    '& span': {
      fontSize: '14px !important',
      color: `${SixsenseColor.Gray.DARK_1}`,
    },
  }),
  poolSelectBox: css({
    minWidth: '226px',
  }),
  creditPoolLabel: css({
    padding: '8px 12px 8px 12px',
  }),
  optionText: css({
    color: '#4B555E',
    width: '100%',
  }),
  customOption: css({
    padding: '8px 12px 8px 12px',
    fontSize: '14px',
    height: '37px',
    ':hover': {
      backgroundColor: `${SixsenseColor.Gray.LIGHT_5}`,
      '.icon': {
        color: `${SixsenseColor.Gray.DARK_2}`,
      },
    },
  }),
  customOptionBg: css({
    backgroundColor: `${SixsenseColor.Blue.LIGHT_6}`,
    ':hover': {
      backgroundColor: `${SixsenseColor.Blue.LIGHT_5}`,
      '.icon': {
        color: `${SixsenseColor.Gray.DARK_2}`,
      },
    },
  }),
};

const CreditsHeader = () => {
  const [{ data = [], status }] = useSharedState(creditPoolState);
  const [orgPoolSelected, setOrgPool] = useSharedState(orgPoolSelectedState);

  useEffect(() => {
    if (status === 'LOADED' && data.length && !orgPoolSelected.id) {
      const activePool = data.find((el) => el.is_active);
      setOrgPool(activePool || data[0]);
    }
  }, [data]);

  const customGroupHeading = (heading) => (
    <div className={styles.creditPoolLabel} {...heading?.innerProps}>
      {heading?.children}
    </div>
  );

  const customSelectMenu = (heading: any) => (
    <Flex
      className={`${styles.customOption} ${
          heading?.isSelected ? styles.customOptionBg : ''
        }`}
      {...heading?.innerProps}
      gap={8}
      justifyContent="space-between"
    >
      {heading?.children}
      {!heading?.isSelected && heading?.data?.subLabel ?
        <span>{heading?.data?.subLabel}</span> : null }
      {heading?.isSelected ? (
        <Check size={20} color={SixsenseColor.Blue.DARK_2} />
        ) : null}
    </Flex>
    );

  return (
    <Flex
      className={styles.container}
      justifyContent="space-between"
      alignItems="center"
    >
      <span>
        <Text weight={600} size={18} color={SixsenseColor.Gray.DARK_5}>
          Credits
        </Text>
        <br />
        <Text weight={400} size={14}>
          Track credits and allocate credits among users
        </Text>
      </span>
      <span className={styles.poolSelectBox}>
        {data?.length ? (
          <Select.Labeled
            disabled={MAINTENANCE_INFO.with_read_only}
            placeholder={
              status === 'LOADED' && !data.length
                ? 'No credit pool present'
                : 'Select credit pool'
            }
            loading={status !== 'LOADED'}
            components={{
              GroupHeading: customGroupHeading,
              Option: customSelectMenu,
            }}
            searchable={false}
            clearable={false}
            className={styles.poolSelect}
            value={data.length ? orgPoolSelected : null}
            onChange={(pool) => {
              setOrgPool(pool);
            }}
            options={[
              {
                label: (
                  <Text
                    weight={600}
                    size={12}
                    color={SixsenseColor.Gray.DARK_1}
                  >
                    CREDIT POOL
                  </Text>
                ),
                options: data,
              },
            ]}
          />
        ) : null}
      </span>
    </Flex>
  );
};

export default CreditsHeader;
