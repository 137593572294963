import React, { useEffect } from 'react';
import { destroyAllParamsNavigate } from 'utils/navigate';
import { Flex, Text, Button } from '@sixsense/core/components';
import { Modal } from 'v2-components';
import styles from './Header.module.scss';
import { actions as globalActions } from 'modules/global';
import { useDispatch, useSelector } from '@sixsense/core/versioned/react-redux';
import { useRequest, useSharedState } from '@sixsense/core';
import { useOrg } from 'hooks';
import { savedConfigsState, SiSaveIntegrationsConfig, unSavedChangesState } from './state';
import { SalesPermission, usePermissionCheck } from '@sixsense/rbac';
import { isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';


const title = 'SI Integrations';
const subTitle =
  `Control synchronization, manage update dependencies, and
 configure export settings in integrations between Sales Intelligence and CRM platforms and SEPs`;

export const Header: React.FC = () => {
  const { id: orgId } = useOrg();
  const dispatch = useDispatch();
  const request = useRequest();
  const [config, setSavedConfig] = useSharedState(savedConfigsState);
  const [unSavedFlag, setUnSavedFlag] = useSharedState(unSavedChangesState);

  const refreshState = () => {
    setSavedConfig(undefined);
    setUnSavedFlag(false);
  };
  useEffect(() => () => refreshState(), []);
  const hasEditPermission = usePermissionCheck([SalesPermission.SETTINGS_INTEGRATION_EDIT]);
  const isViewOnlyEnabled = useSelector(isViewOnlyEnabledFor6senseAccessSelector);
  const handleSave = async () => {
    try {
      const body: SiSaveIntegrationsConfig[] = [];
      Object.values(config).forEach(
        (val) => {
          const { export_options, ...newState } = val;
          if (newState.integrated_app) {
            delete newState.mapping_profile;
            body.push(newState);
          }
        }
      );
      await request(
        `settings/${orgId}/integrations-config/bulk_update/`,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
        }
      );
      // destroyAllParamsNavigate('/settings/manage');
      dispatch(
        globalActions.showNotification(
          'success',
          'Successfully Saved'
        )
      );
      setUnSavedFlag(false);
    } catch (e) {
      dispatch(
        globalActions.showNotification('error', 'Error updating configuration.')
      );
    }
  };
  return (
    <Flex className={styles.container} justifyContent="space-between" gap={16}>
      <Flex direction="column">
        <Text.SectionHeading size={20}> {title}</Text.SectionHeading>
        <Text size={16} color="#696F7B"> {subTitle}</Text>
      </Flex>
      <Flex gap={8} alignItems="center">
        <Button
          className="borderRadius2"
          size="lg"
          hierarchy="secondary" onClick={() => {
            if (unSavedFlag) {
              Modal.prompt({
                type: Modal.PROMPT_TYPES.CONFIRM,
                onOk: () => {
                  destroyAllParamsNavigate('/settings/manage');
                  refreshState();
                },
                onCancel: () => ({}),
                title: 'Are you sure you want to navigate away?',
                content: `You have unsaved changes on this page.
              Are you sure you want to navigate away without saving?`,
              });
            } else {
              destroyAllParamsNavigate('/settings/manage');
              refreshState();
            }
          }}
        >Back</Button>
        <Button
          size="lg"
          className="borderRadius2"
          hierarchy="primary"
          onClick={handleSave}
          disabled={!unSavedFlag || !hasEditPermission || isViewOnlyEnabled}
        >  Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default Header;
