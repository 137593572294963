import React, { useEffect, useState } from 'react';
import { css } from 'styles/emotion';
import {
  Link,
  Card,
  Flex,
  ProgressSteps,
  StepperProps,
  Grid,
  Button,
  Badge,
  useToast,
} from '@sixsense/core/components';
import { CompanyProductDetails } from './CompanyProductDetails';
import { MarketingSalesMaterial } from './MarketingSalesMaterial';
import { UnsavedChangesModal } from './UnsavedChangesModal';
import { SummaryComponent } from './SummaryComponent';
import { Text } from 'v2-components';
import {
  Plus,
  ChevronUp,
  ChevronDown,
  Edit,
  CheckCircle,
  XClose,
} from '@sixsense/core/icons';
import EditableContent from './EditableContent';
import { useRequest, useSharedState } from '@sixsense/core';
import { productSummaryAttributesSharedState } from '../state';
import { Step } from '@sixsense/core/components/Stepper/Stepper';
import { companyProductAttributes, SUMMARY_STEP_SUBLABEL, TOAST_KEY_MAP } from '../constant';
import { hasRequiredMarketingValues, hasRequiredValues } from '../utils';

const STEPS: Step[] = [
  {
    completed: false,
    disabled: false,
    label: 'Company/Product Details',
    subLabel: 'Describe your company, products, partners, and competitors.',
  },
  {
    completed: false,
    disabled: true,
    label: 'Marketing/Sales Material',
    subLabel: 'Who do you sell to?',
  },
  {
    label: 'Summary',
    subLabel: SUMMARY_STEP_SUBLABEL,
    disabled: true,
  },
];

const STEP = {
  COMPANY_AND_PRODUCT: 0,
  MARKETING_SALES_MATERIAL: 1,
  SUMMARY: 2,
};

const styles = {
  productCard: css({
    margin: '20px 0px 0px 0px !important',
    padding: '16px',
    height: '68px',
  }),
  collapsedProductCard: css({
    height: 'auto',
    margin: '0px !important',
    borderTop: 'none !important',
    borderRadius: '0px 0px 6px 6px !important',
    transition: 'height 200ms ease, opacity 200ms ease',
  }),
  productTitle: css({
    fontSize: '16px',
    lineHeight: '20px',
  }),
  addProductText: css({
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    // marginLeft: 'auto',
  }),
  plusIcon: css({
    marginRight: '8px',
  }),
  progressSteps: css({
    height: '260px',
    '> div': {
      height: '100%',
    },
  }),
  customBtn: css({
    borderRadius: '6px !important',
    height: '36px !important',
  }),
  nextBtn: css({
    borderRadius: '6px !important',
    height: '36px !important',
    ':disabled': {
      cursor: 'not-allowed',
      borderColor: '#99DEF7 !important',
      background: '#99DEF7 !important',
      color: '#FFFFFF !important',
    },
  }),
  contentStyle: css({
    fontSize: '14px',
    lineHeight: '21px',
    maxHeight: '300px',
    overflow: 'auto',
    marginTop: '8px',
    textWrap: 'wrap',
  }),
  editBtn: css({
    height: '36px !important',
    minHeight: '36px !important',
    width: '36px',
    borderRadius: '6px !important',
    padding: '9px !important',
    marginRight: '16px',
    marginLeft: 'auto',
    '> div': {
      height: '12px',
    },
  }),
  badgeStyle: css({
    marginLeft: '8px',
    '> span': {
      fontWeight: 600,
    },
  }),
};

type VisitedStep = {
  [step: number]: boolean;
};

type ProductCardProps = {
  product: any;
  productIndex: any;
  orgId: any;
  isCollapsed: any;
  toggleCollapseValue: any;
  activeSteps: any;
  setActiveSteps: any;
  getProductList: any;
};

export const ProductCardComponent = ({
  product,
  productIndex,
  orgId,
  isCollapsed,
  toggleCollapseValue,
  activeSteps,
  setActiveSteps,
  getProductList,
}: ProductCardProps) => {
  const [productAttributes, setProductAttributes] = useSharedState(
    productSummaryAttributesSharedState
  );
  const [steps, setSteps] = useState<StepperProps.steps>(STEPS);
  const [visited, setVisited] = useState<VisitedStep>({
    [STEP.COMPANY_AND_PRODUCT]: true,
  });
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [editBtnClicked, setEditBtnClicked] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState({});
  const [isUseThisSummaryEnabled, setIsUseThisSummaryEnabled] = useState(true);
  const [isContentEditableField, setIsContentEditableField] = useState(false);
  const [editableContent, setEditableContent] = useState('');
  const [initialEditableContent, setInitialEditableContent] = useState('');
  const [isModalClickedFromParent, setIsModalClickedFromParent] =
    useState(false);
  const [generatedSummary, setGeneratedSummary] = useState('');
  const request = useRequest();
  const toast = useToast();
  const productSummaryStatus = {};
  const isSummaryAccepted = {};
  const [isProductAttibutesEdited, setIsProductAttributesEdited] = useState(false);

  const nullCheckForFieldsInCurrentStep = () => {
    if (activeSteps[productIndex] === STEP.COMPANY_AND_PRODUCT) {
      return hasRequiredValues(
        productAttributes[productIndex],
        companyProductAttributes
      );
    } else if (activeSteps[productIndex] === STEP.MARKETING_SALES_MATERIAL) {
      return hasRequiredMarketingValues(
        productAttributes[productIndex].personas
      );
    }
    return true;
  };

  const showErrorToast = () => {
    toast.show({
      type: 'icon',
      icon: XClose,
      iconColor: 'Error',
      title: 'Oops!',
      subtitle: 'Something went wrong. Please try again',
      key: TOAST_KEY_MAP.ERROR_PUBLISHING_SUMMARY,
    });
  };

  useEffect(() => {
    const validate = nullCheckForFieldsInCurrentStep();
    setNextButtonEnabled({
      ...nextButtonEnabled,
      [productIndex]: {
        ...nextButtonEnabled[productIndex],
        [activeSteps[productIndex]]: validate,
      },
    });
  }, [productAttributes[productIndex], activeSteps]);

  const updateSharedState =
    (key: any, value: string | string[]) => {
      const updateProducts = [...productAttributes];
      updateProducts[productIndex] = {
        ...updateProducts[productIndex],
        [key]: value,
      };
      setIsProductAttributesEdited(true);
      setProductAttributes(updateProducts);
    };

  const fetchEmailWriterProductAttributes = async () => {
    const selectedProductAttributes = [...productAttributes];
    const attributesUrl =
      `sales-ai-settings/${orgId}/admin/` +
      `email-writer-product-attribute/?product=${product?.product}`;
    const fetchAttributes: any = await request(attributesUrl, {
      method: 'GET',
    });

    const fetchAttributesData = fetchAttributes?.data;

    // Prioritize 'inprogress' first, then 'active'
    const selectedAttribute =
      fetchAttributesData.find((item) => item.product_summary_state_status === 'inprogress') ||
      fetchAttributesData.find((item) => item.product_summary_state_status === 'active');

    // If a matching attribute is found, set it at the specified index
    if (selectedAttribute) {
      selectedProductAttributes[productIndex] = { ...selectedAttribute };
      setProductAttributes(selectedProductAttributes);
    }
    return true;
  };

  const updateProductAttributes = async () => {
    try {
      const updateUrl =
        `sales-ai-settings/${orgId}/admin` +
        `/email-writer-product-attribute/?product=${product.product}`;
      const payload = { ...productAttributes[productIndex] };
      payload.documents = payload?.documents?.filter((doc) => doc.action)
        .map(({ document_id, category, action }) => ({
          document_id,
          category,
          action,
        })) || [];
      const updateSharedDocuments = { ...productAttributes[productIndex] };
      updateSharedDocuments.documents = updateSharedDocuments?.documents
        ?.filter((doc) => doc.action !== 'remove')
        .map(({ document_id, category, file_name, file }) => ({
          document_id,
          category,
          file_name: file_name || file.name,
        })) || [];
      updateSharedState('documents', updateSharedDocuments.documents);
      const result: any = await request(updateUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      if (result?.status === 'success') {
        return true;
      }
      return false;
    } catch (err) {
      showErrorToast();
      return false;
    }
  };

  const handleAcceptSummary = async () => {
    try {
      const acceptSummaryUrl = `sales-ai-settings/${orgId}/admin/accept-email-writer-summary/`;
      const acceptSummaryResponse = await request(acceptSummaryUrl, {
        method: 'POST',
        body: JSON.stringify({
          product: product.product,
        }),
      });
      if (acceptSummaryResponse?.status === 'success') {
        toast.show({
          type: 'icon',
          icon: CheckCircle,
          iconColor: 'Success',
          title: 'Success',
          subtitle: 'Product Summary Published',
          key: TOAST_KEY_MAP.SUMMARY_PUBLISHED,
        });
        getProductList();
        setShowSummary(false);
        toggleCollapseValue(productIndex);
      } else {
        showErrorToast();
      }
    } catch (err) {
      showErrorToast();
    }
  };

  useEffect(() => {
    if (
      !activeSteps ||
      Object.keys(activeSteps).length === 0
    ) {
      return; // Exit early if conditions are not met
    }
    // Access the current steps and product attributes once
    const productAttr = productAttributes[productIndex];

    // Destructure the steps for easier access
    const [stepOne, stepTwo, stepThree] = steps;

    // Define updated steps with necessary logic
    const updatedStepOne = {
      ...stepOne,
      completed: hasRequiredValues(productAttr, companyProductAttributes),
    };
    const updatedStepTwo = {
      ...stepTwo,
      completed: hasRequiredMarketingValues(productAttr?.personas),
      disabled: !hasRequiredValues(productAttr, companyProductAttributes),
    };

    // Create the new current steps array
    const updatedSteps = [updatedStepOne, updatedStepTwo, stepThree];

    // Update the steps array immutably
    setSteps([...updatedSteps]);
  }, [
    activeSteps,
    steps,
    productIndex,
    productAttributes,
    companyProductAttributes,
  ]);

  const handleStepChange = async (step: number, productindex) => {
    if (step === STEP.SUMMARY) {
      const updateAttributes = await updateProductAttributes();
      if (updateAttributes) {
        setActiveSteps((prevSteps) => ({
          ...prevSteps,
          [productindex]: step,
        }));
        setVisited({ ...visited, [step]: true });
      }
    } else {
      setActiveSteps((prevSteps) => ({
        ...prevSteps,
        [productindex]: step,
      }));
      setVisited({ ...visited, [step]: true });
    }
  };

  const handleEditButtonClick = async () => {
    if (isCollapsed) {
      await fetchEmailWriterProductAttributes();
    }
    setEditBtnClicked(true);
    if (showSummary) {
      setShowSummary(false);
    } else {
      toggleCollapseValue(productIndex);
    }
  };

  // change below value to published to see published ui
  const getProductStatus = (arr) => {
    if (arr.some((obj) => obj.status === 'active')) {
      return 'Published';
    }
    if (arr.some((obj) => obj.status === 'inprogress')) {
      return 'Unpublished Changes';
    }
    return '';
  };

  const handleUseSummaryBtn = (isBeingEdited) => {
    setIsUseThisSummaryEnabled(!isBeingEdited);
  };

  productSummaryStatus[productIndex] = getProductStatus(product?.summaries);

  isSummaryAccepted[productIndex] = product?.summaries[0]?.status === 'active';

  useEffect(() => {
    if (product) {
      const activeSummaryObj = product?.summaries?.find(
        (summary) => summary?.status === 'active'
      );
      // Select the active summary, or default to the first summary if no active one is found
      const updatedSummary =
        (activeSummaryObj && activeSummaryObj?.summary) ||
        (product?.summaries && product?.summaries[0]?.summary) ||
        '';
      setGeneratedSummary(updatedSummary);
    }
  }, [product]);

  const isProductStatus =
    productSummaryStatus[productIndex] === 'Published' ||
    productSummaryStatus[productIndex] === 'Unpublished Changes';

  const showBottomBorder = !isCollapsed && (editBtnClicked || showSummary);

  const onPrductCardCancel = () => {
    setIsModalClickedFromParent(true);
    if (isProductAttibutesEdited) {
      setOpenUnsavedChangesModal(true);
    } else {
      toggleCollapseValue(productIndex);
      setOpenUnsavedChangesModal(false);
    }
  };

  const handleUnsavedModalDiscard = () => {
    if (!isModalClickedFromParent) {
      setIsContentEditableField(false);
      handleUseSummaryBtn(false);
      setEditableContent(initialEditableContent);
    } else {
      toggleCollapseValue(productIndex);
    }
    setOpenUnsavedChangesModal(!openUnsavedChangesModal);
  };

  const handleUnsavedModalContinue = () => {
    if (!isModalClickedFromParent) {
      setIsContentEditableField(true);
    } else {
      setIsModalClickedFromParent(false);
    }
    setOpenUnsavedChangesModal(!openUnsavedChangesModal);
  };

  return (
    <div key={product.product}>
      <Card
        className={styles.productCard}
        style={{ borderRadius: showBottomBorder ? '6px 6px 0px 0px' : '6px', display: 'flex' }}
      >
        <Flex alignItems={'center'} style={{ width: '100%' }}>
          <Text
            type={Text.TYPE.TITLE}
            className={styles.productTitle}
            weight={'bold'}
          >
            {product.display_name}
          </Text>
          {isProductStatus && (
            <><div className={styles.badgeStyle}>
              <Badge
                color={
                    productSummaryStatus[productIndex] === 'Published'
                      ? 'success'
                      : 'warning'
                  }
                mode={'light'}
              >
                {productSummaryStatus[productIndex]}
              </Badge>
            </div><Button
              icon={Edit}
              hierarchy={'secondary'}
              className={styles.editBtn}
              onClick={async () => { await handleEditButtonClick(); }}
            /></>
          )}
          {
            // eslint-disable-next-line no-nested-ternary
            isCollapsed ? (
              isProductStatus ? (
                <ChevronDown
                  size={20}
                  className={styles.addProductText}
                  onClick={() => {
                    setShowSummary(true);
                    setEditBtnClicked(false);
                    toggleCollapseValue(productIndex);
                  }}
                />
              ) : (
                <Link
                  className={styles.addProductText}
                  onClick={() => {
                    setEditBtnClicked(true);
                    toggleCollapseValue(productIndex);
                  }}
                  style={{ marginLeft: 'auto' }}
                >
                  <Plus size={16} className={styles.plusIcon} />
                  Add Product Info
                </Link>
              )
            ) : (
              <ChevronUp
                size={20}
                className={styles.addProductText}
                style={{
                  marginLeft: isProductStatus ? '' : 'auto',
                }}
                onClick={() => {
                  setShowSummary(false);
                  toggleCollapseValue(productIndex);
                }}
              />
            )
          }
        </Flex>
      </Card>

      {!isCollapsed && editBtnClicked ? (
        <><Card className={styles.collapsedProductCard}>
          <Grid style={{ gridTemplateColumns: '22% 78%' }}>
            <Grid.Cell
              column="1"
              row={1}
              style={{
                padding: '20px 18px',
                borderRight: '1px solid #E5E7EB',
              }}
            >
              <div className={styles.progressSteps}>
                <ProgressSteps
                  size="small"
                  onStepSelected={(step) =>
                      handleStepChange(step, productIndex)
                    }
                  steps={steps}
                  activeStep={activeSteps[productIndex] || 0}
                  orientation="vertical"
                />
              </div>
            </Grid.Cell>
            <Grid.Cell column="2" row={1} style={{ padding: '20px' }}>
              {activeSteps[productIndex] === STEP.COMPANY_AND_PRODUCT && (
              <CompanyProductDetails
                companyAttributes={productAttributes[productIndex]}
                updateSharedState={updateSharedState}
              />
                )}
              {activeSteps[productIndex] ===
                  STEP.MARKETING_SALES_MATERIAL && (
                    <MarketingSalesMaterial
                      marketingSalesAttributes={productAttributes[productIndex]}
                      orgId={orgId}
                      updateSharedState={updateSharedState}
                    />
                  )}
              {activeSteps[productIndex] === STEP.SUMMARY && (
              <SummaryComponent
                setOpenUnsavedChangesModal={setOpenUnsavedChangesModal}
                product={product.product}
                productAttributes={productAttributes[productIndex]}
                orgId={orgId}
                isSummaryAccepted={!!isSummaryAccepted[productIndex]}
                handleUseSummaryBtn={handleUseSummaryBtn}
                setIsEditable={setIsContentEditableField}
                isEditable={isContentEditableField}
                updateSharedState={updateSharedState}
                editableContent={editableContent}
                setEditableContent={setEditableContent}
                setInitialEditableContent={setInitialEditableContent}
              />
                )}
            </Grid.Cell>
          </Grid>
          <Grid style={{ borderTop: '1px solid #E5E7EB' }}>
            <Grid.Cell
              style={{
                padding: '16px 20px',
                display: 'flex',
                gap: '8px',
              }}
            >
              <Button
                hierarchy="tertiary"
                onClick={() => onPrductCardCancel()}
                size={'large'}
                style={{ borderRadius: '6px', height: '36px' }}
              >
                  Cancel
                </Button>
              <Flex gap={16} style={{ marginLeft: 'auto' }}>
                {activeSteps[productIndex] !== STEP.COMPANY_AND_PRODUCT && (
                <Button
                  size={'large'}
                  hierarchy="secondary"
                  className={styles.customBtn}
                  onClick={() =>
                        handleStepChange(
                          activeSteps[productIndex] - 1,
                          productIndex
                        )
                      }
                >
                      Previous
                    </Button>
                  )}
                {activeSteps[productIndex] !== STEP.SUMMARY && (
                <Button
                  size={'large'}
                  className={styles.nextBtn}
                  onClick={() =>
                        handleStepChange(
                          activeSteps[productIndex] + 1,
                          productIndex
                        )
                      }
                  disabled={
                        !nextButtonEnabled[productIndex][
                        activeSteps[productIndex]
                        ]
                      }
                >
                      Next
                    </Button>
                  )}
                {activeSteps[productIndex] === STEP.SUMMARY && (
                <Button
                  size={'large'}
                  className={styles.nextBtn}
                  onClick={() => handleAcceptSummary()}
                  disabled={!isUseThisSummaryEnabled}
                >
                      Use This Summary
                    </Button>
                  )}
              </Flex>
            </Grid.Cell>
          </Grid>
        </Card>{openUnsavedChangesModal && (
        <UnsavedChangesModal
          onClickCloseHandler={() => handleUnsavedModalDiscard()}
          onClickContinueHandler={() => handleUnsavedModalContinue()}
        />
          )}</>
      ) : (
        !isCollapsed &&
        showSummary && (
          <Card className={styles.collapsedProductCard}>
            <Grid style={{}}>
              <Grid.Cell
                style={{
                  padding: '20px',
                }}
              >
                <Text weight={Text.WEIGHT.BOLD} type={Text.TYPE.TITLE2}>
                  Summary
                </Text>
                <EditableContent className={styles.contentStyle}>
                  {generatedSummary}
                </EditableContent>
              </Grid.Cell>
            </Grid>
          </Card>
        )
      )}
    </div>
  );
};
