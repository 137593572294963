import React, { useRef, useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';
import { editCaretPosition } from '../utils';

interface EditableContentProps {
  children: string;
  onChange?: (content: string) => void;
  onBlur?: (content: string) => void;
  className?: string;
  isEditable: boolean;
  [key: string]: any; // Allows for additional props
}

const EditableContent = ({
  children,
  onChange,
  onBlur,
  className,
  isEditable = false,
  ...props
}: EditableContentProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  // Sanitization options
  const sanitizeOptions = {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'ul', 'li', 'ol', 'br'],
    allowedAttributes: {
      a: ['href', 'target', 'data-type'],
    },
  };

  // Convert newlines to <br> tags
  const sanitizeContent = (htmlString: string) => {
    const htmlWithNewLines = htmlString.replace(/\n/g, '<br>');
    return sanitizeHtml(htmlWithNewLines, sanitizeOptions);
  };

  // Initialize the sanitized content
  const cleanHtml = sanitizeContent(children);

  const handleInput = () => {
    if (onChange && divRef.current) {
      const sanitized = sanitizeHtml(divRef.current.innerHTML, sanitizeOptions);
      onChange(sanitized);
    }
  };

  const handleBlur = () => {
    if (onBlur && divRef.current) {
      const sanitized = sanitizeHtml(divRef.current.innerHTML, sanitizeOptions);
      onBlur(sanitized);
    }
  };

  // Effect to render the initial sanitized content
  useEffect(() => {
    if (divRef.current) {
      divRef.current.innerHTML = cleanHtml;
    }
    const editor = divRef.current;
    // To prevent the cursor jumping
    editCaretPosition(editor);
  }, [cleanHtml]);

  return (
    <div
      ref={divRef}
      contentEditable={isEditable}
      onInput={handleInput}
      onBlur={handleBlur}
      className={`${className} contentEditableDivCopy`}
      suppressContentEditableWarning
      style={{ paddingRight: '15px' }}
      {...props}
    />
  );
};

export default EditableContent;
