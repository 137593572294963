import React from 'react';
import { Flex } from '@sixsense/core/components';
import { css } from 'styles/emotion';
import { Text } from 'v2-components';
import { GENAI_HEADER, GENAI_SUBHEADER, BACK_BUTTON_TEXT } from '../constant';

const styles = {
  container: css({
    padding: '10px 20px 20px 46px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 2px 0 rgba(74,87,94,0.2)',
  }),
  subBody: css({
    fontSize: '14px',
    lineHeight: '24px',
    color: '#696F7B',
  }),
  title: css({
    fontSize: '18px',
    lineHeight: '24px',
  }),
  button: css({
    position: 'absolute',
    right: '30px',
    top: '16px',
    borderRadius: '6px',
    height: '36px',
    border: '1px solid #CDD1D5',
    padding: '5px 15px',
    fontSize: '14px',
    color: '#38414E',
  }),
  buttonText: css({
    color: '#38414E',
    '&:hover': {
      color: '#38414E',
    },
  }),
};

const GenerativeAIHeader = () => (
  <Flex className={styles.container}>
    <span>
      <Text type={Text.TYPE.TITLE} className={styles.title} weight="bold">
        {GENAI_HEADER}
      </Text>
      <br />
      <Text type={Text.TYPE.SUBBODY} className={styles.subBody}>
        {GENAI_SUBHEADER}
      </Text>
    </span>
    <Flex className={styles.button}>
      <a href="/settings" className={styles.buttonText}>
        {BACK_BUTTON_TEXT}
      </a>
    </Flex>
    <div style={{ flex: 1 }} />
  </Flex>
);

export default GenerativeAIHeader;
