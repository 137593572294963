import React, { useState, useEffect } from 'react';
import { useRequest } from '@sixsense/core';
import { ProductCardComponent } from './ProductCardComponent';
import { css } from 'styles/emotion';
import { Alert, useToast } from '@sixsense/core/components';
import { Rocket } from '@sixsense/core/icons';

const styles = {
  aiwriterAlert: css({
    marginTop: '28px',
  }),
};

export const ProductCard = ({ orgId, onCollapseChange }) => {
  const [showEmailWriterAlert, setShowEmailWriterAlert] =
    useState<Boolean>(true);
  const [collapsedState, setCollapsedState] = useState<Array<Boolean>>([]);
  const [activeSteps, setActiveSteps] = useState({});
  const [productList, setProductList] = useState([]);
  const [productListApiSuccess, setProductListApiSuccess] = useState(false);
  const request = useRequest();
  const toast = useToast();

  const toggleCollapseValue = (productIndex: number) => {
    setCollapsedState((prevState) =>
      prevState?.map((collapsed, i) => (i === productIndex ? !collapsed : true))
    );
    setActiveSteps((prevSteps) => ({
      ...prevSteps,
      [productIndex]: 0,
    }));
    onCollapseChange(collapsedState);
  };

  const getProductList = async () => {
    const productsUrl = `sales-ai-settings/${orgId}/admin/email-writer-product-summaries/`;
    const fetchProducts: any = await request(productsUrl, {
      method: 'GET',
    });
    if (fetchProducts?.status === 'success') {
      setProductListApiSuccess(true);
      setProductList(fetchProducts?.data || []);
      const products = fetchProducts?.data;
      if (products && products?.length > 0) {
        const hasActiveSummary = products?.some((product) =>
          product?.summaries?.some((summary) => summary?.status === 'active')
        );
        setShowEmailWriterAlert(!hasActiveSummary);
      }
      setCollapsedState(new Array(products?.length).fill(true));
    } else {
      setProductListApiSuccess(false);
      toast.show({
        type: 'icon',
        icon: Rocket,
        iconColor: 'Error',
        title: 'Oops!',
        subtitle: 'Something went wrong. Please try again',
      });
    }
  };

  useEffect(() => {
    getProductList();
  }, []);

  const closeAlert = () => {
    setShowEmailWriterAlert(!showEmailWriterAlert);
  };

  return (
    <>{productListApiSuccess && (
      <>{showEmailWriterAlert && (
      <Alert
        type="warning"
        description={'Finish adding your product information to enable your' +
              'Sales Representative to use the AI Writer'}
        closable
        size="sm"
        className={styles.aiwriterAlert}
        onDismiss={closeAlert}
        banner={'true'}
      />
        )}{productList?.map((product, index) => (
          <ProductCardComponent
            key={product?.product}
            product={product}
            productIndex={index}
            orgId={orgId}
            isCollapsed={collapsedState[index]}
            toggleCollapseValue={() => toggleCollapseValue(index)}
            activeSteps={activeSteps}
            setActiveSteps={setActiveSteps}
            getProductList={getProductList}
          />
        ))}</>
    )}</>

  );
};
