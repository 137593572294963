import { isEmpty, lowerCase } from 'lodash';
import { queryStringParamsSelector } from 'modules/global/selectors';
import { createSelector } from 'reselect';
import { EVENT, PRODUCT } from '../../constants';

export const jobsRootSelector = (state) => state.taxonomyV2.jobsRoot;
export const jobsListSelector = createSelector(
    jobsRootSelector,
    (jobsRoot) =>
        jobsRoot.jobsList
  );

export const jobsSelector = createSelector(
    jobsListSelector,
    (jobsList) => jobsList.jobs
  );

export const selectedTabSelector = createSelector(
    jobsListSelector,
    (jobsList) => jobsList.selectedTab
  );

export const tabQueryParamSelector = createSelector(
    queryStringParamsSelector,
    ({ tab }) => {
      if (isEmpty(tab)) return EVENT;
      if (lowerCase(tab) === PRODUCT) return PRODUCT;
      return EVENT;
    }
  );

export const queuedBySelector = createSelector(
    jobsListSelector,
    (jobsList) => jobsList.filters
  );

export const countsSelector = createSelector(
    jobsListSelector,
    (jobsList) => jobsList.counts
  );
