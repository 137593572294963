import { combineReducers } from 'redux';
import {
  loadAuditsStateGenerator,
  loadLastReviewedOnGenerator,
  loadAssigneeGenerator,
} from '../stateGenerators';
import { actions as auditActions, actionTypes as auditActionTypes, auditsReducer } from './audits';

export const overviewReducer = combineReducers({
  [loadLastReviewedOnGenerator.stateKey]: loadLastReviewedOnGenerator.reducer,
  [loadAuditsStateGenerator.stateKey]: loadAuditsStateGenerator.reducer,
  [loadAssigneeGenerator.stateKey]: loadAssigneeGenerator.reducer,
  audits: auditsReducer,
});

export const actions = {
  ...auditActions,
};

export const actionTypes = {
  ...auditActionTypes,
};
