import moment from 'moment';
import { App } from '@sixsense/rbac/constants';


export const usersFilter = (users) => {
  const formattedUsers = users.map(
    (user) => ({ ...user, ...{ role_rbac: getRoleRbac(user.roles) } })
  );
  return formattedUsers;
};

const getRoleRbac = (roles = []) => {
  const role = roles.find((el) => el.app_id === App.SALES);
  return role ? role.role_id : null;
};

export const getCreditDateFormat =
 (date : string | number = '') => moment.utc(date).format('DD MMM YY');
