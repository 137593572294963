import { orgSelector } from 'modules/user/selectors';
import moment from 'moment';
import { createSelector } from 'reselect';
import { stateGenerator } from 'routes/Advertising/routes/Campaigns/stateGenerator';

import { get } from 'lodash';
import {
  PRESET_STRING,
} from '../../constants';

export const baseSelector = (state) => state.taxonomyV2.overview;

const loadLastReviewedOnEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/turbotax_last_reviewed_on/`
);

export const loadLastReviewedOnGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${PRESET_STRING}/RECORDS_LAST_REVIEWED`,
  endpointSelector: loadLastReviewedOnEndpointSelector,
  stateKey: 'lastReviewed',
  initialData: [],
  onSuccessActions: [],
  onFailureActions: [],
  method: 'GET',
});


const auditsEndpointSelector = createSelector(
  orgSelector,
  (_, loadAction) => loadAction,
  (orgId, loadAction) => {

    const startDate = get(loadAction, 'payload.startDate') ?
    moment(loadAction.payload.startDate).format('YYYY/MM/DD') :
    '2000/01/01';

    const endDate = get(loadAction, 'payload.endDate') ?
    moment(loadAction.payload.endDate).format('YYYY/MM/DD'):
    moment(new Date()).format('YYYY/MM/DD');

    let url = `turbotax/taxonomy/org/${orgId}/global_audits/` +
    `?start_date=${startDate}` +
    `&end_date=${endDate}`;

    if (get(loadAction, 'payload.user')) {
      url += `&user=${loadAction.payload.user}`;
    }

    if (get(loadAction, 'payload.activityType.value')) {
      url += `&activity_type=${loadAction.payload.activityType.value}`;
    }

    return url;
  }
);

export const loadAuditsStateGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${PRESET_STRING}/GET_AUDITS`,
  endpointSelector: auditsEndpointSelector,
  stateKey: 'getAudits',
  onSuccessActions: [],
  onFailureActions: [],
  method: 'GET',
});

const loadAssigneeEndpointSelector = createSelector(
  orgSelector,
  (orgId) => `turbotax/taxonomy/org/${orgId}/turbotax_assignee/`
);

/**
 * TODO: this is duplicated from preferences, but should be refactored
 * the reason that this is not done currently is because here it is read-only
 * whereas in preferences it is editable
 */

export const loadAssigneeGenerator = stateGenerator({
  baseStateSelector: baseSelector,
  actionPrefix: `${PRESET_STRING}/LOAD_ASSIGNEE`,
  endpointSelector: loadAssigneeEndpointSelector,
  stateKey: 'loadAssignee',
  initialData: [],
  onSuccessActions: [],
  onFailureActions: [],
  method: 'GET',
});

