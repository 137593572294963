import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import {
  get,
  filter,
  every,
  isEmpty,
  reduce,
  map,
  values,
  keys,
  flatten,
  property,
  size,
  flow,
  includes,
  forEach,
} from 'lodash';
import {
  userObjectSelector,
  isExternalSixsenseUserSelector,
  multiAppsListSelector,
  packagePlanSelector,
} from 'modules/user/selectors';
import { USER, L_USER, OWNER, DEFAULT_ROLE_ORDER, DUMMY_SELECT_VALUE } from './constants';
import { formatOrgUser, isAnyOneRoleIdValid } from './utils';
import { EMAIL_REGEX } from 'utils/constants';
import orderByfp from 'lodash/fp/orderBy';
import filterfp from 'lodash/fp/filter';
import moment from 'moment';
import { Permission } from '@sixsense/rbac';
import { getIsApplyAppLicenseing } from './AppManageConfig/license.config';
import {
  getPrimaryAdminIdList,
  getAdminIdList,
  getShortAndLongNameMap,
} from './AppManageConfig/apps.roles.config';

const formatUser = (u) =>
  keys(u).length > 1
    ? {
      isOwner: u.isOwner,
      checked: u.checked,
      id: get(u, 'user.id'),
      organizationUserId: u.id,
      email: get(u, 'user.email'),
      username: get(u, 'user.username'),
      role: u.is_limited ? L_USER : USER, // todo: move owner logic here
      inviteDate: u.invite_date
          ? moment(u.invite_date).format('MMM DD, YYYY')
          : 'N/A',
      invitedBy: u.invited_by.match('6sense') ? '6sense' : u.invited_by,
      lastLogin: get(u, 'user.last_login')
          ? moment(get(u, 'user.last_login')).format('MMM DD, YYYY')
          : 'N/A',
        status: u.is_active // eslint-disable-line
          ? 'Enabled'
          : get(u, 'user.last_login.length')
          ? 'Disabled'
          : 'Invited',

      inviteDateSort: get(u, 'invite_date', 0),
      lastLoginSort: get(u, 'user.last_login', 0),
    }
    : {};

const _userMgmtSelector = (state) => state.userManagement || {};

const ownerSelector = createSelector(_userMgmtSelector, ({ owner }) =>
  isEmpty(owner) ? owner : formatUser(owner)
);

const userSearchSelector = createSelector(
  _userMgmtSelector,
  ({ userSearch }) => userSearch || ''
);
const userStatusSelector = createSelector(
  _userMgmtSelector,
  ({ userStatus }) => userStatus
);

const displayOwnerSelector = createSelector(ownerSelector, (owner = {}) =>
  isEmpty(owner)
    ? []
    : [
        { cls: 'email', value: owner.username || 'n/a', label: 'Owner' },
        { cls: 'status', value: owner.status || 'n/a', label: 'Status' },
        { cls: 'inviteDate', value: owner.inviteDate, label: 'Invited on' },
        { cls: 'invitedBy', value: owner.invitedBy, label: 'Invited by' },
        { cls: 'lastLogin', value: owner.lastLogin, label: 'Last login' },
    ]
);

// user type selectors
const invitedUsersSelector = createSelector(_userMgmtSelector, ({ invited }) =>
  map(invited, formatUser)
);
const activeUsersSelector = createSelector(_userMgmtSelector, ({ active }) =>
  map(active, formatUser)
);
const deactivatedUsersSelector = createSelector(
  _userMgmtSelector,
  ({ deactivated }) => map(deactivated, formatUser)
);

const allUsersSelector = createSelector(
  activeUsersSelector,
  deactivatedUsersSelector,
  invitedUsersSelector,
  (active = [], deactivated = [], invited = []) => ({
    active,
    deactivated,
    invited,
  })
);
const flatAllUsersSelector = createSelector(allUsersSelector, (users) =>
  flatten(values(users))
);

export const appsListSelector = createSelector(
  multiAppsListSelector,
  (multiAppsList) => {
    const shortAndLongNameMap = getShortAndLongNameMap();
    const appsList = [];
    forEach(multiAppsList || [], (appObject) => {
      let shortAndLongNameObject = shortAndLongNameMap[appObject.id];
      if (!shortAndLongNameObject) {
        shortAndLongNameObject = {
          shortName: appObject.name,
          longName: appObject.name,
        };
      }
      appsList.push({
        ...appObject,
        ...shortAndLongNameObject,
      });
    });
    return appsList;
  }
);

export const rolesSelector = createSelector(
  _userMgmtSelector,
  ({ roles, licenseResponse }) => {
    const rolesMap = {};
    forEach(roles, (role) => {
      rolesMap[role.id] = role;
    });
    forEach(licenseResponse, ({ licenses }) => {
      forEach(
        licenses,
        ({ role_ids, licenseLabel }) => {
          forEach(role_ids, (roleId) => {
            if (rolesMap[roleId]) {
              rolesMap[roleId].licenseLabel = licenseLabel;
            }
          });
        }
      );
    });
    return roles || [];
  }
);

export const formattedAllUsersSelector = createSelector(
  _userMgmtSelector,
  rolesSelector,
  appsListSelector,
  ({ users }, roles, appsList) => {
    const formattedUsers = users.map(
      (user) => formatOrgUser(user, roles || [], appsList)
    );
    return formattedUsers;
  },
);


export const licenseResponseSelector = createSelector(
  _userMgmtSelector,
  ({ licenseResponse }) => licenseResponse || []
);

export const getRoleByIdSelector = createSelector(rolesSelector, (roles) => {
  const rolesMap = {};
  forEach(roles, (role) => {
    rolesMap[role.id] = role;
  });
  return (roleId) =>
    rolesMap[roleId] || {
      id: 0,
      name: '',
      description: '',
      parent: '',
      organization: '',
      app: 1,
      permissions: [],
    };
});

export const authTypeSelector = createSelector(
  _userMgmtSelector,
  ({ authType }) => authType
);

// To do: to be updated with some flag isPrimaryAdmin
export const primaryAdminListSelector = createSelector(
  rolesSelector,
  (roles) => {
    const primaryAdminIdList = getPrimaryAdminIdList();
    const primaryAdminRoleList = [];
    for (const role of roles) {
      if (primaryAdminIdList.includes(role.id)) {
        primaryAdminRoleList.push(role);
      }
    }
    return primaryAdminRoleList;
  }
);

// To do: to be updated with some flag isPrimaryAdmin
export const primaryAdminIdListSelector = createSelector(() => {
  const primaryAdminIdList = getPrimaryAdminIdList();
  return primaryAdminIdList;
});

// To do: To be updated with some flag isAdmin
export const adminRoleIdListSelector = createSelector(() => {
  const adminRoleIdList = getAdminIdList();
  return adminRoleIdList;
});

export const formattedUsersSelector = createSelector(
  _userMgmtSelector,
  authTypeSelector,
  rolesSelector,
  primaryAdminListSelector,
  appsListSelector,
  ({ users }, authType, roles, primaryAdminList, appsList) => {
    const formattedUsers = users
      .map((user) => formatOrgUser(user, roles, appsList))
      .filter(({ roleIdMap }) => {
        for (const { id: appId } of appsList) {
          if (primaryAdminList.find(({ id }) => id === roleIdMap[appId])) {
            return false;
          }
        }
        return true;
      });
    if (authType === 'SAML') {
      return formattedUsers.filter(
        ({ status }) => status === 'enabled' || status === 'disabled'
      );
    }
    return formattedUsers;
  }
);

export const formattedOwnerSelector = createSelector(
  _userMgmtSelector,
  rolesSelector,
  primaryAdminListSelector,
  appsListSelector,
  ({ users }, roles, primaryAdminList, appsList) => {
    const owner = users
      .map((user) => formatOrgUser(user, roles, appsList))
      .find(({ roleIdMap }) => {
        for (const { id: appId } of appsList) {
          if (primaryAdminList.find(({ id }) => id === roleIdMap[appId])) {
            return true;
          }
        }
        return false;
      });
    return owner || undefined;
  }
);

export const formattedAllPrimaryAdminSelector = createSelector(
  _userMgmtSelector,
  rolesSelector,
  primaryAdminListSelector,
  appsListSelector,
  ({ users }, roles, primaryAdminList, appsList) => {
    const paList = users
      .map((user) => formatOrgUser(user, roles, appsList))
      .filter(({ roleIdMap }) => {
        for (const { id: appId } of appsList) {
          if (primaryAdminList.find(({ id }) => id === roleIdMap[appId])) {
            return true;
          }
        }
        return false;
      });
    return paList;
  }
);

export const getFormatedUserByEmailIdFromListUserSelector = createSelector(
  rolesSelector,
  appsListSelector,
  (roles, appsList) => (users, emailId) => {
    const formatedUser = users.map(
      (user) => formatOrgUser(user, roles, appsList)
    ).find(({ username }) => {
      if (username === emailId) {
        return true;
      }
      return false;
    });
    return formatedUser || undefined;
  }
);

export const getDoesOwnerExist = createSelector(
  formattedOwnerSelector,
  (owner) => !!owner
);

export const getRoleListWithUserCountByAppSelector = createSelector(
  rolesSelector,
  formattedUsersSelector,
  getDoesOwnerExist,
  formattedOwnerSelector,
  (roles, users, doesOwnerExist, owner) => (appId) => {
    const roleList = [];
    const allUsers = [...users];
    if (doesOwnerExist) {
      allUsers.push(owner);
    }
    for (const role of roles) {
      if (role.app === appId) {
        roleList.push({
          ...role,
          numUsers: allUsers.reduce(
            (count, user) =>
              user.roleIdMap[appId] === role.id && user.status === 'enabled'
                ? count + 1
                : count,
            0
          ),
        });
      }
    }
    roleList.sort(
      (a, b) => DEFAULT_ROLE_ORDER[a.id] - DEFAULT_ROLE_ORDER[b.id]
    );
    return roleList;
  }
);

const sortBySelector = createSelector(
  _userMgmtSelector,
  ({ sortBy, sortOrder }) => [sortBy, sortOrder]
);

const usersToDisplaySelector = createSelector(
  userStatusSelector,
  userSearchSelector,
  allUsersSelector,
  sortBySelector,
  (status, search, users, [sortBy, sortOrder]) =>
    flow(
      filterfp(({ username }) => includes(username, search.toLowerCase())),
      orderByfp([sortBy], [sortOrder])
    )(users[status])
);

const getTotalAppActiveUserCountSelector = createSelector(
  formattedUsersSelector,
  getDoesOwnerExist,
  (users, doesOwnerExist) => (appId) =>
    users.filter(
      ({ roleIdMap, status }) =>
        isAnyOneRoleIdValid(roleIdMap[appId]) &&
        status !== 'disabled' &&
        status !== 'inviteExpired'
    ).length + (doesOwnerExist ? 1 : 0)
);

const totalActiveUserCountSelector = createSelector(
  formattedUsersSelector,
  getDoesOwnerExist,
  (users, doesOwnerExist) =>
    users.filter(
      ({ status }) => status !== 'disabled' && status !== 'inviteExpired'
    ).length + (doesOwnerExist ? 1 : 0)
);

const getIsAppLicenseUserLimitExceedMapSelector = createSelector(
  licenseResponseSelector,
  appsListSelector,
  (licenseResponse, appsList) => (usersToCheck) => {
    const isAppLicenseUserLimitExceedMap = {};
    let isAnyAppLicenseUserLimitExceed = false;
    forEach(licenseResponse, ({ app_id: appId, licenses }) => {
      isAppLicenseUserLimitExceedMap[appId] = {};
      forEach(licenses, (license) => {
        const {
          value: maxUserLicense,
          users: totalActiveUsers,
          role_ids,
          licenseLabel,
        } = license;
        let moreUserToConsider = 0;
        forEach(
          usersToCheck,
          ([
            status = 'enabled',
            count = 1,
            currentRoleId = null,
            newRoleId = null,
          ]) => {
            if (status === 'enabled' || status === 'invited') {
              if (newRoleId === -1) {
                return;
              }
              if (role_ids.includes(newRoleId) && role_ids.includes(currentRoleId)) {
                return;
              }
              if (
                newRoleId === undefined ||
                newRoleId === null ||
                newRoleId === DUMMY_SELECT_VALUE
              ) {
                if (role_ids.includes(currentRoleId)) {
                  moreUserToConsider += count;
                }
              }
              if (role_ids.includes(newRoleId)) {
                moreUserToConsider += count;
              }
            }
          }
        );
        isAppLicenseUserLimitExceedMap[appId][licenseLabel] =
          moreUserToConsider > 0 &&
          totalActiveUsers + moreUserToConsider > maxUserLicense &&
          maxUserLicense !== -1 &&
          getIsApplyAppLicenseing(appId);

        if (isAppLicenseUserLimitExceedMap[appId][licenseLabel]) {
          isAnyAppLicenseUserLimitExceed = true;
        }
      });
    });
    forEach(appsList, (({ id: appId }) => {
      if (!isAppLicenseUserLimitExceedMap[appId]) {
        isAppLicenseUserLimitExceedMap[appId] = {};
      }
    }));
    return [isAppLicenseUserLimitExceedMap, isAnyAppLicenseUserLimitExceed];
  }
);

const isIframeUserSelector = createSelector(
  packagePlanSelector,
  (packagePlan) => {
    if (packagePlan) {
      if (
        packagePlan.predictive &&
        packagePlan.predictive.is_deleted === false
      ) {
        return true;
      }
      if (packagePlan.SI && packagePlan.SI.is_deleted === false) {
        return true;
      }
    }
    return false;
  }
);

const getIFrameUserCountSelector = createSelector(
  _userMgmtSelector,
  ({ iFrameUserCount }) => iFrameUserCount
);

const noPlatformUsersSelector = createSelector(flatAllUsersSelector, (users) =>
  every(users, isEmpty)
);

const inviteModalVisibilitySelector = createSelector(
  _userMgmtSelector,
  ({ inviteModalVisible }) => inviteModalVisible
);

const invalidEmailsSelector = createSelector(
  _userMgmtSelector,
  ({ invalidEmails }) => invalidEmails
);

const _inviteUsersFormValueSelector = (state) =>
  getFormValues('inviteUsers')(state) || {
    ownerEmail: '',
    basicUsers: '',
  };

const _existingUsersSelector = createSelector(flatAllUsersSelector, (users) =>
  map(users, property('email'))
);

const tab = new RegExp('\\t', 'g');
const newline = new RegExp('\\n', 'g');
const lineBr = new RegExp('\\r', 'g');
const groupEmails = (emails = '', existingUserEmails) =>
  reduce(
    emails.split(','),
    (filteredEmails, em) => {
      const cleanedEmail = em
        .replace(tab, '')
        .replace(newline, '')
        .replace(lineBr, '')
        .trim();
      if (cleanedEmail.length) {
        const preExistingUser = existingUserEmails.includes(cleanedEmail);
        const isValid = EMAIL_REGEX.test(cleanedEmail) && !preExistingUser;

        const emailType = isValid
          ? filteredEmails.validEmails
          : filteredEmails.invalidEmails;
        emailType.push(
          isValid
            ? cleanedEmail
            : {
              email: cleanedEmail,
              error: preExistingUser ? '- Existing User' : '- Invalid',
            }
        );
      }

      return filteredEmails;
    },
    { validEmails: [], invalidEmails: [] }
  );

const inviteFormValueSelector = createSelector(
  _inviteUsersFormValueSelector,
  _existingUsersSelector,
  (
    { ownerEmail, basicUsers = '', ...restProps } = {},
    existingUserEmails
  ) => ({
    ownerEmail,
    basicUsers: groupEmails(basicUsers, existingUserEmails),
    ...restProps,
  })
);

const formStatusSelector = createSelector(
  _userMgmtSelector,
  ({ formStatus = '' }) => formStatus
);
const justInvitedUsersSelector = createSelector(
  _userMgmtSelector,
  ({ invitedUsers }) => invitedUsers
);

const swapModalVisibilitySelector = createSelector(
  _userMgmtSelector,
  ({ swapModalVisible }) => swapModalVisible
);

const newOwnerSelector = (state) => getFormValues("changeOwner")(state) || "{}"; // eslint-disable-line

const userCountSelector = createSelector(_userMgmtSelector, (routeState) =>
  size(routeState[routeState.userStatus])
);

// use invidividual selectors here so we don't have to reformat
const checkedUsersSelector = createSelector(
  activeUsersSelector,
  deactivatedUsersSelector,
  invitedUsersSelector,
  (active, deactivated, invited) =>
    filter(flatten([active, deactivated, invited]), property('checked'))
);

const areAllUsersOfStatusChecked = createSelector(
  allUsersSelector,
  userStatusSelector,
  (users, status) => every(users[status], property('checked'))
);

const checkedUserCountSelector = createSelector(
  flatAllUsersSelector,
  (users) => filter(users, property('checked')).length
);

const allUsersForDownloadSelector = createSelector(
  formattedUsersSelector,
  formattedOwnerSelector,
  (users, owner) => [...users, owner]
);

const usersLoadedSelector = createSelector(
  _userMgmtSelector,
  ({ usersLoaded, ownerLoaded } = {}) => usersLoaded && ownerLoaded
);

const currentUserRoleSelector = createSelector(
  userObjectSelector,
  ({ role } = {}) => role
);

// todo: move role stuff into usercondition compponent instead
const canUseTableHeaderDropdownActions = createSelector(
  userObjectSelector,
  (userObj = {}) => [OWNER].includes(userObj.role) || userObj.is_staff
);
const canRemindOrResetSelector = canUseTableHeaderDropdownActions;
const canInviteSelector = canUseTableHeaderDropdownActions;
const canChangeRoleSelector = canUseTableHeaderDropdownActions;

// dev:
// const canUseTableHeaderDropdownActions = () => true;
// const canChangeRoleSelector = () => true;
// const canInviteSelector = () => true;
// const canRemindOrResetSelector = () => true;

const loadUserListErrorSelector = createSelector(
  _userMgmtSelector,
  ({ error }) => error
);

const errorMessageSelector = createSelector(
  _userMgmtSelector,
  ({ userListErrorMessage }) => userListErrorMessage
);

const hasPermissions = (availablePermissions, permissionsArray) =>
  permissionsArray.some((_permission) => {
    const hasPermission = availablePermissions.has(_permission);
    return hasPermission;
  });

const appIdsQueryParamsAPIUrlSelector = createSelector(() => '');

const getAppsPermissionSelector = createSelector(
  _userMgmtSelector,
  ({ appsPermission }) =>
    (appId) =>
      (appsPermission || {})[appId] || new Set()
);

const isAppViewOrEditUserPermissionMapSelector = createSelector(
  appsListSelector,
  isExternalSixsenseUserSelector,
  getAppsPermissionSelector,
  (appsList, isExternalSixsenseUser, getAppsPermissionFunc) => {
    const isAppsPermissionMap = {};
    for (const { id } of appsList) {
      const availablePermissions = getAppsPermissionFunc(id);
      isAppsPermissionMap[id] =
        hasPermissions(availablePermissions, [
          Permission.SETTINGS_USER_MANAGEMENT_EDIT_USER,
          Permission.SETTINGS_USER_MANAGEMENT_VIEW,
        ]) || isExternalSixsenseUser;
    }
    return isAppsPermissionMap;
  }
);

const isAppEditUserPermissionMapSelector = createSelector(
  appsListSelector,
  isExternalSixsenseUserSelector,
  getAppsPermissionSelector,
  (appsList, isExternalSixsenseUser, getAppsPermissionFunc) => {
    const isAppsPermissionMap = {};
    for (const { id } of appsList) {
      const availablePermissions = getAppsPermissionFunc(id);
      isAppsPermissionMap[id] =
        hasPermissions(availablePermissions, [
          Permission.SETTINGS_USER_MANAGEMENT_EDIT_USER,
        ]) || isExternalSixsenseUser;
    }
    return isAppsPermissionMap;
  }
);

const isAppChangeOwnerUserPermissionMapSelector = createSelector(
  appsListSelector,
  isExternalSixsenseUserSelector,
  getAppsPermissionSelector,
  (appsList, isExternalSixsenseUser, getAppsPermissionFunc) => {
    const isAppsPermissionMap = {};
    for (const { id } of appsList) {
      const availablePermissions = getAppsPermissionFunc(id);
      isAppsPermissionMap[id] =
        hasPermissions(availablePermissions, [
          Permission.SETTINGS_USER_MANAGEMENT_OWNER_ASSIGNMENT,
        ]) || isExternalSixsenseUser;
    }
    return isAppsPermissionMap;
  }
);

const isAppAdminAssignmentUserPermissionMapSelector = createSelector(
  appsListSelector,
  isExternalSixsenseUserSelector,
  getAppsPermissionSelector,
  (appsList, isExternalSixsenseUser, getAppsPermissionFunc) => {
    const isAppsPermissionMap = {};
    for (const { id } of appsList) {
      const availablePermissions = getAppsPermissionFunc(id);
      isAppsPermissionMap[id] =
        hasPermissions(availablePermissions, [
          Permission.SETTINGS_USER_MANAGEMENT_ADMIN_ASSIGNMENT,
        ]) || isExternalSixsenseUser;
    }
    return isAppsPermissionMap;
  }
);

export const getRolesListByAppSelector = createSelector(
  rolesSelector,
  adminRoleIdListSelector,
  isAppAdminAssignmentUserPermissionMapSelector,
  (roles, adminRoleIdList, isAppAdminAssignmentUserPermissionMap) => (appId) =>
    roles
      .filter(({ app }) => app === appId)
      .map((role) => {
        const isAdmin = adminRoleIdList.includes(role.id);
        return {
          ...role,
          disabled: !isAppAdminAssignmentUserPermissionMap[appId] && isAdmin,
        };
      })
);

export const getUsernameFilterSelector = createSelector(
  _userMgmtSelector,
  ({ usernameFilter }) => usernameFilter
);

const getEmailsFilterMissingMapSelector = createSelector(
  _userMgmtSelector,
  ({ emailsFilterMissingMap }) =>
    emailsFilterMissingMap || {
      notInSystem: [],
      filteredOut: [],
      paEmail: null,
    }
);

const getRolesTabSelectionSelector = createSelector(
  _userMgmtSelector,
  ({ rolesTabSelection }) => rolesTabSelection
);

export {
  userSearchSelector,
  userStatusSelector,
  flatAllUsersSelector,
  sortBySelector,
  ownerSelector,
  displayOwnerSelector,
  invitedUsersSelector,
  activeUsersSelector,
  deactivatedUsersSelector,
  allUsersSelector,
  usersToDisplaySelector,
  noPlatformUsersSelector,
  inviteModalVisibilitySelector,
  invalidEmailsSelector,
  inviteFormValueSelector,
  formStatusSelector,
  justInvitedUsersSelector,
  swapModalVisibilitySelector,
  newOwnerSelector,
  userCountSelector,
  checkedUsersSelector,
  checkedUserCountSelector,
  areAllUsersOfStatusChecked,
  allUsersForDownloadSelector,
  usersLoadedSelector,
  loadUserListErrorSelector,
  errorMessageSelector,
  currentUserRoleSelector,

  // todo: user UserCondition instead
  canUseTableHeaderDropdownActions,
  canRemindOrResetSelector,
  canChangeRoleSelector,
  canInviteSelector,
  appIdsQueryParamsAPIUrlSelector,

  // isAppEditUserPermissionSelector,
  // isAppChangeOwnerUserPermissionSelector,
  // isAppViewOrEditUserPermissionSelector,
  // isAppAdminAssignmentUserPermissionSelector,
  isAppEditUserPermissionMapSelector,
  isAppChangeOwnerUserPermissionMapSelector,
  isAppViewOrEditUserPermissionMapSelector,
  isAppAdminAssignmentUserPermissionMapSelector,
  getTotalAppActiveUserCountSelector,
  totalActiveUserCountSelector,
  getIsAppLicenseUserLimitExceedMapSelector,
  isIframeUserSelector,
  getIFrameUserCountSelector,
  getEmailsFilterMissingMapSelector,
  getRolesTabSelectionSelector,
};
