import React from "react";
import { Text } from "@sixsense/core/components";
import { localeString } from "utils/utils";
import { NA } from "./common/NA";
import { is6SenseCampaign, isDraftOrScheduledCampaign } from "../../../utils";
import { ROW_DATA_TYPES } from "aa-components/CampaignCSVExportModal/constants";
import Label, { CAMPAIGN_LABELS } from "../../../components/Labels";
import { TextColor } from "@sixsense/core/style/variables";
import { pxToRem } from "@sixsense/core/style";

export const VIEWS = {
  sortByKey: "users_view_through",
  key: "views",
  csvColumnKey: "user_view_through",
  title: <Label name={CAMPAIGN_LABELS.userViewThrough} />,
  dataIndex: "campaign_data.users_view_through",
  width: pxToRem(140),
  render: (userViewThrough, campaign) => {
    const campaignStatus = campaign.campaign_status;
    if (
      is6SenseCampaign(campaign) === false ||
      isDraftOrScheduledCampaign(campaignStatus)
    ) {
      return <NA />;
    }

    return (
      <Text.Body weight="normal" color={`var(${TextColor.DEFAULT})`}>
        {localeString(userViewThrough)}
      </Text.Body>
    );
  },
  csvOverrides: {
    dataIndex: "download_stats.users_view_through",
    render: (userViewThrough, campaign, dataType) => {
      const campaignStatus = campaign.campaign_status;
      const campaignHasStats = !isDraftOrScheduledCampaign(campaignStatus);
      if (
        (is6SenseCampaign(campaign) && campaignHasStats) ||
        dataType === ROW_DATA_TYPES.aggregated
      ) {
        return `"${localeString(parseInt(userViewThrough) || 0)}"`;
      }

      return "-";
    },
  },
  align: "right",
};
