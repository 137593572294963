import moment from 'moment';
import React from 'react';
import {
  Row,
  Col,
  Text,
  Tooltip2 as Tooltip,
  Icon,
  Button,
} from 'v2-components';
import { css } from 'styles/emotion';
import { CreditType } from '../types';
import { AAColor, pxToRem } from '@sixsense/core/style';
import { Card } from '@sixsense/core/components';
import { useSharedState } from '@sixsense/core';
import { dataCreditsUsageState } from '../state';

const styles = {
  creditSectionHeading: css({
    padding: '8px 20px',
  }),
  distributeSection: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  }),
  creditText: css({
    marginTop: '8px',
  }),
  creditBox: css({
    display: 'grid',
  }),
  tooltip: css({
    color: AAColor.BLUE,
    fontSize: '8px',
    marginLeft: pxToRem(3),
    marginTop: '-2px',
    cursor: 'pointer',
  }),
};

type CreditDetailsComponentProps = {
  creditData: CreditType;
  sections: Array<any>;
  label: string;
  showDistributeButton: boolean;
  distributeCredits: Function;
};

const CreditDetailsComponent = ({
  creditData,
  sections,
  label,
  showDistributeButton,
  distributeCredits,
}: CreditDetailsComponentProps) => {
  const [dataCreditsUsageFlag] = useSharedState(dataCreditsUsageState);
  const creditInfo = {
    total_allocated_credits_to_users:
      creditData.total_allocated_credits_to_users || 0,
    total_used_credits: creditData.total_used_credits || 0,
    total_credits: creditData.total_credits || 0,
    expiry_date: creditData.expiry_date
      ? moment.utc(creditData.expiry_date).format('MMM DD, YYYY')
      : '-',
    updated: creditData.updated
      ? moment(creditData.updated).format('MMM DD, YYYY')
      : '-',
    total_unallocated_credits: creditData.total_unallocated_credits || 0,
    total_balance_credits: creditData.total_balance_credits || 0,
  };
  return (
    <Card style={{ margin: 'unset', width: '100%' }}>
      {label ? (
        <Card.Header style={{ padding: '8px 20px' }}>
          <Card.Header.Title style={{ textTransform: 'none' }}>
            {label}
          </Card.Header.Title>
        </Card.Header>
      ) : (
        <></>
      )}
      <Card.Content>
        <Row>
          {sections.map((el) => (
            <Col
              className={styles.creditBox}
              maxWidth={label ? `${100 / sections.length}%` : '16.66%'}
              flexBasis={'50%'}
              key={el.key}
            >
              <Row alignItems={Row.ALIGN_ITEMS.CENTER}>
                <Text> {el.label} </Text>
                <Tooltip
                  overlayStyle={{ width: '300px' }}
                  placement={'top'}
                  overlay={el.tooltip}
                >
                  <Icon type={'info'} className={styles.tooltip} size="10px" />
                </Tooltip>
              </Row>
              <span className={styles.creditText}>
                <Text type="title" weight="bold">
                  {' '}
                  {creditInfo[el.key]}
                </Text>
              </span>
            </Col>
          ))}
          {dataCreditsUsageFlag && showDistributeButton ? (
            <Col className={styles.distributeSection}>
              <Button type="primary" onClick={distributeCredits} disabled={creditData.is_expired}>
                Distribute Credits
              </Button>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Card.Content>
    </Card>
  );
};

export default CreditDetailsComponent;
