import React from 'react';
import { pxToRem } from '@sixsense/core/style';
import { css } from 'styles/emotion';
import { ActionDropdown, ActionItem } from 'v2-components';
import { User } from '../types';
import { useDispatch, useSelector } from '@sixsense/core/versioned/react-redux';
import { useSetSharedValue } from '@sixsense/core/shared-state';
import { selectedUserState } from '../state';
import { actions } from '../modules';
import useUserActions from '../hooks/useUserActions';
import ConfirmModal from './ConfirmModal';
import {
  isAppChangeOwnerUserPermissionMapSelector,
  isAppEditUserPermissionMapSelector,
  getIsAppLicenseUserLimitExceedMapSelector,
  appsListSelector,
} from '../selectors';
import { isViewOnlyEnabledFor6senseAccessSelector } from 'modules/user/selectors';

type Props = {
  user: User;
  isSAML: boolean;
  isPrimaryAdmin: boolean,
};

const styles = {
  more: css({
    width: pxToRem(40),
    cursor: 'pointer',
    color: '#001F32',
  }),
};

const UserActionDropdown = ({ user, isSAML, isPrimaryAdmin }: Props) => {
  const appsList = useSelector(appsListSelector);
  const isViewOnlyEnabledFor6senseAccess = useSelector(isViewOnlyEnabledFor6senseAccessSelector);

  const canEditApps = useSelector(isAppEditUserPermissionMapSelector);
  const getIsAppLicenseUserLimitExceedMap = useSelector(
    getIsAppLicenseUserLimitExceedMapSelector
  );
  const canChangeOwnerApp = useSelector(
    isAppChangeOwnerUserPermissionMapSelector
  );

  const dispatch = useDispatch();
  const setSelectedUser = useSetSharedValue(selectedUserState);
  const { deleteUsers, reinviteUsers, enableUsers, disableUsers } =
    useUserActions();

  const { confirm, modalProps } = ConfirmModal.useConfirm();

  const handleDelete = (userId: number, userName: string) => {
    confirm().then(() => {
      deleteUsers([userId]);
      dispatch(actions.unallocateCreditUsers([userName]));
    });
  };

  const [, isAnyAppLicenseUserLimitExceed] = getIsAppLicenseUserLimitExceedMap(
    appsList.map(({ id: appId }) => ['enabled', 1, null, user.roleIdMap[appId]])
  );

  const isAnyAppEdit = appsList.some(({ id }) => canEditApps[id]);

  const canChangeOwnerAnyApp = appsList.some(({ id }) => canChangeOwnerApp[id]);

  return isAnyAppEdit ? (
    <React.Fragment>
      <ActionDropdown
        id="user_actions"
        leftIcon="more_horiz"
        leftIconClass={styles.more}
      >
        <ActionItem action={() => setSelectedUser(user.id)}>
          View and Edit User
        </ActionItem>
        {user.status === 'enabled' && !isPrimaryAdmin ? (
          <ActionItem
            action={() => {
              disableUsers([user.id]);
              dispatch(actions.unallocateCreditUsers([user.username]));
            }}
            disabled={isViewOnlyEnabledFor6senseAccess}
          >
            Disable User
          </ActionItem>
        ) : null}
        {user.status === 'disabled' &&
          !isSAML &&
          !isAnyAppLicenseUserLimitExceed ? (
            <ActionItem
              action={() => enableUsers([user.id])}
              disabled={isViewOnlyEnabledFor6senseAccess}
            >
            Enable User
          </ActionItem>
        ) : null}
        {['inviteExpired', 'invited'].includes(user.status) &&
          !isSAML &&
          !isAnyAppLicenseUserLimitExceed ? (
            <ActionItem
              action={() => reinviteUsers([user.id])}
              disabled={isViewOnlyEnabledFor6senseAccess}
            >
            Resend Invite
          </ActionItem>
        ) : null}
        {['inviteExpired', 'invited'].includes(user.status) && !isSAML ? (
          <ActionItem
            action={() => {
              handleDelete(user.id, user.username);
            }}
            disabled={isViewOnlyEnabledFor6senseAccess}
          >
            Delete User
          </ActionItem>
        ) : null}
        {canChangeOwnerAnyApp && isPrimaryAdmin ? (
          <ActionItem
            action={() => dispatch(actions.changeSwapModalVisibility(true))}
          >
            Change Primary Administrator
          </ActionItem>
        ) : null}
      </ActionDropdown>
      <ConfirmModal {...modalProps} />
    </React.Fragment>
  ) : null;
};

export default UserActionDropdown;
