export const GENAI_HEADER = 'Generative AI';

export const GENAI_SUBHEADER =
  'Manage and set access controls on Generative AI capabilities for your org';

export const BACK_BUTTON_TEXT = 'Back';

export const INSIGHTS_CARD_HEADER = 'AI Insights';

export const INSIGHTS_CARD_SUBHEADER =
  'Save time by reading an AI generated summary for an account.';

export const LEARN_MORE = 'Learn More';

export const LEARN_MORE_LINK =
  'https://support.6sense.com/knowledge-base/ai-insights/';

export const COMPANY_WEBSITE_PLACEHOLDER = 'https://www.websitename.com';

export const COMPANY_AND_PRODUCT_PLACEHOLDER =
  'Describe your company and the products, services, and/or solutions it offers';

export const COMPANY_COMPETITORS_PLACEHOLDER =
  'Add one or more of your top competitors';

export const GTM_PARTNERS_PLACEHOLDER =
  'Describe your company\'s partnerships or platform integrations ' +
  'that prospects might find useful or compelling.';

export const SUMMARY_STEP_SUBLABEL =
  'Generative AI takes your resources and crafts an elevator pitch ' +
  'specific to your company and product.';

export const PAIN_POINTS_PLACEHOLDER =
  'Enter the pain points that your Target Persona is likely to face in their role or organization.';

export const VALUE_PROPOSITION_PLACEHOLDER =
  'Describe the unique value of your product, service, or solution. Emphasize why a ' +
  'prospect should choose your company over competitors.';

export const productSummaryAttributes = {
  company_website: '',
  company_product_information: '',
  competitors: [],
  gtm_partners_and_integrations: '',
  personas: [
    {
      targeted_persona: '',
      pain_points: '',
      value_proposition: '',
    },
  ],
  documents: [],
};

export type ProductSummaryAttributesType = typeof productSummaryAttributes;

export const companyProductAttributes = [
  'company_website',
  'company_product_information',
  'competitors',
  'gtm_partners_and_integrations',
];

export const marketingSalesAttributes = ['personas'];

export const documents = [];

export const adminLabel = 'Visible to Sales Administrator';

export const allUsersLabel = 'Visible to All Users';

export const UPLOAD_STATUS = {
  IN_PROGRESS: 'in-progress',
  ERROR: 'error',
  DONE: 'done',
};

export const TOAST_KEY_MAP = {
  TOO_MANY_FILES: 'too_many_files',
  COMPETITORS_VALIDATION: 'competitors_validation',
  SUMMARY_PUBLISHED: 'summary_published',
  ERROR_PUBLISHING_SUMMARY: 'error_publishing_summary',
};
