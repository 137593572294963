/* eslint-disable max-len */

export const TOTAL_CREDIT_POOL_LABELS = [
  {
    label: 'Total Credits',
    key: 'total_credits',
    tooltip:
      'Total credits allocated to your organization for the selected credit pool.',
  },
  {
    label: 'Used Credits',
    key: 'total_used_credits',
    tooltip:
      'Sum of credits consumed by the users for unlocking and exporting records.',
  },
  {
    label: 'Allocated Credits',
    key: 'total_allocated_credits_to_users',
    tooltip:
      'Sum of credits allocated to users for unlocking and exporting records.',
  },
  {
    label: 'Unallocated Credits',
    key: 'total_unallocated_credits',
    tooltip:
      'Credits balance that can be assigned to users. This is (Total Credits - Allocated Credits).',
  },
  {
    label: 'Expiration Date',
    key: 'expiry_date',
    tooltip: 'Credit expiry date for the selected credit pool.',
  },
  {
    label: 'Last Updated',
    key: 'updated',
    tooltip: 'Date on which credits were last assigned to the organization.',
  },
];

export const TOTAL_WF_CREDIT_POOL_LABELS = [
  {
    label: 'Total Credits',
    key: 'total_credits',
    tooltip:
      'Total credits allocated to your organization for the selected credit pool.',
  },
  {
    label: 'Used Credits',
    key: 'total_used_credits',
    tooltip:
      'Sum of credits consumed by the users for unlocking, exporting and enriching records. ',
  },
  {
    label: 'Expiration Date',
    key: 'expiry_date',
    tooltip: 'Credit expiry date for the selected credit pool.',
  },
  {
    label: 'Last Updated',
    key: 'updated',
    tooltip: 'Date on which credits were last assigned to the organization.',
  },
];
export const SI_CREDIT_POOL_LABELS = [
  {
    label: 'Total Credits',
    key: 'total_credits',
    tooltip:
      'Total credits allocated to your organization for the selected credit pool.',
  },
  {
    label: 'Used Credits',
    key: 'total_used_credits',
    tooltip:
      'Sum of credits consumed by the users for unlocking and exporting records.',
  },
  {
    label: 'Allocated Credits',
    key: 'total_allocated_credits_to_users',
    tooltip:
      'Sum of credits allocated to users for unlocking and exporting records.',
  },
  {
    label: 'Unallocated Credits',
    key: 'total_unallocated_credits',
    tooltip:
      'Credits balance that can be assigned to users. This is (Total Credits - Allocated Credits).',
  },
];
export const WF_CREDIT_POOL_LABELS = [
  {
    label: 'Allocated Credits',
    key: 'total_credits',
    tooltip:
      'Total credits allocated to your organization for the selected credit pool.',
  },
  {
    label: 'Used Credits',
    key: 'total_used_credits',
    tooltip:
      'Sum of credits consumed by the users for enriching and exporting records.',
  },
  {
    label: 'Balance Credits',
    key: 'total_balance_credits',
    tooltip:
      'Credits balance for the workflow application. This is (Allocated Credits - Used Credits).',
  },
];

export const STATUS_LABEL_MAP = {
  enabled: 'Enabled',
  disabled: 'Disabled',
  invited: 'Invited',
  inviteExpired: 'Expired Invite',
};

export const STATUS_FILTER_OPTIONS = [
  { label: 'All Status', value: 'all' },
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' },
];

export const SAML_STATUS_FILTER_OPTIONS = [
  { label: 'All Status', value: 'all' },
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' },
];

export const CREDIT_MSGS = {
  ADD_CREDIT_ERR_MSG: 'You have added more credits than available to allocate',
  UPDATE_CREDIT_ERR_MSG:
    'You have added more credits than available to allocate',
  REMOVE_CREDIT_ERR_MSG: 'You cannot remove more credits than are available',
  DISTRIBUTE_CREDIT_ERR_MSG: 'You cannot distribute more credits than are available',
  CREDIT_GREATER_THAN_ZERO: 'Use only positive, whole numbers',
  INVALID_ERROR_MSG: 'Use only positive, whole numbers',
  CREDITS_ADDED_MSG: 'Credits added',
  CREDITS_UPDATED_MSG: 'Credits updated',
  CREDIT_NOT_ALLOTED: 'Credits are not allocated to your organization. Please reach out to your CSM for credit allocation!',
  CREDIT_USAGE_DISABLED: 'Please contact your CSM to complete required contract documents to enable credit allocation.',
};

export const BALANCE_FILTER_TYPES = {
  ALL: 'all',
  BELOW: 'below',
  ABOVE: 'above',
};

export const DISABLED_STATUS = 'disabled';

export const TOOLTIP_MSGS = {
  LITE: 'Credits cannot be allocated to users with an Enterprise Lite license',
  DISABLED: 'User no longer have access to SI app',
  CREDIT_POOL_EXPIRED_STATUS: 'Expired Pool user Credit Allotment  - Credit pool expired, select an active pool to allot credits to users',
};

export const LICENSE_TYPES = {
  LITE: 'LITE',
};

export const LICENSE_TYPES_LABEL = {
  FULL: 'Enterprise',
  LITE: 'Enterprise Lite',
};


export const creditTypeProperties = {
  total_unallocated_credits: 0,
  id: 0,
  total_allocated_credits_to_users: 0,
  total_balance_credits: 0,
  total_used_credits: 0,
  updated: '',
  created: '',
  is_deleted: false,
  org_id: 0,
  total_credits: 0,
  start_date: '',
  expiry_date: '',
  created_by: '',
  updated_by: '',
  message: '',
  is_expired: false,
  wf_org_credits_id: 0,
};

export const STATUS_MAPPER = {
  enabled: ['enabled', 'invited'],
  disabled: ['disabled', 'inviteExpired'],
};
