/* eslint-disable max-len */
import salesforceLogo from 'images/IntegrationLogos/salesforce-old-logo.svg';
import dynamicsLogo from 'images/IntegrationLogos/microsoft-logo.svg';
import hubspotLogo from 'images/IntegrationLogos/hubspot-logo.svg';

import salesloftLogo from 'images/salesloft-logo.svg';
import outreachLogo from 'images/outreach.png';

export const EXPORT_OPTIONS = [
  {
    title: 'Lead',
    value: 'Lead',
    label: 'Lead',
    disabled: false,
    dropdownKey: 'lead_export_mapping_profile_id',
    toggleKey: 'lead_export_enabled',
    integrations: ['Salesforce', 'Dynamics'],
    mappingData: [],
  },
  {
    title: 'Contact',
    value: 'Contact',
    label: 'Contact',
    disabled: false,
    dropdownKey: 'contact_export_mapping_profile_id',
    toggleKey: 'contact_export_enabled',
    integrations: ['Salesforce', 'Dynamics', 'HubspotCrm', 'hvs'],
    mappingData: [],
  },
  {
    title: 'Account',
    value: 'Account',
    label: 'Account',
    disabled: false,
    dropdownKey: 'account_export_mapping_profile_id',
    toggleKey: 'account_export_enabled',
    integrations: ['Salesforce', 'Dynamics', 'HubspotCrm'],
    mappingData: [],
  },
  {
    title: 'Contact',
    value: 'Contact',
    label: 'Prospect',
    disabled: false,
    dropdownKey: 'contact_export_mapping_profile_id',
    toggleKey: 'contact_export_enabled',
    integrations: ['outreach'],
    mappingData: [],
  },
  {
    title: 'Contact',
    value: 'Contact',
    label: 'People',
    disabled: false,
    dropdownKey: 'contact_export_mapping_profile_id',
    toggleKey: 'contact_export_enabled',
    integrations: ['salesloft'],
    mappingData: [],
  },
];

export const INTEGRATION_STATUS = {
  NOT_CONFIGURED: 'NOT_CONFIGURED',
  CONFIGURED: 'CONFIGURED',
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR: 'ERROR',
};

export const CRMSummaryConfig = {
  NonConfigure: {
    title: 'CRM Settings',
    description: 'Integrate your organization’s CRM instance',
  },
  Salesforce: {
    header: {
      logo: salesforceLogo,
      mainTitle: 'Salesforce (Production)',
      category: 'Customer Relationship Management (CRM)',
      subTitle: 'by Salesforce.Inc',
    },
    label1: 'Update Salesforce data when Contacts are updated in 6sense',
    label2: 'Update Salesforce data when Leads are updated in 6sense',
    label3: 'Allow Creation of New Account on Contact Push',
    label4: 'Push contact if account push fails',
    label5: 'Update Salesforce data when Companies are updated in 6sense',
    tooltips: {
      label3: 'If enabled, this feature permits users to create a new Account for Contacts that do not have a matching account in CRM. However, if the feature is disabled, only Contacts with matching Accounts in CRM will be pushed to CRM.',
      label4: 'Allow users to manually select which account to match to in Salesforce during the export process.',
      label5: 'Allowed if no account matches at the country level',
    },
    about: {
      mainInfo: {
        title: 'About this Integration',
        description:
          "6sense's enterprise-grade CRM integration with Salesforce " +
          'creates a unified source of truth for revenue teams. 6sense ingests CRM ' +
          'historical data to build proven AI predictive models and uncover target buyers. ' +
          'Continuous cleaning, enrichment, and syncs with your CRM data ensures data accuracy ' +
          'that enables account prioritization and identification of entire buying committees.',
      },
      metaInfo: [
        {
          name: 'Category',
          value: 'CRM',
        },
        {
          name: 'Feature Support',
          value:
            'Segments, Orchestration, Predictive Models, Sales Intelligence, Pipeline Intelligence',
        },
      ],
      keyfeatures: {
        title: 'Key Features',
        features: [
          {
            name: 'Enable orchestrations',
            value: 'Using accurate, robust data',
          },
          {
            name: 'Data Enrichment',
            value:
              'Create a rich, contextualized view of the entire buying committee',
          },
          {
            name: 'Unite data to create a source of truth',
            value:
              'Capture, standardize, and clean data from B2B buyer ' +
              'interactions across digital channels ',
          },
        ],
      },
    },
    help: {
      links: [
        {
          title: 'Additional Resources',
          list: [
            {
              href: 'https://support.6sense.com/hc/en-us/articles/1500006652662',
              text: 'How to connect Salesforce to 6sense using OAuth',
            },
            {
              href: 'https://developer.salesforce.com/page/REST_API',
              text: 'Salesforce REST API Information',
            },
            {
              // eslint-disable-next-line max-len
              href: 'https://6sense.com/resources/home/sales-enablement/6sense-crm-integration-one-pager',
              text: '6sense CRM Integration - Salesforce',
            },
          ],
        },
      ],
    },
  },
  Dynamics: {
    header: {
      logo: dynamicsLogo,
      mainTitle: 'Dynamics',
      category: 'Customer Relationship Management (CRM)',
      subTitle: 'by Microsoft.Inc',
    },
    label1: 'Update Dynamics data when Contacts are updated in 6sense',
    label2: 'Update Dynamics data when Leads are updated in 6sense',
    label3: 'Allow Creation of New Account on Contact Push',
    label4: 'Push contact if account push fails',
    label5: 'Update Dynamics data when Companies are updated in 6sense',
    tooltips: {
      label3: 'If enabled, this feature permits users to create a new Account for Contacts that do not have a matching account in CRM. However, if the feature is disabled, only Contacts with matching Accounts in CRM will be pushed to CRM.',
      label4: 'Allow users to manually select which account to match to in Dynamics during the export process.',
      label5: 'Allowed if no account matches at the country level',
    },
    about: {
      mainInfo: {
        title: 'About this Integration',
        description:
          "6sense's enterprise-grade CRM integration with Dynamics creates " +
          'a unified source of truth for revenue teams. 6sense ingests CRM historical data to ' +
          'build proven AI predictive models and uncover target buyers. Continuous cleaning, ' +
          'enrichment, and syncs with your CRM data ensures data accuracy that enables account ' +
          'prioritization and identification of entire buying committees.',
      },
      metaInfo: [
        {
          name: 'Category',
          value: 'CRM',
        },
        {
          name: 'Feature Support',
          value:
            'Segments, Orchestration, Predictive Models, Sales Intelligence',
        },
      ],
      keyfeatures: {
        title: 'Key Features',
        features: [
          {
            name: 'Enable orchestrations',
            value: 'Using accurate, robust data',
          },
          {
            name: 'Data Enrichment',
            value:
              'Create a rich, contextualized view of the entire buying committee',
          },
          {
            name: 'Unite data to create a source of truth',
            value:
              'Capture, standardize, and clean data from B2B buyer ' +
              'interactions across digital channels',
          },
        ],
      },
    },
    help: {
      links: [
        {
          title: 'Additional Resources',
          list: [
            {
              // eslint-disable-next-line max-len
              href: 'https://6sense.com/resources/home/sales-enablement/6sense-crm-integration-one-pager',
              text: '6sense CRM Integration - Dynamics',
            },
          ],
        },
      ],
    },
  },
  HubspotCrm: {
    header: {
      logo: hubspotLogo,
      mainTitle: 'HubSpot CRM',
      category: 'Customer Relationship Management (CRM)',
      subTitle: 'by HubSpot.Inc',
    },
    label1: 'Update Hubspot data when Contacts are updated in 6sense',
    label3: 'Allow Creation of New Account on Contact Push',
    label4: 'Push contact if account push fails',
    label5: 'Update Hubspot data when Companies are updated in 6sense',
    tooltips: {
      label3: 'If enabled, this feature permits users to create a new Account for Contacts that do not have a matching account in CRM. However, if the feature is disabled, only Contacts with matching Accounts in CRM will be pushed to CRM.',
      label4: 'Allow users to manually select which account to match to in HubspotCrm during the export process.',
      label5: 'Allowed if no account matches at the country level',
    },
    about: {
      mainInfo: {
        title: 'About this Integration',
        description:
          "6sense's enterprise-grade CRM integration with HubSpot creates " +
          'a unified source of truth for revenue teams. 6sense ingests CRM historical data to ' +
          'build proven AI predictive models and uncover target buyers. Continuous cleaning, ' +
          'enrichment, and syncs with your CRM data ensures data accuracy that enables account ' +
          'prioritization and identification of entire buying committees.',
      },
      metaInfo: [
        {
          name: 'Category',
          value: 'CRM',
        },
        {
          name: 'Feature Support',
          value:
            'Segments, Orchestration, Predictive Models, Sales Intelligence',
        },
      ],
      keyfeatures: {
        title: 'Key Features',
        features: [
          {
            name: 'Enable orchestrations',
            value: 'Using accurate, robust data',
          },
          {
            name: 'Data Enrichment',
            value:
              'Create a rich, contextualized view of the entire buying committee',
          },
          {
            name: 'Unite data to create a source of truth',
            value:
              'Capture, standardize, and clean data from B2B buyer ' +
              'interactions across digital channels ',
          },
        ],
      },
    },
    help: {
      links: [
        {
          title: 'Additional Resources',
          list: [
            {
              // eslint-disable-next-line max-len
              href: 'http://6sense.com/resources/home/sales-enablement/6sense-crm-integration-one-pager',
              text: '6sense CRM integration - HubSpot',
            },
          ],
        },
      ],
    },
  },
};

export const SepSummaryConfig = {
  NonConfigure: {
    title: 'SEP Settings',
    description: 'Integrate your organization’s SEP instance',
  },
  salesloft: {
    header: {
      logo: salesloftLogo,
      mainTitle: 'Salesloft',
      category: 'Sales Engagement Platform (SEP)',
      subTitle: 'by Salesloft',
    },
    label1: 'Update Salesloft Prospects when People data is updated in 6sense',
    label3: 'Sync Account with Prospect',
    label4: 'Push contact if account push fails',
    label5: 'Update Salesloft Accounts when Company data is updated in 6sense',
    tooltips: {
      label6: 'Constrain exports to only CRM records or any records.',
      label7: 'CRM records will be enriched before export if enabled and credits might be deducted. Non CRM records will always be enriched before export and credits will be deducted.',
    },
    about: {
      mainInfo: {
        title: 'About this Integration',
        description:
          'The 6sense and Salesloft integration enables revenue teams to engage ' +
          'buyers with personalization at scale. Quickly turn AI insights into action' +
          ' by adding key buyers into appropriate Salesloft Cadences and engage' +
          ' the entire buying committee with relevance.',
      },
      metaInfo: [
        {
          name: 'Category',
          value: 'SEP',
        },
        {
          name: 'Feature Support',
          value: 'Orchestration, Sales Intelligence',
        },
      ],
      keyfeatures: {
        title: 'Key Features',
        features: [
          {
            name: 'Prioritize Outreach',
            value: 'To accounts showing significant engagement',
          },
          {
            name: 'Personalize Engagement',
            value: 'For accounts ready to engage.',
          },
          {
            name: 'Automate Workflows and Improve Efficiency',
            value: 'By focusing on accounts that matter to your team',
          },
        ],
      },
    },
    help: {
      links: [
        {
          title: 'Additional Resources',
          list: [
            {
              href: 'https://6sense.com/partners/technology/salesloft/',
              text: '6sense - Salesloft',
            },
          ],
        },
      ],
    },
  },
  outreach: {
    header: {
      logo: outreachLogo,
      mainTitle: 'Outreach',
      category: 'Sales Engagement Platform (SEP)',
      subTitle: 'by Outreach',
    },
    label1: 'Update Outreach Prospects when People data is updated in 6sense',
    label3: 'Sync Account with Prospect',
    label4: 'Push contact if account push fails',
    label5: 'Update Outreach Accounts when Company data is updated in 6sense',
    tooltips: {
      label6: 'Constrain exports to only CRM records or any records.',
      label7: 'CRM records will be enriched before export if enabled and credits might be deducted. Non CRM records will always be enriched before export and credits will be deducted.',
    },
    about: {
      mainInfo: {
        title: 'About this Integration',
        description:
          'The 6sense integration with Outreach brings AI-driven insights, predictions,' +
          ' and recommendations directly into the Outreach SEM platform, offering actionable' +
          ' revenue intelligence and account prioritization that promotes efficiency and helps' +
          ' sales reps deliver more personalized, timely account engagement.',
      },
      metaInfo: [
        {
          name: 'Category',
          value: 'SEP',
        },
        {
          name: 'Feature Support',
          value: 'Orchestration, Sales Intelligence',
        },
      ],
      keyfeatures: {
        title: 'Key Features',
        features: [
          {
            name: 'Prioritize Outreach',
            value: 'To accounts showing significant engagement',
          },
          {
            name: 'Personalize Engagement',
            value: 'For accounts ready to engage',
          },
          {
            name: 'Automate Workflows and Improve Efficiency',
            value: 'By focusing on accounts that matter to your team',
          },
        ],
      },
    },
    help: {
      links: [
        {
          title: 'Additional Resources',
          list: [
            {
              // eslint-disable-next-line max-len
              href: 'https://6sense.com/partners/technology/outreach/',
              text: '6sense - Outreach',
            },
          ],
        },
      ],
    },
  },
  hvs: {
    header: {
      logo: salesforceLogo,
      mainTitle: 'Salesforce Sales Engagement (Sales Cadence)',
      category: 'Sales Engagement Platform (SEP)',
      subTitle: 'by Salesforce.Inc',
    },
    label1: 'Update Sales Engagement Prospects when People data is updated in 6sense',
    label3: 'Sync Account with Prospect',
    label4: 'Push contact if account push fails',
    label5: 'Update Sales Engagement Accounts when Company data is updated in 6sense',
    tooltips: {
      label6: 'Constrain exports to only CRM records or any records.',
      label7: 'CRM records will be enriched before export if enabled and credits might be deducted. Non CRM records will always be enriched before export and credits will be deducted.',
    },
    about: {
      mainInfo: {
        title: 'About this Integration',
        description:
          'Our 6sense Sales Intelligence & Salesforce integration ' +
          'also includes Salesforce Sales Engagement and enables sellers to identify hot accounts ' +
          'and buyers in 6sense dashboards and add them directly into applicable ' +
          'Sales Engagement cadences.',
      },
      metaInfo: [
        {
          name: 'Category',
          value: 'SEP',
        },
        {
          name: 'Feature Support',
          value: 'Orchestration, Sales Intelligence',
        },
      ],
      keyfeatures: {
        title: 'Key Features',
        features: [
          {
            name: 'Prioritize Outreach',
            value: 'By identifying buyers at hot accounts',
          },
          {
            name: 'Personalize Engagement',
            value: 'With a one-click add into personalized sales cadences',
          },
          {
            name: 'Automate Workflows and Improve Efficiency ',
            value:
              'By finding and reaching accounts that matter, without leaving SFDC',
          },
        ],
      },
    },
    help: {
      links: [
        {
          title: 'Additional Resources',
          list: [
            {
              href:
                'https://support.6sense.com/hc/en-us/articles/' +
                '7417624363411-Integrating-6sense-with-Salesforce-Sales-Engagement#requirements-0-0',
              text: 'How to install?',
            },
            {
              href:
                'https://support.6sense.com/hc/en-us/articles/' +
                '7596839464339-Using-Salesforce-Sales-Engagement-with-6sense-Sales-Intelligence#' +
                'adding-a-contact-to-a-salesforce-sales-cadence-from-6sense-0-1',
              text: 'Using Salesforce Sales Engagement with 6sense Sales Intelligence',
            },
          ],
        },
      ],
    },
  },
};

export * from './request';
