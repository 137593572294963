import { createSelector } from 'reselect';
import { orgSelector } from 'modules/user/selectors';
import { filtersConfigSelector } from 'modules/segments/selectors';
import { map, filter, isEmpty } from 'lodash';
import { updateSegmentsResponse } from './utils';
import { formatFilterset } from 'modules/segments/utils';
import { areCRMCredsValidatingSelector } from 'modules/integrations/selectors';

const publishedSegmentsSelector = (state) => state.publishedSegments;

const dropdownLimitSelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.dropdownSegmentsLimit
);

const searchInputSelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.searchInput
);

const dropDownEndpointSelector = createSelector(
  [
    orgSelector,
    dropdownLimitSelector,
    searchInputSelector,
  ],
  (orgId, pageSize, searchStr) => {
    const endpoint =
      `org/${orgId}/segment/?limit=${pageSize}&is_published=false&ordering=name`;

    if (searchStr && searchStr !== '') {
      return `${endpoint}&name_contains=${searchStr.replace('+', '%2B')}`;
    }
    return endpoint;
  },
);

const dropDownSegmentsListSelector = createSelector(
  publishedSegmentsSelector,
  ({ dropdownSegments = [] } = {}) =>
    map(
      dropdownSegments,
      (value) =>
        ({ ...value, name_segment: `${value.name || ''}` })
    )
);

const displaySearchSelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.showSearchBar,
);

const dropdownSegmentsLoadingSelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.dropdownLoading
);

const getSegmentTagsSelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.segmentTags
);

const getSelectedSegmentMetaSelector = createSelector(
  publishedSegmentsSelector,
  getSegmentTagsSelector,
  filtersConfigSelector,
  (publishedSegments, tags, config) =>
    isEmpty(publishedSegments.segmentMeta) ? publishedSegments.segmentMeta :
    updateSegmentsResponse(
      [publishedSegments.segmentMeta], tags, config, formatFilterset
    )[0]
);

const isLoadedSelector = createSelector(
  publishedSegmentsSelector,
  areCRMCredsValidatingSelector,
  (publishedSegments, areCRMCredsValidating) => publishedSegments.loaded && !areCRMCredsValidating
);

const asyncOperationsSelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.asyncCallLoading,
);

const getTagFilterSelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.tagFilter
);

const getModalVisibilitySelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.visibility
);

const getSavedSegmentsSelector = createSelector(
  publishedSegmentsSelector,
  getTagFilterSelector,
  getSegmentTagsSelector,
  ({ segmentsList }, tagFilter, segmentTags) => {
    const curTag = segmentTags.find((tag) => tag.name === tagFilter);
    if (!curTag) {
      return segmentsList;
    }
    return filter(segmentsList, (s) => {
      const tagIndex = s.tags.findIndex((segTag) => segTag.segment_tag_id === curTag.id);
      return tagIndex >= 0 && s.tags[tagIndex].is_deleted === false;
    });
  },
);

const getSegmentToUnpublishSelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.unpublishSegmentId
);

const getMaxTextDisplaySelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.maxTextVisible
);

const getSegmentDependenciesDictSelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.dependencies
  );

const getHasExistingDependenciesSelector = createSelector(
  publishedSegmentsSelector,
  (publishedSegments) => publishedSegments.hasExistingDependencies
);

export {
  publishedSegmentsSelector,
  displaySearchSelector,
  dropdownSegmentsLoadingSelector,
  dropDownEndpointSelector,
  dropDownSegmentsListSelector,
  dropdownLimitSelector,
  searchInputSelector,
  getSelectedSegmentMetaSelector,
  isLoadedSelector,
  getSavedSegmentsSelector,
  asyncOperationsSelector,
  getSegmentTagsSelector,
  getModalVisibilitySelector,
  getTagFilterSelector,
  getSegmentToUnpublishSelector,
  getMaxTextDisplaySelector,
  getSegmentDependenciesDictSelector,
  getHasExistingDependenciesSelector,
};
